import React, { useEffect, useState, useRef } from 'react';
import logo from './gobcarry.gif';
import './App.css';
import ModalBoost from './fboost.js';
import ModalBumpkin from './fbkn.js';
import ModalTNFT from './ftrynft.js';
import ModalGraph from './fgraph.js';
import ModalDlvr from './fdelivery.js';
import Cadre from './animodal.js';
import DropdownCheckbox from './listcol.js';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
//import xrespFarm from './respFarm.json';
//import xrespPrice from './respPrice.json';
//import xrespBumpkin from './respBumpkin.json';
var vversion = 1.02;

const dateSeason = new Date('2024-05-01');
const resetDay = 2;
const currWeek = Math.ceil((new Date() - dateSeason) / (1000 * 60 * 60 * 24 * 7));
const tktWeekly = 50;
const imgtkt = './icon/res/scroll.webp';

const imgsfl = './icon/res/sfltoken.png';
const imgxp = './icon/ui/level_up.png';
const imgcrop = './icon/res/soil.png';
const imgwood = './icon/res/harvested_tree.png';
const imgstone = './icon/res/stone_small.png';
const imgbeehive = './icon/res/beehive.webp';
const imgflowerbed = './icon/flower/flower_bed_modal.png';
const imgchkn = './icon/res/chkn.png';
const imgflch = './icon/ui/flch.png';
const imgrdy = './icon/ui/expression_alerted.png';
const imgtrd = './icon/ui/sparkle2.gif';
const imgna = './icon/nft/na.png';
const imgrod = './icon/tools/fishing_rod.png';

var it = [];
var food = [];
var fish = [];
var flower = [];
//var fishing = [];
//var expand = [];
var xexpandData = [];
var nft = [];
var nftw = [];
var skill = [];
var buildng = [];
var buildngf = [];
var bud = [];
var spot = {
  crop: 0,
  wood: 0,
  stone: 0,
  iron: 0,
  gold: 0,
  crimstone: 0,
  sunstone: 0,
  oil: 0,
  egg: 0,
  beehive: 0,
  fruit: 0
}
var mutantchickens = [];
var sTickets = [];
var supplyOS = [];
var supplyOSW = [];
var fishingDetails = "";
var wklactivity = [];
var frmOwner = "";
var username = "";
var isAbo = false;

var BoostNFT = [];
var BoostSkill = [];
var bkn = [];
var imgbkn = "";

var bFarmit = [];
var bCookit = [];
var bTrynft = [];
var bTrynftw = [];
var bTrybuild = [];
var bTryskill = [];
var bTrybud = [];
var bnft = [];
var bnftw = [];
var bbuild = [];
var bskill = [];
var bbud = [];
var fruitPlanted = [];
var dProd = [];
var dProdtry = [];
var xdxp = 0;

var xinitprc = false;
var xnotifiedTimers = [];
var xHrvst = [];
var xHrvsttry = [];
var HrvstMax = [];
var HrvstMaxtry = [];
var xBurning = [];
xBurning.burn = [];
xBurning.burntry = [];
var itDck = [];
var cstPricesb = [10000, 5000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 500, 100, 50, 50, 50, 1000, 100, 100, 100, 100, 100, 100, 100];
var platformListings = "Trades";

//var testb = false;

function App() {
  const [inputValue, setInputValue] = useState('');
  const [inputMaxBB, setInputMaxBB] = useState(1);
  const [inputFarmTime, setInputFarmTime] = useState(8);
  const [inputFromLvl, setInputFromLvl] = useState(1);
  const [inputToLvl, setInputToLvl] = useState(30);
  const [inputKeep, setInputKeep] = useState(3);
  const [fromtolvltime, setfromtolvltime] = useState(0);
  const [fromtolvlxp, setfromtolvlxp] = useState(0);
  const [dailyxp, setdailyxp] = useState(0);
  const [cstPrices, setCstPrices] = useState(cstPricesb);
  const [fromexpand, setfromexpand] = useState(1);
  const [toexpand, settoexpand] = useState(23);
  const [fromtoexpand, setfromtoexpand] = useState([]);
  const lastClickedInputValue = useRef('');
  const lastClickedInputKeep = useRef('');
  const [farmData, setFarmData] = useState([]);
  const [bumpkinData, setBumpkinData] = useState([]);
  const [bumpkinDataOC, setBumpkinDataOC] = useState([]);
  const [reqState, setReqState] = useState("");
  const [invData, setinvData] = useState(null);
  const [cookData, setcookData] = useState(null);
  const [fishData, setfishData] = useState(null);
  const [flowerData, setflowerData] = useState(null);
  const [expandData, setexpandData] = useState(null);
  const [itData, setitData] = useState(it);
  const [priceData, setpriceData] = useState([]);
  const [priceDataT, setpriceDataT] = useState([]);
  const [priceDataN, setpriceDataN] = useState([]);
  const [priceDataO, setpriceDataO] = useState([]);
  const [priceDataOW, setpriceDataOW] = useState([]);
  const [listingsData, setlistingsData] = useState([]);
  const [showfBoost, setShowfBoost] = useState(false);
  const [showfSkill, setShowfSkill] = useState(false);
  const [showfBumpkin, setShowfBumpkin] = useState(false);
  const [showfTNFT, setShowfTNFT] = useState(false);
  const [showfGraph, setShowfGraph] = useState(false);
  const [showfDlvr, setShowfDlvr] = useState(false);
  const [showCadre, setShowCadre] = useState(false);
  const [selectedCurr, setSelectedCurr] = useState('SFL');
  const [selectedQuant, setSelectedQuant] = useState('unit');
  const [selectedQuantCook, setSelectedQuantCook] = useState('unit');
  const [selectedQuantFish, setSelectedQuantFish] = useState('unit');
  const [selectedCostCook, setselectedCostCook] = useState('trader');
  const [selectedQuantity, setSelectedQuantity] = useState('farm');
  const [selectedQuantityCook, setSelectedQuantityCook] = useState('farm');
  const [selectedReady, setSelectedReady] = useState('when');
  const [selectedDsfl, setSelectedDsfl] = useState('trader');
  const [selectedFromActivity, setSelectedFromActivity] = useState("today");
  const [selectedFromActivityDay, setSelectedFromActivityDay] = useState("7");
  const [selectedExpandType, setSelectedExpandType] = useState("spring");
  const [activityDisplay, setActivityDisplay] = useState("item");
  const [selectedInv, setSelectedInv] = useState('inv');
  const [activeTimers, setActiveTimers] = useState([]);
  const [notifiedTimers, setNotifiedTimers] = useState([]);
  const [GraphType, setGraphType] = useState('');
  const [mutData, setmutData] = useState([]);
  const [ticketsData, setticketsData] = useState([]);
  const [deliveriesData, setdeliveriesData] = useState([]);
  const [HarvestD, setHarvestD] = useState(xHrvst);
  const [activityData, setActivityData] = useState({});
  const [activityTable, setActivityTable] = useState([]);
  const [xListeCol, setXListeCol] = useState([
    ['Hoard', 1],
    ['Item name', 0],
    ['Quantity', 1],
    ['Time', 1],
    ['Production cost', 1],
    ['Shop price', 1],
    ['Trader price', 1],
    ['Withdraw quantity', 1],
    ['Niftyswap price', 1],
    ['OpenSea price', 1],
    ['Yield', 1],
    ['Harvest average', 1],
    ['To harvest', 1],
    ['BlockBuck production', 0],
    ['Price difference with Trader Balloon', 1],
    ['Daily SFL', 0],
    ['Daily max production', 0],
    ['Coefficient Prod cost / Sell cost', 0],
    ['Wen ready', 1],
  ]);
  const [xListeColCook, setXListeColCook] = useState([
    ['Building', 1],
    ['Item name', 0],
    ['Quantity', 1],
    ['XP', 1],
    ['Time to cook', 1],
    ['Time for components growing', 0],
    ['XP/H', 1],
    ['XP/H with components time', 0],
    ['XP/SFL', 1],
    ['Cost', 1],
    ['Cost p2p', 1],
    ['Components', 1],
  ]);
  const [xListeColFish, setXListeColFish] = useState([
    ['Category', 1],
    ['Location', 1],
    ['Hoard', 1],
    ['Item name', 1],
    ['Bait', 1],
    ['Quantity on farm', 1],
    ['Caught', 1],
    ['Chum', 1],
    ['Period', 1],
    ['Percent by category', 1],
    ['XP', 1],
    ['Cost', 1],
    ['XP/SFL', 1],
  ]);
  const [xListeColFlower, setXListeColFlower] = useState([
    ['Seed', 1],
    ['Flower name', 1],
    ['Breeding', 1],
    ['Quantity in bag', 1],
    ['Found', 1],
  ]);
  const [xListeColExpand, setXListeColExpand] = useState([
    ['From / To', 1],
    ['LVL requirement', 1],
    ['Ressources requirement', 1],
    ['Nodes', 1],
    ['Cost', 1],
  ]);
  const [xListeColActivity, setXListeColActivity] = useState([
    ['From', 1],
    ['Total XP', 1],
    ['Tickets on daily chest', 1],
    ['Tickets on crops', 1],
    ['Tickets on tentacles', 1],
    ['Tickets from deliveries', 1],
    ['Tickets from chores', 1],
    ['Tickets max', 1],
    ['Deliveries cost', 1],
    ['Deliveries cost P2P', 1],
    ['Ticket cost', 1],
    ['SFL from deliveries', 1],
    ['SFL balance', 1],
    ['Ressources burned', 1],
  ]);
  const [xListeColActivityItem, setXListeColActivityItem] = useState([
    ['Item Name', 1],
    ['Harvested', 1],
    ['Quantity', 1],
    ['Burned', 1],
    ['Production Cost', 1],
    ['Balloon Price', 1],
    ['Niftyswap Price', 1],
    ['OpenSea Price', 1],
    ['Devliveries Burn', 1],
    ['Food produced', 1],
  ]);
  const [xListeColActivityQuest, setXListeColActivityQuest] = useState([
    ['From', 1],
    ['Description', 1],
    ['Reward', 1],
    ['Date', 1],
  ]);
  const [CostChecked, setCostChecked] = useState(true);
  const [TryChecked, setTryChecked] = useState(false);
  const [BurnChecked, setBurnChecked] = useState(true);
  const handleButtonfBoostClick = () => {
    setShowfBoost(true);
  };
  const handleClosefBoost = () => {
    setShowfBoost(false);
  };
  const handleButtonfSkillClick = () => {
    setShowfSkill(true);
  };
  const handleClosefSkill = () => {
    setShowfSkill(false);
  };
  const handleButtonfBumpkinClick = async () => {
    //if (testb === false) {
    const response = await fetch("/getbumpkin", {
      method: 'GET',
      headers: {
        //tokenuri: bumpkinData[0].tkuri,
        bknid: bumpkinData[0].id,
        frmid: lastClickedInputValue.current
      }
    });
    if (response.ok) {
      const data = await response.json();
      bkn = data.responseBumpkin;
      const imageData = data.responseImage;
      //setImageData(`data:image/png;base64,${imageData}`);
      imgbkn = `data:image/png;base64,${imageData}`;
      setBumpkinDataOC(data.responseBkn);
      bumpkinData[0].Bknlvl = data.Bknlvl;
    }
    /*} else {
      const data = xrespBumpkin;
      bkn = data.json;
      const imageData = btoa(String.fromCharCode.apply(null, new Uint8Array(data.image)));
      setImageData(imageData);
    } */
    setShowfBumpkin(true);
  };
  const handleClosefBumpkin = () => {
    setShowfBumpkin(false);
  };
  const handleButtonfTNFTClick = () => {
    setShowfTNFT(true);
  };
  const handleButtonfDlvrClick = () => {
    setShowfDlvr(true);
  };
  const handleClosefTNFT = (ittry, foodtry, fishtry, nfttry, nftwtry, buildtry, skilltry, budtry, xfishingDetails, xbTrynft, xbTrynftw, xbTrybuild, xbTryskill, xbTrybud) => {
    bTrynft = xbTrynft;
    bTrynftw = xbTrynftw;
    bTrybuild = xbTrybuild;
    bTryskill = xbTryskill;
    bTrybud = xbTrybud;
    //setTryit(nft, nftw, skill, buildng, bud);
    MergeIt(it, ittry);
    //getFarmit(it);
    //setPlanted(ittry);
    //it = ittry;
    MergeFood(food, foodtry);
    //getCookit(it);
    //food = foodtry;
    MergeFish(fish, fishtry);
    MergeFishing(fishingDetails, xfishingDetails);
    /* fish = fishtry;
    nft = nfttry;
    nftw = nftwtry;
    buildng = buildtry;
    skill = skilltry;
    bud = budtry; */
    //getActive(nft, nftw, skill, buildng, bud);
    //getTryit(nft, nftw, skill, buildng, bud);
    //setInv();
    setitData(it);
    setCookie();
    setShowfTNFT(false);
  };
  const handleClosefGraph = () => {
    setShowfGraph(false);
  };
  const handleClosefDlvr = () => {
    setShowfDlvr(false);
  };
  const handleCloseCadre = () => {
    setShowCadre(false);
  };
  const handleChangeQuant = (event) => {
    const selectedValue = event.target.value;
    setSelectedQuant(selectedValue);
  }
  const handleChangeQuantCook = (event) => {
    const selectedValue = event.target.value;
    setSelectedQuantCook(selectedValue);
  }
  const handleChangeQuantFish = (event) => {
    const selectedValue = event.target.value;
    setSelectedQuantFish(selectedValue);
  }
  const handleChangeFromActivity = (event) => {
    const selectedValue = event.target.value;
    setSelectedFromActivity(selectedValue);
  }
  const handleChangeFromActivityDay = (event) => {
    const selectedValue = event.target.value;
    setSelectedFromActivityDay(selectedValue);
  }
  const handleChangeActivityDisplay = (event) => {
    const selectedValue = event.target.value;
    setActivityDisplay(selectedValue);
  }
  const handleChangeExpandType = (event) => {
    const selectedValue = event.target.value;
    setSelectedExpandType(selectedValue);
  }
  const handleChangeQuantity = (event) => {
    const selectedValue = event.target.value;
    setSelectedQuantity(selectedValue);
  }
  const handleChangeQuantityCook = (event) => {
    const selectedValue = event.target.value;
    setSelectedQuantityCook(selectedValue);
    //if (inputFromLvl > 0 && inputToLvl < 66) { getxpFromToLvl(inputFromLvl, inputToLvl, xdxp) }
  }
  const handleChangeCostCook = (event) => {
    const selectedValue = event.target.value;
    setselectedCostCook(selectedValue);
  }
  const handleChangeInv = (event) => {
    const selectedValue = event.target.value;
    setSelectedInv(selectedValue);
  }
  const handleChangeReady = (event) => {
    const selectedValue = event.target.value;
    activeTimers.forEach(timerId => {
      clearInterval(timerId);
    });
    activeTimers.length = 0;
    setSelectedReady(selectedValue);
  }
  const handleChangeDsfl = (event) => {
    const selectedValue = event.target.value;
    setSelectedDsfl(selectedValue);
  }
  const handleTimerFinish = (index) => {
    setActiveTimers((prevTimers) => prevTimers.filter((_, i) => i !== index));
    if (Notification.permission === 'granted') {
      console.log(`Timer finish for index ${index}`);
      if (!notifiedTimers.includes(index) && !xnotifiedTimers.includes(index)) {
        createNotification(index);
      }
    }
  };
  function createNotification(index) {
    /*     if (xinit = true) {
          const notification = new Notification('Sunflower Manager', {
            body: index + ' ready.',
          });
          xnotifiedTimers.push(index);
          setNotifiedTimers((prevTimers) => [...prevTimers, index]);
          console.log(`Creating notification for index ${index}`);
        } */
  }
  /* const startTimer = (timestamp) => {
    setActiveTimers((prevTimers) => [...prevTimers, timestamp]);
  }; */
  const handleChangeCurr = (event) => {
    const selectedValue = event.target.value;
    setSelectedCurr(selectedValue);
  }
  const handleDropdownChange = (updatedOptions) => {
    setXListeCol(updatedOptions);
  };
  const handleDropdownCookChange = (updatedOptions) => {
    setXListeColCook(updatedOptions);
  };
  const handleDropdownFishChange = (updatedOptions) => {
    setXListeColFish(updatedOptions);
  };
  const handleDropdownFlowerChange = (updatedOptions) => {
    setXListeColFlower(updatedOptions);
  };
  const handleDropdownExpandChange = (updatedOptions) => {
    setXListeColExpand(updatedOptions);
  };
  const handleDropdownActivityChange = (updatedOptions) => {
    setXListeColActivity(updatedOptions);
  };
  const handleDropdownActivityItemChange = (updatedOptions) => {
    setXListeColActivityItem(updatedOptions);
  };
  const handleDropdownActivityQuestChange = (updatedOptions) => {
    setXListeColActivityQuest(updatedOptions);
  };
  const handleInputChange = (event) => {
    const value = event.target.value.replace(/\D/g, '');
    setInputValue(value);
  };
  const handleInputKeepChange = (event) => {
    const value = event.target.value.replace(/\D/g, '');
    setInputKeep(value);
    lastClickedInputKeep.current = value;
  };
  const handleInputcstPricesChange = (event, item) => {
    setCstPrices(prevCstPrices => ({
      ...prevCstPrices,
      [item]: event.target.innerText
    }));
  };
  const handleMaxBBChange = (event) => {
    setInputMaxBB(event.target.value);
  };
  const handleFarmTimeChange = (event) => {
    setInputFarmTime(event.target.value);
  };
  const handleFromLvlChange = (event) => {
    const value = event.target.value.replace(/\D/g, '');
    setInputFromLvl(value);
    if (value > 0 && value < 66) { getxpFromToLvl(value, inputToLvl, xdxp) }
  };
  const handleToLvlChange = (event) => {
    const value = event.target.value.replace(/\D/g, '');
    setInputToLvl(value);
    if (value > 0 && value < 66) { getxpFromToLvl(inputFromLvl, value, xdxp) }
  };
  const handleButtonClick = async () => {
    //if (Notification.permission !== 'granted') { Notification.requestPermission() }
    activeTimers.forEach(timerId => {
      clearInterval(timerId);
    });
    setActiveTimers([]);
    setNotifiedTimers([]);
    xnotifiedTimers = [];
    try {
      lastClickedInputValue.current = inputValue;
      var bTrynftArray = [];
      var bTrynftwArray = [];
      var bTrybuildArray = [];
      var bTryskillArray = [];
      var bTrybudArray = [];
      if (localStorage.getItem("SFLManData") !== null) {
        const cookieValue = localStorage.getItem("SFLManData");
        var loadedData = JSON.parse(cookieValue);
        bTrynftArray = loadedData.bTrynft.length > 0 ? loadedData.bTrynft.reduce((acc, item) => { acc[item.name] = item.value; return acc; }, {}) : "";
        bTrynftwArray = loadedData.bTrynftw.length > 0 ? loadedData.bTrynftw.reduce((acc, item) => { acc[item.name] = item.value; return acc; }, {}) : "";
        bTrybuildArray = loadedData.bTrybuild.length > 0 ? loadedData.bTrybuild.reduce((acc, item) => { acc[item.name] = item.value; return acc; }, {}) : "";
        bTryskillArray = loadedData.bTryskill.length > 0 ? loadedData.bTryskill.reduce((acc, item) => { acc[item.name] = item.value; return acc; }, {}) : "";
        bTrybudArray = loadedData.bTrybud.length > 0 ? loadedData.bTrybud.reduce((acc, item) => { acc[item.name] = item.value; return acc; }, {}) : "";
      }
      //if (testb === false) {
      const response = await fetch("/getfarm", {
        method: 'GET',
        headers: {
          frmid: inputValue,
          inputkeep: inputKeep,
          xtrynft: JSON.stringify(bTrynftArray),
          xtrynftw: JSON.stringify(bTrynftwArray),
          xtrybuild: JSON.stringify(bTrybuildArray),
          xtryskill: JSON.stringify(bTryskillArray),
          xtrybud: JSON.stringify(bTrybudArray),
        }
      });
      if (response.ok) {
        const responseData = await response.json();
        //console.log(responseData);
        setFarmData(responseData.frmData);
        setBumpkinData(responseData.Bumpkin);
        it = responseData.it;
        getPlanted(it);
        food = responseData.food;
        fish = responseData.fish;
        flower = responseData.flower;
        nft = responseData.nft;
        nftw = responseData.nftw;
        skill = responseData.skill;
        buildng = responseData.buildng;
        bud = responseData.bud;
        spot = responseData.spot;
        if (localStorage.getItem("SFLManData") === null) {
          setFarmit(it);
          setCookit(food);
          setTryit(nft, nftw, skill, buildng, bud);
          setActive(nft, nftw, skill, buildng, bud);
        } else {
          getFarmit(it);
          getCookit(food);
          getTryit(nft, nftw, skill, buildng, bud);
          setActive(nft, nftw, skill, buildng, bud);
        }
        //fishing = responseData.fishing;
        //Fishing = responseData.FishingCasts;
        fishingDetails = responseData.fishingDetails;
        BoostNFT = responseData.BoostNFT;
        BoostSkill = responseData.BoostSkill;
        mutantchickens = responseData.mutantchickens;
        sTickets = responseData.sTickets;
        setdeliveriesData(responseData.orderstable);
        buildngf = responseData.buildngf;
        frmOwner = responseData.frmOwner;
        //expand = responseData.expand;
        xexpandData = responseData.expandData;
        setfromtoexpand(responseData.expandData);
        getFromToExpand(fromexpand, toexpand);
        wklactivity = responseData.wklactivity;
        username = responseData.username;
        isAbo = responseData.isabo;
        NFTPrice();
        setReqState('');
      } else {
        if (response.status === 429) {
          setReqState('Too many requests, wait a few seconds');
        } else {
          setReqState(`Error : ${response.status}`);
          //localStorage.clear();
          //console.log("Error, cleared local data");
        }
      }
      if (selectedInv === "activity") {
        getActivity();
      }
      /*} else {
        const responseData = xrespFarm;
        setFarmData(responseData.frmData);
        setBumpkinData(responseData.Bumpkin);
        it = responseData.it;
        itb = responseData.itb;
        nft = responseData.nft;
        nftw = responseData.nftw;
        skill = responseData.skill;
        buildng = responseData.buildng;
        bud = responseData.bud;
        spot = responseData.spot;
        BoostNFT = responseData.BoostNFT;
        BoostSkill = responseData.BoostSkill;
        //sflsupply = responseData["sflsupply"];
        mutantchickens = responseData.mutantchickens;
        sTickets = responseData.sTickets;
        NFTPrice();
        setReqState('');
      } */
    } catch (error) {
      //setReqState(`Error : ${error}`);
      console.log(`Error : ${error}`);
      //localStorage.clear();
      //console.log("Error, cleared local data");
    }
  };

  const handleTraderClick = () => {
    setGraphType("Trader");
    setShowfGraph(true);
  };
  const handleNiftyClick = () => {
    setGraphType("Nifty");
    setShowfGraph(true);
  };
  const handleOSClick = () => {
    setGraphType("OpenSea");
    setShowfGraph(true);
  };
  const handleTradeListClick = async (frmid, element, platform) => {
    /* const textarea = document.createElement('textarea');
    textarea.value = frmid;
    document.body.appendChild(textarea);
    textarea.select();
    const success = document.execCommand('copy');
    if (success) {
      const tooltip = document.createElement('div');
      tooltip.classList.add('tooltipfrmid');
      tooltip.textContent = frmid + ' FarmID copied !';
      const rect = element.getBoundingClientRect();
      tooltip.style.top = rect.top - 40 + 'px';
      tooltip.style.left = rect.left + 'px';
      document.body.appendChild(tooltip);
      setTimeout(() => {
        document.body.removeChild(tooltip);
      }, 2000);
      document.body.removeChild(textarea);
    }; */
    platformListings = platform;
    const response = await fetch("/get50listing", {
      method: 'GET',
      headers: {
        frmid: frmid,
        listid: element,
        platform: platform,
      }
    });
    if (response.ok) {
      try {
        const responseData = await response.json();
        if (responseData !== 'error') {
          //const responseData = response.body;
          setlistingsData(responseData);
          setShowCadre(true);
          //console.log(responseData);
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      console.log(response);
    }
  }
  const handleDonClick = (address, element) => {
    const textarea = document.createElement('textarea');
    textarea.value = address;
    document.body.appendChild(textarea);
    textarea.select();
    const success = document.execCommand('copy');
    if (success) {
      const tooltip = document.createElement('div');
      tooltip.classList.add('tooltipfrmid');
      tooltip.textContent = address + ' copied !';
      const rect = element.getBoundingClientRect();
      tooltip.style.top = rect.top + 40 + 'px';
      tooltip.style.left = rect.left - 70 + 'px';
      document.body.appendChild(tooltip);
      setTimeout(() => {
        document.body.removeChild(tooltip);
      }, 2000);
      document.body.removeChild(textarea);
    };
  }
  const handleCostCheckedChange = (event) => {
    setCostChecked(event.target.checked);
  };
  const handleTryCheckedChange = (event) => {
    setTryChecked(event.target.checked);
  };
  const handleBurnCheckedChange = (event) => {
    setBurnChecked(event.target.checked);
  };
  const handleFarmitChange = (item) => {
    it[item].farmit = it[item].farmit === 1 ? 0 : 1;
    setFarmit(it);
    setInv();
    setCookie();
  };
  const handleCookitChange = (itemh) => {
    food[itemh].cookit = food[itemh].cookit === 1 ? 0 : 1;
    setCookit(food);
    setCook();
    setCookie();
  };
  const handleIncrement = (item, xtry, max) => {
    /* setxHrvst((prevHrvst) => {
      if (prevHrvst[item] < max) {
        const updatedHrvst = [...prevHrvst];
        updatedHrvst[item] += 1;
        return updatedHrvst;
      }
      return prevHrvst;
    }); */
    if (xtry) {
      if (xHrvsttry[item] < max) { xHrvsttry[item] += 1 }
    } else {
      if (xHrvst[item] < max) { xHrvst[item] += 1 }
    }
    setInv();
    setCookie();
  };
  const handleDecrement = (item, xtry) => {
    /* setxHrvst((prevHrvst) => {
      if (prevHrvst[item] > 1) {
        const updatedHrvst = [...prevHrvst];
        updatedHrvst[item] -= 1;
        return updatedHrvst;
      }
      return prevHrvst;
    }); */
    if (xtry) {
      if (xHrvsttry[item] > 1) { xHrvsttry[item] -= 1 }
    } else {
      if (xHrvst[item] > 1) { xHrvst[item] -= 1 }
    }
    setInv();
    setCookie();
  };
  const handleFromExpandChange = (xIndex) => {
    //setfromexpand(xIndex);
    setfromexpand(prevState => xIndex);
    console.log(xIndex);
  };
  const handleToExpandChange = (xIndex) => {
    settoexpand(xIndex);
    console.log(xIndex);
  };
  const handleSetHrvMax = (xtry) => {
    if (xtry) {
      const itemMx = Object.keys(HrvstMaxtry);
      itemMx.map(item => {
        xHrvsttry[item] = HrvstMaxtry[item];
        //console.log(xHrvsttry);
      });
      //xHrvsttry = HrvstMaxtry.slice();
    } else {
      const itemMx = Object.keys(HrvstMax);
      itemMx.map(item => {
        xHrvst[item] = HrvstMax[item];
        //console.log(xHrvst);
      });
      //xHrvst = HrvstMax.slice();
    }
    //setHarvestD(xHrsvtortry);
    setInv();
    setCookie();
  };

  function setInv() {
    if (farmData.inventory) {
      const inventoryEntries = Object.entries(farmData.inventory);
      var pinventoryEntries = "";
      if (farmData.previousInventory) { pinventoryEntries = Object.entries(farmData.previousInventory) }
      const itemOrder = Object.keys(it);
      const burnortry = !TryChecked ? "burn" : "burntry";
      if (selectedQuantity === "daily") {
        const stoneSpot = !TryChecked ? it["Stone"].farmit * (xHrvst["Stone"] * spot.stone) : it["Stone"].farmit * (xHrvsttry["Stone"] * spot.stone);
        const ironSpot = !TryChecked ? it["Iron"].farmit * (xHrvst["Iron"] * spot.iron) : it["Iron"].farmit * (xHrvsttry["Iron"] * spot.iron);
        const goldSpot = !TryChecked ? it["Gold"].farmit * (xHrvst["Gold"] * spot.gold) : it["Gold"].farmit * (xHrvsttry["Gold"] * spot.gold);
        const crimestoneSpot = !TryChecked ? it["Crimstone"].farmit * (xHrvst["Crimstone"] * spot.crimstone) : it["Crimstone"].farmit * (xHrvsttry["Crimstone"] * spot.crimstone);
        const sunstoneSpot = !TryChecked ? it["Sunstone"].farmit * (xHrvst["Sunstone"] * spot.sunstone) : it["Sunstone"].farmit * (xHrvsttry["Sunstone"] * spot.sunstone);
        const oilSpot = !TryChecked ? it["Oil"].farmit * (xHrvst["Oil"] * spot.oil) : it["Oil"].farmit * (xHrvsttry["Oil"] * spot.oil);
        xBurning[burnortry]["Wood"] = (stoneSpot * 3) + (ironSpot * 3) + (goldSpot * 3) + (crimestoneSpot * 3) + (sunstoneSpot * 3) + (oilSpot * 25);
        xBurning[burnortry]["Stone"] = (ironSpot * 5);
        xBurning[burnortry]["Iron"] = (goldSpot * 5) + (oilSpot * 10);
        xBurning[burnortry]["Gold"] = (crimestoneSpot * 3) + (sunstoneSpot * 3);
      }
      const sortedInventoryItems = itemOrder.map(item => {
        const quantity = inventoryEntries.find(([entryItem]) => entryItem === item)?.[1] || 0;
        return [item, quantity];
      });
      const priceTEntries = Object.entries(priceDataT);
      const itEntries = Object.entries(it);
      priceTEntries.forEach(([item], index) => {
        for (var j = 0; j < itEntries.length; j++) {
          if (Number(it[itEntries[j][0]].id) === priceTEntries[item][1].id) {
            it[itEntries[j][0]].farmid = priceTEntries[item][1].farmid;
            break;
          }
        }
      });
      var totTimeCrp = 0;
      var totTimeRs = 0;
      var totCost = 0;
      var totShop = 0;
      var totTrader = 0;
      var totNifty = 0;
      var totOS = 0;
      let invIndex = 0;
      const inventoryItemsCrop = setInvContent(pinventoryEntries, sortedInventoryItems, totCost, totShop, totTrader, totNifty, totOS, totTimeCrp, totTimeRs, invIndex, "crop");
      totTimeCrp = inventoryItemsCrop.totTimeCrp;
      totCost = inventoryItemsCrop.totCost;
      totShop = inventoryItemsCrop.totShop;
      totTrader = inventoryItemsCrop.totTrader;
      totNifty = inventoryItemsCrop.totNifty;
      totOS = inventoryItemsCrop.totOS;
      invIndex = inventoryItemsCrop.invIndex;
      var tprctcN = 0;
      var tprctcO = 0;
      tprctcN = inventoryItemsCrop.totcTrader > 0 ? parseFloat(((inventoryItemsCrop.totcNifty - inventoryItemsCrop.totcTrader) / inventoryItemsCrop.totcTrader) * 100).toFixed(0) : "";
      tprctcO = inventoryItemsCrop.totcTrader > 0 ? parseFloat(((inventoryItemsCrop.totcOS - inventoryItemsCrop.totcTrader) / inventoryItemsCrop.totcTrader) * 100).toFixed(0) : "";
      const totCrop = selectedQuant !== "unit" ?
        (<>
          {xListeCol[0][1] === 1 ? (<td className="ttcenter" >TOTAL</td>) : ("")}
          <td className="td-icon">   </td>
          <td style={{ display: 'none' }}>ID</td>
          {xListeCol[1][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {selectedQuantity === "daily" ? (<td className="ttcenter"></td>) : ("")}
          {selectedQuantity === "daily" ? (<td className="ttcenter"></td>) : ("")}
          {selectedQuantity === "daily" ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[2][1] === 1 ? (<td className="ttcenter" style={{ color: `rgb(160, 160, 160)` }}></td>) : ("")}
          {xListeCol[3][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[4][1] === 1 ? (<td className="ttcenter">{parseFloat(inventoryItemsCrop.totcCost).toFixed(2)}</td>) : ("")}
          {xListeCol[5][1] === 1 ? (<td className="ttcenter">{parseFloat(inventoryItemsCrop.totcShop).toFixed(2)}</td>) : ("")}
          {xListeCol[6][1] === 1 ? (<td className="ttcenterbrd">{parseFloat(inventoryItemsCrop.totcTrader).toFixed(2)}</td>) : ("")}
          {xListeCol[17][1] === 1 && xListeCol[6][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[7][1] === 1 ? (<td className="ttcenter" style={{ color: `rgb(160, 160, 160)` }}></td>) : ("")}
          {xListeCol[8][1] === 1 && xListeCol[14][1] === 1 ? (<td className={tprctcN > -20 ? 'tdpdiffgrn' : 'tdpdiff'}>{tprctcN}{inventoryItemsCrop.totcTrader > 0 ? "%" : ""}</td>) : ("")}
          {xListeCol[8][1] === 1 ? (<td className="ttcenterbrd">{parseFloat(inventoryItemsCrop.totcNifty).toFixed(2)}</td>) : ("")}
          {xListeCol[17][1] === 1 && xListeCol[8][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[9][1] === 1 && xListeCol[14][1] === 1 ? (<td className={tprctcO > -20 ? 'tdpdiffgrn' : 'tdpdiff'}>{tprctcO}{inventoryItemsCrop.totcTrader > 0 ? "%" : ""}</td>) : ("")}
          {xListeCol[9][1] === 1 ? (<td className="ttcenterbrd">{parseFloat(inventoryItemsCrop.totcOS).toFixed(2)}</td>) : ("")}
          {xListeCol[17][1] === 1 && xListeCol[9][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[10][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[11][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[12][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[18][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[13][1] === 1 ? (<td className="ttcenter" style={{ color: `rgb(160, 160, 160)` }}></td>) : ("")}
          {xListeCol[15][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[16][1] === 1 ? (<td className="ttcenter" style={{ color: `rgb(160, 160, 160)` }}></td>) : ("")}
        </>) : ("");
      const inventoryItemsRes = setInvContent(pinventoryEntries, sortedInventoryItems, totCost, totShop, totTrader, totNifty, totOS, totTimeCrp, totTimeRs, invIndex, "mineral", "gem", "wood", "oil");
      totTimeRs = inventoryItemsCrop.totTimeRs;
      totCost = inventoryItemsRes.totCost;
      totShop = inventoryItemsRes.totShop;
      totTrader = inventoryItemsRes.totTrader;
      totNifty = inventoryItemsRes.totNifty;
      totOS = inventoryItemsRes.totOS;
      invIndex = inventoryItemsRes.invIndex;
      tprctcN = inventoryItemsRes.totcTrader > 0 ? parseFloat(((inventoryItemsRes.totcNifty - inventoryItemsRes.totcTrader) / inventoryItemsRes.totcTrader) * 100).toFixed(0) : "";
      tprctcO = inventoryItemsRes.totcTrader > 0 ? parseFloat(((inventoryItemsRes.totcOS - inventoryItemsRes.totcTrader) / inventoryItemsRes.totcTrader) * 100).toFixed(0) : "";
      const totRes = selectedQuant !== "unit" ?
        (<>
          {xListeCol[0][1] === 1 ? (<td className="ttcenter" >TOTAL</td>) : ("")}
          <td className="td-icon">   </td>
          <td style={{ display: 'none' }}>ID</td>
          {xListeCol[1][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {selectedQuantity === "daily" ? (<td className="ttcenter"></td>) : ("")}
          {selectedQuantity === "daily" ? (<td className="ttcenter"></td>) : ("")}
          {selectedQuantity === "daily" ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[2][1] === 1 ? (<td className="ttcenter" style={{ color: `rgb(160, 160, 160)` }}></td>) : ("")}
          {xListeCol[3][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[4][1] === 1 ? (<td className="ttcenter">{parseFloat(inventoryItemsRes.totcCost).toFixed(2)}</td>) : ("")}
          {xListeCol[5][1] === 1 ? (<td className="ttcenter">{parseFloat(inventoryItemsRes.totcShop).toFixed(2)}</td>) : ("")}
          {xListeCol[6][1] === 1 ? (<td className="ttcenterbrd">{parseFloat(inventoryItemsRes.totcTrader).toFixed(2)}</td>) : ("")}
          {xListeCol[17][1] === 1 && xListeCol[6][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[7][1] === 1 ? (<td className="ttcenter" style={{ color: `rgb(160, 160, 160)` }}></td>) : ("")}
          {xListeCol[8][1] === 1 && xListeCol[14][1] === 1 ? (<td className={tprctcN > -20 ? 'tdpdiffgrn' : 'tdpdiff'}>{tprctcN}{inventoryItemsRes.totcTrader > 0 ? "%" : ""}</td>) : ("")}
          {xListeCol[8][1] === 1 ? (<td className="ttcenterbrd">{parseFloat(inventoryItemsRes.totcNifty).toFixed(2)}</td>) : ("")}
          {xListeCol[17][1] === 1 && xListeCol[8][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[9][1] === 1 && xListeCol[14][1] === 1 ? (<td className={tprctcO > -20 ? 'tdpdiffgrn' : 'tdpdiff'}>{tprctcO}{inventoryItemsRes.totcTrader > 0 ? "%" : ""}</td>) : ("")}
          {xListeCol[9][1] === 1 ? (<td className="ttcenterbrd">{parseFloat(inventoryItemsRes.totcOS).toFixed(2)}</td>) : ("")}
          {xListeCol[17][1] === 1 && xListeCol[9][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[10][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[11][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[12][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[18][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[13][1] === 1 ? (<td className="ttcenter" style={{ color: `rgb(160, 160, 160)` }}></td>) : ("")}
          {xListeCol[15][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[16][1] === 1 ? (<td className="ttcenter" style={{ color: `rgb(160, 160, 160)` }}></td>) : ("")}
        </>) : ("");
      const inventoryItemsAnml = setInvContent(pinventoryEntries, sortedInventoryItems, totCost, totShop, totTrader, totNifty, totOS, totTimeCrp, totTimeRs, invIndex, "animal", "honey", "flower");
      //totTimeRs = inventoryItemsAnml.totTimeRs;
      totCost = inventoryItemsAnml.totCost;
      totShop = inventoryItemsAnml.totShop;
      totTrader = inventoryItemsAnml.totTrader;
      totNifty = inventoryItemsAnml.totNifty;
      totOS = inventoryItemsAnml.totOS;
      invIndex = inventoryItemsAnml.invIndex;
      tprctcN = inventoryItemsAnml.totcTrader > 0 ? parseFloat(((inventoryItemsAnml.totcNifty - inventoryItemsAnml.totcTrader) / inventoryItemsAnml.totcTrader) * 100).toFixed(0) : "";
      tprctcO = inventoryItemsAnml.totcTrader > 0 ? parseFloat(((inventoryItemsAnml.totcOS - inventoryItemsAnml.totcTrader) / inventoryItemsAnml.totcTrader) * 100).toFixed(0) : "";
      const totAnml = selectedQuant !== "unit" ?
        (<>
          {xListeCol[0][1] === 1 ? (<td className="ttcenter" >TOTAL</td>) : ("")}
          <td className="td-icon">   </td>
          <td style={{ display: 'none' }}>ID</td>
          {xListeCol[1][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {selectedQuantity === "daily" ? (<td className="ttcenter"></td>) : ("")}
          {selectedQuantity === "daily" ? (<td className="ttcenter"></td>) : ("")}
          {selectedQuantity === "daily" ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[2][1] === 1 ? (<td className="ttcenter" style={{ color: `rgb(160, 160, 160)` }}></td>) : ("")}
          {xListeCol[3][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[4][1] === 1 ? (<td className="ttcenter">{parseFloat(inventoryItemsAnml.totcCost).toFixed(2)}</td>) : ("")}
          {xListeCol[5][1] === 1 ? (<td className="ttcenter">{parseFloat(inventoryItemsAnml.totcShop).toFixed(2)}</td>) : ("")}
          {xListeCol[6][1] === 1 ? (<td className="ttcenterbrd">{parseFloat(inventoryItemsAnml.totcTrader).toFixed(2)}</td>) : ("")}
          {xListeCol[17][1] === 1 && xListeCol[6][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[7][1] === 1 ? (<td className="ttcenter" style={{ color: `rgb(160, 160, 160)` }}></td>) : ("")}
          {xListeCol[8][1] === 1 && xListeCol[14][1] === 1 ? (<td className={tprctcN > -20 ? 'tdpdiffgrn' : 'tdpdiff'}>{tprctcN}{inventoryItemsAnml.totcTrader > 0 ? "%" : ""}</td>) : ("")}
          {xListeCol[8][1] === 1 ? (<td className="ttcenterbrd">{parseFloat(inventoryItemsAnml.totcNifty).toFixed(2)}</td>) : ("")}
          {xListeCol[17][1] === 1 && xListeCol[8][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[9][1] === 1 && xListeCol[14][1] === 1 ? (<td className={tprctcO > -20 ? 'tdpdiffgrn' : 'tdpdiff'}>{tprctcO}{inventoryItemsAnml.totcTrader > 0 ? "%" : ""}</td>) : ("")}
          {xListeCol[9][1] === 1 ? (<td className="ttcenterbrd">{parseFloat(inventoryItemsAnml.totcOS).toFixed(2)}</td>) : ("")}
          {xListeCol[17][1] === 1 && xListeCol[9][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[10][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[11][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[12][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[18][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[13][1] === 1 ? (<td className="ttcenter" style={{ color: `rgb(160, 160, 160)` }}></td>) : ("")}
          {xListeCol[15][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[16][1] === 1 ? (<td className="ttcenter" style={{ color: `rgb(160, 160, 160)` }}></td>) : ("")}
        </>) : ("");
      const inventoryItemsFruit = setInvContent(pinventoryEntries, sortedInventoryItems, totCost, totShop, totTrader, totNifty, totOS, totTimeCrp, totTimeRs, invIndex, "fruit", "mushroom");
      totCost = inventoryItemsFruit.totCost;
      totShop = inventoryItemsFruit.totShop;
      totTrader = inventoryItemsFruit.totTrader;
      totNifty = inventoryItemsFruit.totNifty;
      totOS = inventoryItemsFruit.totOS;
      invIndex = inventoryItemsFruit.invIndex;
      const tprctN = totTrader > 0 ? parseFloat(((totNifty - totTrader) / totTrader) * 100).toFixed(0) : "";
      const tprctO = totTrader > 0 ? parseFloat(((totOS - totTrader) / totTrader) * 100).toFixed(0) : "";
      tprctcN = inventoryItemsFruit.totcTrader > 0 ? parseFloat(((inventoryItemsFruit.totcNifty - inventoryItemsFruit.totcTrader) / inventoryItemsFruit.totcTrader) * 100).toFixed(0) : "";
      tprctcO = inventoryItemsFruit.totcTrader > 0 ? parseFloat(((inventoryItemsFruit.totcOS - inventoryItemsFruit.totcTrader) / inventoryItemsFruit.totcTrader) * 100).toFixed(0) : "";
      const totFruit = selectedQuant !== "unit" ?
        (<>
          {xListeCol[0][1] === 1 ? (<td className="ttcenter" >TOTAL</td>) : ("")}
          <td className="td-icon">   </td>
          <td style={{ display: 'none' }}>ID</td>
          {xListeCol[1][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {selectedQuantity === "daily" ? (<td className="ttcenter"></td>) : ("")}
          {selectedQuantity === "daily" ? (<td className="ttcenter"></td>) : ("")}
          {selectedQuantity === "daily" ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[2][1] === 1 ? (<td className="ttcenter" style={{ color: `rgb(160, 160, 160)` }}></td>) : ("")}
          {xListeCol[3][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[4][1] === 1 ? (<td className="ttcenter">{parseFloat(inventoryItemsFruit.totcCost).toFixed(2)}</td>) : ("")}
          {xListeCol[5][1] === 1 ? (<td className="ttcenter">{parseFloat(inventoryItemsFruit.totcShop).toFixed(2)}</td>) : ("")}
          {xListeCol[6][1] === 1 ? (<td className="ttcenterbrd">{parseFloat(inventoryItemsFruit.totcTrader).toFixed(2)}</td>) : ("")}
          {xListeCol[17][1] === 1 && xListeCol[6][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[7][1] === 1 ? (<td className="ttcenter" style={{ color: `rgb(160, 160, 160)` }}></td>) : ("")}
          {xListeCol[8][1] === 1 && xListeCol[14][1] === 1 ? (<td className={tprctcN > -20 ? 'tdpdiffgrn' : 'tdpdiff'}>{tprctcN}{inventoryItemsFruit.totcTrader > 0 ? "%" : ""}</td>) : ("")}
          {xListeCol[8][1] === 1 ? (<td className="ttcenterbrd">{parseFloat(inventoryItemsFruit.totcNifty).toFixed(2)}</td>) : ("")}
          {xListeCol[17][1] === 1 && xListeCol[8][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[9][1] === 1 && xListeCol[14][1] === 1 ? (<td className={tprctcO > -20 ? 'tdpdiffgrn' : 'tdpdiff'}>{tprctcO}{inventoryItemsFruit.totcTrader > 0 ? "%" : ""}</td>) : ("")}
          {xListeCol[9][1] === 1 ? (<td className="ttcenterbrd">{parseFloat(inventoryItemsFruit.totcOS).toFixed(2)}</td>) : ("")}
          {xListeCol[17][1] === 1 && xListeCol[9][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[10][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[11][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[12][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[18][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[13][1] === 1 ? (<td className="ttcenter" style={{ color: `rgb(160, 160, 160)` }}></td>) : ("")}
          {xListeCol[15][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
          {xListeCol[16][1] === 1 ? (<td className="ttcenter" style={{ color: `rgb(160, 160, 160)` }}></td>) : ("")}
        </>) : ("");
      var showBldinv = true;
      var BldItems = "";
      if (showBldinv) {
        const bldOrder = ["Fire Pit", "Kitchen", "Deli", "Bakery", "Smoothie Shack", "Compost Bin", "Turbo Composter", "Premium Composter"];
        const sortedBldItems = bldOrder.map(item => {
          const quantity = inventoryEntries.find(([entryItem]) => entryItem === item)?.[1] || 0;
          return [item, quantity];
        });
        BldItems = sortedBldItems.map(([building], index) => {
          if (buildngf[building]) {
            if (buildngf[building].readyAt > 0) {
              const ico = buildngf[building].img;
              const item = building;
              const icost = buildngf[building].cost;
              const irdyat = buildngf[building].readyAt;
              var xnow = new Date().getTime();
              const ximgrdy = irdyat > 0 && irdyat < xnow ? <img src={imgrdy} alt="" /> : "";
              const ximgfood = <img src={buildngf[building].itimg} alt="" style={{ width: '15px', height: '15px' }} />
              const iquant = buildngf[building].quant;
              const pNifty = buildngf[building].costp2pn;
              const pOS = buildngf[building].costp2po;
              const pTrad = buildngf[building].costp2pt;
              return (
                <tr key={index}>
                  {xListeCol[0][1] === 1 ? (<td></td>) : ("")}
                  <td id="iccolumn"><i><img src={ico} alt={''} className="itico" /></i></td>
                  <td style={{ display: 'none' }}></td>
                  {xListeCol[1][1] === 1 ? (<td className="tditem">{item}</td>) : ("")}
                  {selectedQuantity === "daily" ? (<td className="tdcenter"></td>) : ("")}
                  {selectedQuantity === "daily" ? (<td className="tdcenter"></td>) : ("")}
                  {selectedQuantity === "daily" ? (<td className="tdcenter"></td>) : ("")}
                  {xListeCol[2][1] === 1 ? (<td className="tdcenter" style={{ color: `rgb(160, 160, 160)` }}></td>) : ("")}
                  {xListeCol[3][1] === 1 ? (<td className="tdcenter" style={{ color: `rgb(200, 200, 200)` }}></td>) : ("")}
                  {xListeCol[4][1] === 1 ? (<td className="tdcenter">{frmtNb(icost)}</td>) : ("")}
                  {xListeCol[5][1] === 1 ? (<td className="tdcenter"></td>) : ("")}
                  {xListeCol[6][1] === 1 ? (<td className="tdcenterbrd">{frmtNb(pTrad)}</td>) : ("")}
                  {xListeCol[17][1] === 1 && xListeCol[6][1] === 1 ? (<td className="tdcenter"></td>) : ("")}
                  {xListeCol[7][1] === 1 ? (<td className="tdcenter"></td>) : ("")}
                  {xListeCol[8][1] === 1 && xListeCol[14][1] === 1 ? (<td className="tdcenter"></td>) : ("")}
                  {xListeCol[8][1] === 1 ? (<td className="tdcenterbrd">{frmtNb(pNifty)}</td>) : ("")}
                  {xListeCol[17][1] === 1 && xListeCol[8][1] === 1 ? (<td className="tdcenter"></td>) : ("")}
                  {xListeCol[9][1] === 1 && xListeCol[14][1] === 1 ? (<td className="tdcenter"></td>) : ("")}
                  {xListeCol[9][1] === 1 ? (<td className="tdcenterbrd">{frmtNb(pOS)}</td>) : ("")}
                  {xListeCol[17][1] === 1 && xListeCol[9][1] === 1 ? (<td className="tdcenter"></td>) : ("")}
                  {xListeCol[10][1] === 1 ? (<td className="tdcenter" style={{ color: `rgb(255, 234, 204)` }}></td>) : ("")}
                  {xListeCol[11][1] === 1 ? (<td className="tdcenter" style={{ color: `rgb(255, 225, 183)` }}></td>) : ("")}
                  {xListeCol[12][1] === 1 ? (<td className="tdcenter" style={{ color: `rgb(253, 215, 162)` }}>{iquant > 0 ? iquant : ""}{ximgfood}</td>) : ("")}
                  {xListeCol[18][1] === 1 ? (<td id={`timer-${index}`} className="tdcenterbrd">{(irdyat > 0 ? selectedReady === "when" ? (<span>{formatdate(irdyat)}{' '}{ximgrdy}</span>) : <Timer key={`timer-${index}`} timestamp={irdyat} index={item} onTimerFinish={handleTimerFinish} /> : "")}</td>) : ("")}
                  {xListeCol[13][1] === 1 ? (<td className="tdcenter" style={{ color: `rgb(160, 160, 160)` }}></td>) : ("")}
                  {xListeCol[15][1] === 1 ? (<td className="tdcenter" style={{ color: `rgb(255, 204, 132)` }}></td>) : ("")}
                  {xListeCol[16][1] === 1 ? (<td className="tdcenter" style={{ color: `rgb(160, 160, 160)` }}></td>) : ("")}
                </tr>
              );
            }
          }
        });
      }
      const totTime = convTime(totTimeCrp);
      const tableContent = (
        <>
          <table className="table">
            <thead>
              <tr>
                {xListeCol[0][1] === 1 ? (<th className="thcenter" >Hoard</th>) : ("")}
                <th className="th-icon">   </th>
                <td style={{ display: 'none' }}>ID</td>
                {xListeCol[1][1] === 1 ? (<th className="thcenter">Item</th>) : ("")}
                {selectedQuantity === "daily" ? (<th className="thcenter"> </th>) : ("")}
                {selectedQuantity === "daily" ? (<th className="thcenter"><div>Hrv</div><div>max</div></th>) : ("")}
                {selectedQuantity === "daily" ? (<th className="thcenter"><div>Hrv</div><div>
                  <img src="/icon/ui/arrow_left.png" alt="Hrv = Hrv max" title="Set Hrv to Hrv Max" onClick={() => handleSetHrvMax(TryChecked)} style={{ width: '11px', height: '11px' }} /></div>
                </th>) : ("")}
                {xListeCol[2][1] === 1 ? (<th className="thcenter" style={{ color: `rgb(160, 160, 160)` }}>
                  <div className="selectquantityback"><FormControl variant="standard" id="formselectquant" className="selectquant" size="small">
                    <InputLabel>Quantity</InputLabel>
                    <Select value={selectedQuantity} onChange={handleChangeQuantity}>
                      <MenuItem value="farm">Farm</MenuItem>
                      <MenuItem value="daily">Daily</MenuItem>
                      <MenuItem value="blockbuck">BlockBuck</MenuItem>
                      <MenuItem value="custom">Custom</MenuItem>
                    </Select></FormControl></div>
                </th>) : ("")}
                {xListeCol[3][1] === 1 ? (<th className="thcenter">{selectedQuantity === "daily" ? (<div><div>Time</div><div>{totTime}</div></div>) : ("Time")}</th>) : ("")}
                {xListeCol[4][1] === 1 ? (<th className="thcenter">
                  <div className="selectquantback"><FormControl variant="standard" id="formselectquant" className="selectquant" size="small">
                    <InputLabel>Cost</InputLabel>
                    <Select value={selectedQuant} onChange={handleChangeQuant}>
                      <MenuItem value="unit">/ Unit</MenuItem>
                      <MenuItem value="quant">x Quantity</MenuItem>
                    </Select></FormControl></div>
                  <div className="checkcost"><input type="checkbox" id="CostColumnCheckbox" checked={CostChecked} onChange={handleCostCheckedChange} /></div>
                </th>) : ("")}
                {xListeCol[5][1] === 1 ? (<th className="thcenter">Shop</th>) : ("")}
                {xListeCol[6][1] === 1 ? (<th className="thtrad" onClick={() => handleTraderClick()}><div className="overlay-trad"></div>Trader</th>) : ("")}
                {xListeCol[17][1] === 1 && xListeCol[6][1] === 1 ? (<th className="thcenter">Coef</th>) : ("")}
                {xListeCol[7][1] === 1 ? (<th className="thcenter" style={{ color: `rgb(160, 160, 160)` }}>Withdraw</th>) : ("")}
                {xListeCol[8][1] === 1 && xListeCol[14][1] === 1 ? (<th className="thcenter">Diff</th>) : ("")}
                {xListeCol[8][1] === 1 ? (<th className="thnifty" onClick={() => handleNiftyClick()}><div className="overlay-nifty"></div> </th>) : ("")}
                {xListeCol[17][1] === 1 && xListeCol[8][1] === 1 ? (<th className="thcenter">Coef</th>) : ("")}
                {xListeCol[9][1] === 1 && xListeCol[14][1] === 1 ? (<th className="thcenter">Diff</th>) : ("")}
                {xListeCol[9][1] === 1 ? (<th className="thos" onClick={() => handleOSClick()}><div className="overlay-os"></div> </th>) : ("")}
                {xListeCol[17][1] === 1 && xListeCol[9][1] === 1 ? (<th className="thcenter">Coef</th>) : ("")}
                {xListeCol[10][1] === 1 ? (<th className="thcenter">Yield</th>) : ("")}
                {xListeCol[11][1] === 1 ? (<th className="thcenter">Harvest</th>) : ("")}
                {xListeCol[12][1] === 1 ? (<th className="thcenter">ToHarvest</th>) : ("")}
                {xListeCol[18][1] === 1 ? (<th className="tdcenterbrd">
                  <div className="selectreadyback"><FormControl variant="standard" id="formselectquant" className="selectquant" size="small">
                    <InputLabel>Ready</InputLabel>
                    <Select value={selectedReady} onChange={handleChangeReady}>
                      <MenuItem value="when">When</MenuItem>
                      <MenuItem value="remain">Remain</MenuItem>
                    </Select></FormControl></div>
                </th>) : ("")}
                {xListeCol[13][1] === 1 ? (<th className="thcenter" style={{ color: `rgb(160, 160, 160)` }}>BBprod</th>) : ("")}
                {xListeCol[15][1] === 1 ? (<th className="thcenter"><div className="selectquantityback"><FormControl variant="standard" id="formselectquant" className="selectquant" size="small">
                  <InputLabel>Daily SFL</InputLabel>
                  <Select value={selectedDsfl} onChange={handleChangeDsfl}>
                    <MenuItem value="trader">Trader</MenuItem>
                    <MenuItem value="nifty">Niftyswap</MenuItem>
                    <MenuItem value="opensea">OpenSea</MenuItem>
                    <MenuItem value="max">Higher</MenuItem>
                    {/* <MenuItem value="min">Lower</MenuItem> */}
                  </Select></FormControl></div></th>) : ("")}
                {xListeCol[16][1] === 1 ? (<th className="thcenter" style={{ color: `rgb(160, 160, 160)` }}>DailyMax</th>) : ("")}
              </tr>
              {selectedQuant !== "unit" ?
                <tr style={{ position: "sticky" }}>
                  {xListeCol[0][1] === 1 ? (<td className="ttcenter" >TOTAL</td>) : ("")}
                  <td className="td-icon">   </td>
                  <td style={{ display: 'none' }}>ID</td>
                  {xListeCol[1][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
                  {selectedQuantity === "daily" ? (<td className="ttcenter"></td>) : ("")}
                  {selectedQuantity === "daily" ? (<td className="ttcenter"></td>) : ("")}
                  {selectedQuantity === "daily" ? (<td className="ttcenter"></td>) : ("")}
                  {xListeCol[2][1] === 1 ? (<td className="ttcenter" style={{ color: `rgb(160, 160, 160)` }}></td>) : ("")}
                  {xListeCol[3][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
                  {xListeCol[4][1] === 1 ? (<td className="ttcenter">{parseFloat(totCost).toFixed(2)}</td>) : ("")}
                  {xListeCol[5][1] === 1 ? (<td className="ttcenter">{parseFloat(totShop).toFixed(2)}</td>) : ("")}
                  {xListeCol[6][1] === 1 ? (<td className="ttcenterbrd">{parseFloat(totTrader).toFixed(2)}</td>) : ("")}
                  {xListeCol[17][1] === 1 && xListeCol[6][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
                  {xListeCol[7][1] === 1 ? (<td className="ttcenter" style={{ color: `rgb(160, 160, 160)` }}></td>) : ("")}
                  {xListeCol[8][1] === 1 && xListeCol[14][1] === 1 ? (<td className={tprctN > -20 ? 'tdpdiffgrn' : 'tdpdiff'}>{tprctN}{totTrader > 0 ? "%" : ""}</td>) : ("")}
                  {xListeCol[8][1] === 1 ? (<td className="ttcenterbrd">{parseFloat(totNifty).toFixed(2)}</td>) : ("")}
                  {xListeCol[17][1] === 1 && xListeCol[8][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
                  {xListeCol[9][1] === 1 && xListeCol[14][1] === 1 ? (<td className={tprctO > -20 ? 'tdpdiffgrn' : 'tdpdiff'}>{tprctO}{totTrader > 0 ? "%" : ""}</td>) : ("")}
                  {xListeCol[9][1] === 1 ? (<td className="ttcenterbrd">{parseFloat(totOS).toFixed(2)}</td>) : ("")}
                  {xListeCol[17][1] === 1 && xListeCol[9][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
                  {xListeCol[10][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
                  {xListeCol[11][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
                  {xListeCol[12][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
                  {xListeCol[18][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
                  {xListeCol[13][1] === 1 ? (<td className="ttcenter" style={{ color: `rgb(160, 160, 160)` }}></td>) : ("")}
                  {xListeCol[15][1] === 1 ? (<td className="ttcenter"></td>) : ("")}
                  {xListeCol[16][1] === 1 ? (<td className="ttcenter" style={{ color: `rgb(160, 160, 160)` }}></td>) : ("")}
                </tr> : ("")}
            </thead>
            <tbody>
              {selectedQuant !== "unit" ?
                (<tr style={{ position: "sticky" }}>{totCrop}</tr>) : ""}
              {inventoryItemsCrop.inventoryItems}
              {selectedQuant !== "unit" ?
                (<tr style={{ position: "sticky" }}>{totRes}</tr>) : ""}
              {inventoryItemsRes.inventoryItems}
              {selectedQuant !== "unit" ?
                (<tr style={{ position: "sticky" }}>{totAnml}</tr>) : ""}
              {inventoryItemsAnml.inventoryItems}
              {selectedQuant !== "unit" ?
                (<tr style={{ position: "sticky" }}>{totFruit}</tr>) : ""}
              {inventoryItemsFruit.inventoryItems}
              {BldItems}
            </tbody>
          </table>
        </>
      );
      invIndex++;
      //setFarmID(xtfarmID);
      setinvData(tableContent);
      setMutants(mutantchickens);
      setsTickets(sTickets);
      //console.log(`Wood : ${new Date(it["Wood"].rdyat)} / ${it["Wood"].rdyat}`);
      //console.log(`Iron : ${new Date(it["Iron"].rdyat)} / ${it["Iron"].rdyat}`);
      //if (xinitinv = false) { xinitinv = true }
    }
  }
  function setInvContent(pinventoryEntries, sortedInventoryItems, totCost, totShop, totTrader, totNifty, totOS, totTimeCrp, totTimeRs, invIndex, ItCat1, ItCat2, ItCat3, ItCat4) {
    const farmTime = inputFarmTime / 24;
    const MaxBB = inputMaxBB;
    const burnortry = !TryChecked ? "burn" : "burntry";
    var totcCost = 0;
    var totcShop = 0;
    var totcTrader = 0;
    var totcNifty = 0;
    var totcOS = 0;
    //let xIndex = 0;
    const TaxTradSfl = 0.25 / priceData[2];
    const catArray = [ItCat1, ItCat2, ItCat3, ItCat4].filter(Boolean);
    const CorespondantItems = sortedInventoryItems.filter(item => catArray.includes(it[item[0]].cat));
    const tableLen = CorespondantItems.length;
    invIndex += tableLen;
    const imgbee = <img src="./icon/ui/bee.webp" alt={''} className="nodico" title="Bee swarm" style={{ width: '15px', height: '15px' }} />;
    const inventoryItems = sortedInventoryItems.map(([item, quantity], index) => {
      let xIndex = index;
      const firstind = index === invIndex - tableLen;
      const lastind = index === invIndex - 1;
      const cellStyle = {};
      cellStyle.borderBottom = lastind ? '1px solid rgb(83, 51, 51)' : 'none';
      cellStyle.borderTop = firstind ? '1px solid rgb(83, 51, 51)' : 'none';
      //cellStyle.color = lastind ? `rgb(150, 50, 20)` : '';
      if ((quantity > 0 || it[item].tobharvest > 0) && catArray.includes(it[item].cat)) {
        const cobj = it[item];
        const icat = cobj ? cobj.cat : '';
        const ico = cobj ? cobj.img : '';
        const ido = cobj ? cobj.id : 0;
        const frmido = cobj ? cobj.farmid : 0;
        const ximgtrd = frmido === Number(inputValue) ? <img src={imgtrd} alt="" /> : "";
        //const ximgtrdOS = frmOwner === priceDataO[i].makerof ? <img src={imgtrd} alt="" /> : "";
        const maxh = cobj ? cobj.hoard : 0;
        var costp = cobj ? !TryChecked ? cobj.cost : cobj.costtry : 0;
        var pShop = cobj ? cobj.shop : 0;
        var time = cobj ? !TryChecked ? cobj.time : cobj.timetry : 0;
        const timmenbr = convtimenbr(time);
        const rtimmenbr = convtimenbr(cobj.time);
        const timmenbrtry = convtimenbr(cobj.timetry);
        const imyield = cobj ? !TryChecked ? cobj.myield : cobj.myieldtry : 0;
        const iharvest = cobj ? !TryChecked ? cobj.harvest : cobj.harvesttry : 0;
        const rharvest = cobj.harvest ? cobj.harvest : 0;
        const rharvesttry = cobj.harvesttry ? cobj.harvesttry : 0;
        const iharvestdmax = cobj.harvestdmax ? cobj.harvestdmax : 0;
        const iharvestdmaxtry = cobj.harvestdmaxtry ? cobj.harvestdmaxtry : 0;
        const i2bharvest = cobj ? cobj.tobharvest : 0;
        const iplanted = cobj ? cobj.planted : 0;
        const irdyat = cobj ? cobj.rdyat : 0;
        var xnow = new Date().getTime();
        const ximgrdy = irdyat > 0 && irdyat < xnow ? <img src={imgrdy} alt="" /> : "";
        const itradmax = cobj ? cobj.tradmax : 0;
        const istock = cobj ? cobj.stock : 0;
        const ifrmit = cobj ? cobj.farmit : 0;
        const previousQuantity = parseFloat(pinventoryEntries.find(([pItem]) => pItem === item)?.[1] || 0);
        const pquant = previousQuantity;
        const itemQuantity = item === "Flower" ? it["Flower"].quant : quantity;
        const difference = itemQuantity - pquant;
        const absDifference = Math.abs(difference);
        const isNegativeDifference = difference < 0;
        const hoardPercentage = Math.floor((absDifference / maxh) * 100);
        let spotNb = 0;
        let istockorhoard = 0;
        let spotImage = "";
        if (icat === "crop") { spotNb = spot.crop; istockorhoard = istock; spotImage = imgcrop; }
        if (item === "Wood") { spotNb = spot.wood; nft["Foreman Beaver"].isactive === 1 ? istockorhoard = maxh : istockorhoard = istock; spotImage = imgwood; }
        if (item === "Stone") { spotNb = spot.stone; istockorhoard = istock; spotImage = imgstone; }
        if (item === "Iron") { spotNb = spot.iron; istockorhoard = istock; spotImage = imgstone; }
        if (item === "Gold") { spotNb = spot.gold; istockorhoard = istock; spotImage = imgstone; }
        if (item === "Crimstone") { spotNb = spot.crimstone; istockorhoard = istock; spotImage = imgstone; }
        if (item === "Sunstone") { spotNb = spot.sunstone; istockorhoard = istock; spotImage = imgstone; }
        if (item === "Egg") { spotNb = 1; istockorhoard = Math.ceil(farmTime / timmenbr); spotImage = imgchkn; }
        if (item === "Honey") { spotNb = spot.beehive; istockorhoard = istock; spotImage = imgbeehive; }
        if (item === "Flower") { spotNb = spot.flower; istockorhoard = istock; spotImage = imgflowerbed; }
        const bswarm = item === "Honey" && it["Honey"].swarm;
        if (icat === "fruit") { spotNb = spot.fruit; istockorhoard = istock * (4 + buildng["Immortal Pear"].isactive); spotImage = imgwood; }
        const hrvststk = (Math.floor(istockorhoard / spotNb) > 0 ? Math.floor(istockorhoard / spotNb) : 1);
        const hrvststkfrt = (Math.floor(istockorhoard / iplanted) > 0 ? Math.floor(istockorhoard / iplanted) : 1);
        const hrvststkegg = (Math.floor(istockorhoard / spotNb) > 0 ? Math.floor(istockorhoard / spotNb) : 1) / timmenbr;
        const tmstk = hrvststk * timmenbr;
        const tmstkfrt = hrvststkfrt * timmenbr;
        const tmstkegg = hrvststkegg * timmenbr;
        const tmstkx = (icat === "fruit" ? tmstkfrt : item === "Egg" ? tmstkegg : tmstk);
        const BBd = farmTime / tmstkx;
        const BBdmx = farmTime / tmstk;
        const BBprod = (((item === "Wood" && nft["Foreman Beaver"].isactive === 1) || item === "Egg" ? maxh : hrvststk * iharvest));
        const hrvststkx = (icat === "fruit" ? hrvststkfrt : item === "Egg" ? hrvststkegg : hrvststk);
        const hrvstd = (BBd <= MaxBB ? (Math.ceil(hrvststkx * BBd)) : (Math.ceil(hrvststkx * MaxBB))) > 0 ? (BBd <= MaxBB ? (Math.ceil(hrvststkx * BBd)) :
          (Math.ceil(hrvststkx * MaxBB))) : 1;
        const hrvstdmx = (BBdmx <= MaxBB ? (Math.ceil(hrvststk * BBdmx)) : (Math.ceil(hrvststk * MaxBB))) > 0 ? (BBdmx <= MaxBB ? (Math.ceil(hrvststk * BBdmx)) :
          (Math.ceil(hrvststk * MaxBB))) : 1;
        if (!TryChecked) { HrvstMax[item] = hrvstdmx } else { HrvstMaxtry[item] = hrvstdmx }
        if (!TryChecked) { if (!xHrvst[item] || xHrvst[item] > HrvstMax[item]) { xHrvst[item] = HrvstMax[item] } }
        else { if (!xHrvsttry[item] || xHrvsttry[item] > HrvstMaxtry[item]) { xHrvsttry[item] = HrvstMaxtry[item] } }
        const bhrvstItem = !TryChecked ? xHrvst[item] : xHrvsttry[item];
        const dailyprod = bhrvstItem * (item === "Egg" ? iharvestdmax : iharvest);
        const rhdmax = rharvest / rtimmenbr;
        const rhdmaxtry = rharvesttry / timmenbrtry;
        dProd[item] = it[item].farmit ? bhrvstItem * (item === "Egg" ? rhdmax : rharvest) : 0;
        dProdtry[item] = it[item].farmit ? bhrvstItem * (item === "Egg" ? rhdmaxtry : rharvesttry) : 0;
        const hrvstdmaxx = (icat === "fruit" ? !TryChecked ? iharvestdmax : iharvestdmaxtry : item === "Egg" ? !TryChecked ? iharvestdmax : iharvestdmaxtry : iharvest);
        const dailyprodmx = hrvstdmx * hrvstdmaxx;
        if (ifrmit === 1 && icat === "crop") { totTimeCrp += bhrvstItem * timmenbr }
        if (ifrmit === 1 && (icat === "mineral" || icat === "gem" || icat === "wood")) { totTimeRs += tmstk }
        const iburn = xBurning[burnortry][item] ? xBurning[burnortry][item] : 0;
        const iQuant = selectedQuantity === "daily" ? (ifrmit === 1 ? dailyprod : 0) - iburn : selectedQuantity === "blockbuck" ?
          BBprod : selectedQuantity === "custom" ? cstPrices[xIndex] : itemQuantity;
        var Ttax = Math.ceil(iQuant / itradmax) * 0.25;
        const nTTax = 0.1;
        const NTax = 0.05;
        const OTax = 0.1;
        let convPricep = 0;
        let convPriceshp = 0;
        if (selectedCurr === "SFL") {
          convPricep = costp;
          convPriceshp = pShop;
        }
        if (selectedCurr === "MATIC" || selectedCurr === "POL") {
          convPricep = (costp * priceData[2]) / priceData[1];
          convPriceshp = (pShop * priceData[2]) / priceData[1];
        }
        if (selectedCurr === "USDC") {
          convPricep = costp * priceData[2];
          convPriceshp = pShop * priceData[2];
        }
        if (selectedQuant !== "unit") {
          costp = convPricep * (Number(iQuant) + Number(ifrmit === 1 ? iburn : 0));
          if (costp < 0) { costp = 0 }
          pShop = convPriceshp * iQuant;
          if (time !== "" && time !== 0) {
            if (selectedQuantity === "daily") {
              time = convTime(bhrvstItem * timmenbr);
            } else {
              time = convTime(Math.ceil(iQuant / iharvest) * timmenbr);
            }
          }
        }
        else {
          costp = convPricep;
          pShop = convPriceshp;
        }
        if (CostChecked === true && xListeCol[4][1] === 1 && selectedQuant !== "unit") { pShop = pShop - costp; }
        let pTrad = 0;
        let puTrad = 0;
        for (let i = 0; i < priceDataT.length; i++) {
          if (priceDataT[i].id.toString() === ido) {
            let convPrice = 0;
            if (selectedCurr === "SFL") {
              convPrice = priceDataT[i].unit;
              Ttax = Ttax / priceData[2];
            }
            if (selectedCurr === "MATIC" || selectedCurr === "POL") {
              convPrice = (priceDataT[i].unit * priceData[2]) / priceData[1];
              Ttax = Ttax / priceData[1];
            }
            if (selectedCurr === "USDC") {
              convPrice = priceDataT[i].unit * priceData[2];
            }
            puTrad = convPrice;
            if (selectedQuant !== "unit") {
              convPrice *= iQuant;
              convPrice -= (costp * (CostChecked === true && xListeCol[4][1] === 1));
              //convPrice -= Ttax;
              convPrice = convPrice - (convPrice * nTTax);
            }
            pTrad = convPrice;
            break;
          }
        }
        let pNifty = 0;
        let puNifty = 0;
        for (let i = 0; i < priceDataN.length; i++) {
          if (priceDataN[i].id === ido) {
            let convPrice = 0;
            if (selectedCurr === "SFL") {
              convPrice = priceDataN[i].cryptoprice;
            }
            if (selectedCurr === "MATIC" || selectedCurr === "POL") {
              convPrice = (priceDataN[i].cryptoprice * priceData[2]) / priceData[1];
            }
            if (selectedCurr === "USDC") {
              convPrice = priceDataN[i].cryptoprice * priceData[2];
            }
            puNifty = convPrice;
            if (selectedQuant !== "unit") {
              convPrice *= (iQuant * 0.7);
              convPrice -= (costp * (CostChecked === true && xListeCol[4][1] === 1));
              convPrice = convPrice - (convPrice * NTax);
            }
            pNifty = convPrice;
            break;
          }
        }
        let pOS = 0;
        let puOS = 0;
        for (let i = 0; i < priceDataO.length; i++) {
          if (priceDataO[i].id === ido) {
            let convPrice = 0;
            if (selectedCurr === "SFL") {
              convPrice = priceDataO[i].unit / priceData[2];
            }
            if (selectedCurr === "MATIC" || selectedCurr === "POL") {
              convPrice = priceDataO[i].unit / priceData[1];
            }
            if (selectedCurr === "USDC") {
              convPrice = priceDataO[i].unit;
            }
            puOS = convPrice;
            if (selectedQuant !== "unit") {
              convPrice *= (iQuant * 0.7);
              convPrice -= (costp * (CostChecked === true && xListeCol[4][1] === 1));
              convPrice = convPrice - (convPrice * OTax);
            }
            pOS = convPrice;
            break;
          }
        }
        const coefT = parseFloat(pTrad / costp).toFixed(2) !== "Infinity" ? parseFloat(pTrad / costp).toFixed(2) : "";
        const coefN = parseFloat(pNifty / costp).toFixed(2) !== "Infinity" ? parseFloat(pNifty / costp).toFixed(2) : "";
        const coefO = parseFloat(pOS / costp).toFixed(2) !== "Infinity" ? parseFloat(pOS / costp).toFixed(2) : "";
        const colorT = ColorValue(coefT);
        const colorN = ColorValue(coefN);
        const colorO = ColorValue(coefO);
        const prctN = pTrad > 0 ? parseFloat(((pNifty - pTrad) / pTrad) * 100).toFixed(0) : "";
        const prctO = pTrad > 0 ? parseFloat(((pOS - pTrad) / pTrad) * 100).toFixed(0) : "";
        //const BBsfl = (getMaxValue(puTrad, puNifty, puOS)) * BBprod;
        const puNiftyWthdr = puNifty * 0.7;
        const puOSWthdr = puOS * 0.7;
        const xDsfl = selectedDsfl === "max" ? (getMaxValue(puTrad, puNiftyWthdr, puOSWthdr)) :
          selectedDsfl === "trader" ? puTrad : selectedDsfl === "nifty" ? puNiftyWthdr : selectedDsfl === "opensea" ? puOSWthdr : 0;
        const Dsfl = xDsfl * dailyprodmx;
        //const titleTrad = selectedQuant !== "unit" ? Math.ceil(iQuant / itradmax) + " * (" + itradmax + " * " + puTrad + " - 0.25$)" : "";
        const titleTrad = selectedQuant !== 'unit' ? frmtNb(Math.ceil(iQuant / itradmax)) + ` x (${frmtNb(itradmax)} x ${frmtNb(puTrad)}) - ${frmtNb(TaxTradSfl)}SFL(0.25$)` : "";
        const titleNifty = selectedQuant !== "unit" ? frmtNb(iQuant * 0.7) + " x " + frmtNb(puNifty) + " - 5%" : "";
        const titleOS = selectedQuant !== "unit" ? frmtNb(iQuant * 0.7) + " x " + frmtNb(puOS) + " - 10%" : "";
        const maxPltfrm = Math.max(puTrad, puNiftyWthdr, puOSWthdr) === puTrad ? "Trader" : Math.max(puTrad, puNiftyWthdr, puOSWthdr) === puNiftyWthdr ? "Niftyswap" :
          Math.max(puTrad, puNiftyWthdr, puOSWthdr) === puOSWthdr ? "OpenSea" : "";
        const titleDsfl = selectedDsfl === "max" ? `${frmtNb(dailyprodmx)} x ${frmtNb(xDsfl)} at ${maxPltfrm}` : "";
        const cellDSflStyle = {};
        cellDSflStyle.backgroundColor = (selectedDsfl === "max" && Dsfl > 0) ? maxPltfrm === "Trader" ? 'rgba(5, 128, 1, 0.14)' :
          maxPltfrm === "Niftyswap" ? 'rgba(103, 1, 128, 0.14)' : maxPltfrm === "OpenSea" ? 'rgba(0, 75, 236, 0.14)' : '' : '';

        if (selectedQuant !== "unit") {
          const bCost = !isNaN(costp) ? Number(costp) : 0;
          const bShop = !isNaN(pShop) ? Number(pShop) : 0;
          const bTrad = !isNaN(pTrad) ? Number(pTrad) : 0;
          const bNifty = !isNaN(pNifty) ? Number(pNifty) : 0;
          const bOS = !isNaN(pOS) ? Number(pOS) : 0;
          totCost += bCost;
          totShop += bShop;
          totTrader += bTrad;
          totNifty += bNifty;
          totOS += bOS;
        }
        if (selectedQuant !== "unit") {
          const bCost = !isNaN(costp) ? Number(costp) : 0;
          const bShop = !isNaN(pShop) ? Number(pShop) : 0;
          const bTrad = !isNaN(pTrad) ? Number(pTrad) : 0;
          const bNifty = !isNaN(pNifty) ? Number(pNifty) : 0;
          const bOS = !isNaN(pOS) ? Number(pOS) : 0;
          totcCost += bCost;
          totcShop += bShop;
          totcTrader += bTrad;
          totcNifty += bNifty;
          totcOS += bOS;
        }
        return (
          <>
            <tr key={xIndex}>
              {xListeCol[0][1] === 1 ? (<td style={cellStyle}>
                {maxh > 0 && (
                  <div className={`progress-bar ${isNegativeDifference ? 'negative' : ''}`}>
                    <div className="progress" style={{ width: `${hoardPercentage}%` }}>
                      <span className="progress-text">
                        {isNegativeDifference ? `-${parseFloat(absDifference).toFixed(0)}` : `${parseFloat(difference).toFixed(0)}/${parseFloat(maxh).toFixed(0)}`}
                      </span>
                    </div>
                  </div>
                )}
              </td>) : ("")}
              <td id="iccolumn" style={cellStyle}><i><img src={ico} alt={''} className="itico" /></i></td>
              <td style={{ display: 'none' }}>{ido}</td>
              {xListeCol[1][1] === 1 ? (<td className="tditem" style={cellStyle}>{item}</td>) : ("")}
              {selectedQuantity === "daily" ? (<td className="tdcenter" style={cellStyle}><input type="checkbox" checked={ifrmit} onChange={() => handleFarmitChange(item)} /></td>) : ("")}
              {selectedQuantity === "daily" ? (<td className="tdcenter" style={cellStyle}>{hrvstd}</td>) : ("")}
              {selectedQuantity === "daily" ? (<td className="tdcenter" style={cellStyle}>
                <img src="/icon/ui/arrow_up-1.png" alt="Minus" onClick={() => handleDecrement(item, TryChecked)} style={{ width: '11px', height: '11px' }} />
                {bhrvstItem}
                <img src="/icon/ui/arrow_up.png" alt="Plus" onClick={() => handleIncrement(item, TryChecked, hrvstd)} style={{ width: '11px', height: '11px' }} />
              </td>) : ("")}
              {xListeCol[2][1] === 1 ? selectedQuantity === "custom" ?
                (<td className="tdcenter" style={{ ...cellStyle, color: `rgb(160, 160, 160)` }}><div
                  contentEditable
                  suppressContentEditableWarning={true}
                  onBlur={(event) => handleInputcstPricesChange(event, xIndex)}>{cstPrices[xIndex]}</div></td>) :
                (<td className="tdcenter" style={{ ...cellStyle, color: `rgb(160, 160, 160)` }}>{parseFloat(iQuant).toFixed(2)}</td>) : ("")}
              {xListeCol[3][1] === 1 ? (<td className="tdcenter" style={{ ...cellStyle, color: `rgb(200, 200, 200)` }}>{time}</td>) : ("")}
              {xListeCol[4][1] === 1 ? (<td className="tdcenter" style={cellStyle}>{frmtNb(costp)}</td>) : ("")}
              {xListeCol[5][1] === 1 ? (<td className="tdcenter" style={cellStyle}>{frmtNb(pShop)}</td>) : ("")}
              {xListeCol[6][1] === 1 ? (<td className={parseFloat(pTrad).toFixed(20) === getMaxValue(pTrad, pNifty, pOS) ? 'tdcentergreen' : 'tdcenterbrd'}
                onClick={(event) => handleTradeListClick(inputValue, ido, "Trades")} style={cellStyle} title={titleTrad} >{frmtNb(pTrad)}{ximgtrd}</td>) : ("")}
              {xListeCol[17][1] === 1 && xListeCol[6][1] === 1 ? (<td style={{ ...cellStyle, color: colorT, textAlign: 'center', fontSize: '8px' }}>{coefT > 0 ? coefT : ""}</td>) : ("")}
              {xListeCol[7][1] === 1 ? (<td className="tdcenter" style={{ ...cellStyle, color: `rgb(160, 160, 160)` }}>{parseFloat((iQuant) * 0.7).toFixed(2)}</td>) : ("")}
              {xListeCol[8][1] === 1 && xListeCol[14][1] === 1 ? (<td className={prctN > -20 ? 'tdpdiffgrn' : 'tdpdiff'} style={cellStyle}>{prctN}{pTrad > 0 ? "%" : ""}</td>) : ("")}
              {xListeCol[8][1] === 1 ? (<td className={parseFloat(pNifty).toFixed(20) === getMaxValue(pTrad, pNifty, pOS) ? 'tdcentergreen' : 'tdcenterbrd'}
                style={cellStyle} title={titleNifty}>{frmtNb(pNifty)}</td>) : ("")}
              {xListeCol[17][1] === 1 && xListeCol[8][1] === 1 ? (<td style={{ ...cellStyle, color: colorN, textAlign: 'center', fontSize: '8px' }}>{coefN > 0 ? coefN : ""}</td>) : ("")}
              {xListeCol[9][1] === 1 && xListeCol[14][1] === 1 ? (<td className={prctO > -20 ? 'tdpdiffgrn' : 'tdpdiff'} style={cellStyle}>{prctO}{pTrad > 0 ? "%" : ""}</td>) : ("")}
              {xListeCol[9][1] === 1 ? (<td className={parseFloat(pOS).toFixed(20) === getMaxValue(pTrad, pNifty, pOS) ? 'tdcentergreen' : 'tdcenterbrd'}
                onClick={(event) => handleTradeListClick(inputValue, ido, "OS")} style={cellStyle} title={titleOS}>{frmtNb(pOS)}</td>) : ("")}
              {xListeCol[17][1] === 1 && xListeCol[9][1] === 1 ? (<td style={{ ...cellStyle, color: colorO, textAlign: 'center', fontSize: '8px' }}>{coefO > 0 ? coefO : ""}</td>) : ("")}
              {xListeCol[10][1] === 1 ? (<td className="tdcenter" style={{ ...cellStyle, color: `rgb(255, 234, 204)` }}>{parseFloat(imyield).toFixed(2)}</td>) : ("")}
              {xListeCol[11][1] === 1 ? (<td className="tdcenter" style={{ ...cellStyle, color: `rgb(255, 225, 183)` }}>{parseFloat(iharvest).toFixed(2)}</td>) : ("")}
              {xListeCol[12][1] === 1 ? (<td className="tdcenter" style={{ ...cellStyle, color: `rgb(253, 215, 162)` }}>
                <div class="tooltip-container">
                  {i2bharvest > 0 ? parseFloat(i2bharvest).toFixed(2) : ""}{bswarm && imgbee}
                  <div class="tooltip-content" style={{ top: `calc(${index > 1 ? "-100%" : "100%"} + 5px);` }}>
                    <div>
                      {iplanted} <img src={spotImage} alt="" style={{ width: '14px', height: '14px' }} /> <img src={imgflch} alt="" /> {parseFloat(i2bharvest).toFixed(2)} <img src={ico} alt={''} className="itico" />
                    </div></div>
                </div>
              </td>) : ("")}
              {xListeCol[18][1] === 1 ? (<td id={`timer-${xIndex}`} className="tdcenterbrd" style={cellStyle}>{(i2bharvest > 0 || item === "Honey" ? selectedReady === "when" ?
                (<span>{formatdate(irdyat)}{' '}{ximgrdy}</span>) :
                <Timer key={`timer-${xIndex}`} timestamp={irdyat} index={item} onTimerFinish={handleTimerFinish} /> : "")}</td>) : ("")}
              {xListeCol[13][1] === 1 ? (<td className="tdcenter" style={{ ...cellStyle, color: `rgb(160, 160, 160)` }}>{BBprod > 0 ? parseFloat(BBprod).toFixed(2) : ""}</td>) : ("")}
              {xListeCol[15][1] === 1 ? (<td className="tdcenter" style={{ ...cellStyle, ...cellDSflStyle, color: `rgb(255, 204, 132)` }} title={titleDsfl}>{Dsfl > 0 ? parseFloat(Dsfl).toFixed(2) : ""}</td>) : ("")}
              {xListeCol[16][1] === 1 ? (<td className="tdcenter" style={{ ...cellStyle, color: `rgb(160, 160, 160)` }}>{dailyprodmx > 0 ? parseFloat(dailyprodmx).toFixed(2) : ""}</td>) : ("")}
            </tr>
          </>
        );
      }
    });
    const result = {
      inventoryItems: inventoryItems,
      totTimeRs: totTimeRs,
      totTimeCrp: totTimeCrp,
      totCost: totCost,
      totShop: totShop,
      totTrader: totTrader,
      totNifty: totNifty,
      totOS: totOS,
      totcCost: totcCost,
      totcShop: totcShop,
      totcTrader: totcTrader,
      totcNifty: totcNifty,
      totcOS: totcOS,
      invIndex: invIndex
    }
    return result;
  }

  function setCook() {
    if (farmData.inventory) {
      const inventoryEntries = Object.entries(farmData.inventory);
      const foodNames = Object.keys(food);
      const Compo = [];
      Compo["total"] = [];
      const sortedInventoryItems = foodNames.map(item => {
        const quantity = inventoryEntries.find(([entryItem]) => entryItem === item)?.[1] || 0;
        for (let i = 1; i < 5; i++) {
          const compo = food[item][`compo${i}`];
          const quant = food[item][`quant${i}`];
          if (fish[compo]) {
            Compo[item] = Compo[item] || [];
            Compo["total"][compo] = 0;
            Compo[item][compo] = Compo[item][compo] || 0;
            Compo[item][compo] += Number(quant);
          }
        }
        return [item, quantity];
      });
      const farmTime = inputFarmTime / 24;
      var totXP = 0;
      var totCost = 0;
      var totCostp2p = 0;
      var BldTime = [];
      var totTime = 0;
      var totComp = [];
      Object.keys(it).forEach(itemName => {
        if (itemName !== "") { totComp[itemName] = 0 }
      });
      const inventoryItems = sortedInventoryItems.map(([item, quantity], index) => {
        const cobj = food[item];
        const ico = cobj ? cobj.img : '';
        const ibld = cobj ? cobj.bld : '';
        var time = cobj ? !TryChecked ? cobj.time : cobj.timetry : '';
        const timenbr = convtimenbr(time);
        var timecomp = cobj ? !TryChecked ? cobj.timecrp : cobj.timecrptry : '';
        const timecrpnbr = convtimenbr(timecomp);
        const icookit = cobj ? cobj.cookit : 0;
        const iquantd = Math.ceil(farmTime / timenbr) !== Infinity ? Math.ceil(farmTime / timenbr) : 0;
        let prodValues = [];
        for (let i = 1; i < 5; i++) {
          const compo = food[item][`compo${i}`];
          if (it[compo]) {
            const itdprod = dProd[compo] ? dProd[compo] : 0;
            const itdprodtry = dProdtry[compo] ? dProdtry[compo] : 0;
            window[`dprod${i}`] = Math.floor(!TryChecked ? itdprod / food[item][`quant${i}`] : itdprodtry / food[item][`quant${i}`]);
            itDck[compo] = window[`dprod${i}`] * food[item][`quant${i}`];
            prodValues.push(window[`dprod${i}`]);
            //console.log(item + ":" + compo + ":" + window[`dprod${i}`] + " itDck:" + itDck[compo]);
          }
        }
        //const prodValues = selectedQuantityCook === "dailymax" ? iquantd : selectedQuantityCook === "daily" ? [dprod1, dprod2, dprod3, dprod4, dprod5].filter(value => value > 0) : 0;
        var xquantd = selectedQuantityCook === "dailymax" ? iquantd : selectedQuantityCook === "daily" ? Math.min(...prodValues) !== Infinity ? Math.min(...prodValues) : 0 : 0;
        xquantd = selectedQuantityCook === "daily" ? xquantd > iquantd ? iquantd : xquantd : xquantd;
        //!TryChecked ? food[item].dprod = xquantd : food[item].dprodtry = xquantd;
        const iKeep = selectedQuantCook !== "unit" ? inputKeep : 0;
        const iQuant = selectedQuantityCook === "farm" ? (quantity - iKeep > 0 ? quantity - iKeep : 0) : xquantd;
        const ixp = cobj ? selectedQuantCook === "unit" ? (!TryChecked ? cobj.xp : cobj.xptry) : (!TryChecked ? cobj.xp : cobj.xptry) * iQuant : 0;
        const ixph = cobj ? (!TryChecked ? cobj.xph : cobj.xphtry) : 0;
        totXP += (selectedQuantityCook === "daily" || selectedQuantityCook === "dailymax" ? isNaN(ixp) ? 0 : Number(ixp) * food[item].cookit : isNaN(ixp) ? 0 : Number(ixp));
        if (cobj.cookit) {
          if (!BldTime[ibld]) { BldTime[ibld] = 0 }
          BldTime[ibld] += xquantd * timenbr;
        }
        const ixphcomp = cobj ? timecomp !== 0 ? parseFloat(ixp / (timecrpnbr * 24)).toFixed(1) : 0 : 0;
        //const ixpsfl = cobj ? cobj.xpsfl : 0;
        var icost = cobj ? selectedQuantCook === "unit" ? (!TryChecked ? cobj.cost : cobj.costtry) : (!TryChecked ? cobj.cost : cobj.costtry) * iQuant : 0;
        var icostp2p = cobj ? selectedQuantCook === "unit" ?
          selectedCostCook === "shop" ? cobj.costshop : selectedCostCook === "trader" ? cobj.costp2pt : selectedCostCook === "nifty" ? cobj.costp2pn : selectedCostCook === "opensea" ? cobj.costp2po : 0
          : selectedCostCook === "shop" ? cobj.costshop * iQuant : selectedCostCook === "trader" ? cobj.costp2pt * iQuant : selectedCostCook === "nifty" ? cobj.costp2pn * iQuant : selectedCostCook === "opensea" ? cobj.costp2po * iQuant : 0 : 0;
        if (isNaN(icostp2p)) { icostp2p = 0 }
        let convPricep = 0;
        let convPricep2p = 0;
        if (selectedCurr === "SFL") {
          convPricep = icost;
          convPricep2p = icostp2p;
        }
        if (selectedCurr === "MATIC") {
          convPricep = (icost * priceData[2]) / priceData[1];
          convPricep2p = (icostp2p * priceData[2]) / priceData[1];
        }
        if (selectedCurr === "USDC") {
          convPricep = icost * priceData[2];
          convPricep2p = icostp2p * priceData[2];
        }
        icost = convPricep;
        icostp2p = convPricep2p;
        const ixpsfl = icost > 0 ? parseFloat(ixp / icost).toFixed(1) : 0;
        if (selectedQuantCook !== "unit") {
          if (time !== "" && time !== 0) { time = convTime(iQuant * timenbr) }
          if (timecomp !== "" && timecomp !== 0) { timecomp = convTime(iQuant * timecrpnbr) }
        }
        if (((selectedQuantityCook === "daily" || selectedQuantityCook === "dailymax") && cobj.cookit) || selectedQuantityCook === "farm") {
          totCost += icost;
          totCostp2p += icostp2p;
          totComp[food[item].compo1] += food[item].quant1 * (selectedQuantCook === "unit" ? 1 : iQuant);
          totComp[food[item].compo2] += food[item].quant2 * (selectedQuantCook === "unit" ? 1 : iQuant);
          totComp[food[item].compo3] += food[item].quant3 * (selectedQuantCook === "unit" ? 1 : iQuant);
          totComp[food[item].compo4] += food[item].quant4 * (selectedQuantCook === "unit" ? 1 : iQuant);
          totComp[food[item].compo5] += food[item].quant5 * (selectedQuantCook === "unit" ? 1 : iQuant);
          if (fish[food[item].compo1]) { Compo["total"][food[item].compo1] += food[item].quant1 * (selectedQuantCook === "unit" ? 1 : iQuant) }
          if (fish[food[item].compo2]) { Compo["total"][food[item].compo2] += food[item].quant2 * (selectedQuantCook === "unit" ? 1 : iQuant) }
          if (fish[food[item].compo3]) { Compo["total"][food[item].compo3] += food[item].quant3 * (selectedQuantCook === "unit" ? 1 : iQuant) }
          if (fish[food[item].compo4]) { Compo["total"][food[item].compo4] += food[item].quant4 * (selectedQuantCook === "unit" ? 1 : iQuant) }
          if (fish[food[item].compo5]) { Compo["total"][food[item].compo5] += food[item].quant5 * (selectedQuantCook === "unit" ? 1 : iQuant) }
        }
        return (
          <tr key={index}>
            {xListeColCook[0][1] === 1 ? <td className="tdcenter">{ibld}</td> : null}
            <td id="iccolumn"><i><img src={ico} alt={''} className="itico" /></i></td>
            {xListeColCook[1][1] === 1 ? <td className="tditem">{item}</td> : null}
            {selectedQuantityCook === "daily" || selectedQuantityCook === "dailymax" ? <td className="tdcenter"><input type="checkbox" checked={icookit} onChange={() => handleCookitChange(item)} /></td> : null}
            {xListeColCook[2][1] === 1 ? <td className="tdcenter">{iQuant}</td> : null}
            {xListeColCook[3][1] === 1 ? <td className="tdcenter">{parseFloat(ixp).toFixed(1)}</td> : null}
            {xListeColCook[4][1] === 1 ? <td className="tdcenter">{time}</td> : null}
            {xListeColCook[5][1] === 1 ? <td className="tdcenter">{timecomp}</td> : null}
            {xListeColCook[6][1] === 1 ? <td className="tdcenter">{ixph}</td> : null}
            {xListeColCook[7][1] === 1 ? <td className="tdcenter">{ixphcomp}</td> : null}
            {xListeColCook[8][1] === 1 ? <td className="tdcenter">{ixpsfl}</td> : null}
            {xListeColCook[9][1] === 1 ? <td className="tdcenter">{frmtNb(icost)}</td> : null}
            {xListeColCook[10][1] === 1 ? <td className="tdcenter">{frmtNb(icostp2p)}</td> : null}
            {xListeColCook[11][1] === 1 ? Object.keys(it).map((itemName, itIndex) => (
              (it[itemName].cat === "crop" || it[itemName].cat === "animal" || it[itemName].cat === "fruit" || it[itemName].cat === "honey"
                || it[itemName].cat === "mushroom") ? (
                <td className="tdcenterbrd" style={{ fontSize: '12px' }} key={itemName}>
                  {food[item].compo1 === itemName ? food[item].quant1 * (selectedQuantCook === "unit" ? 1 : iQuant) : ""}
                  {food[item].compo2 === itemName ? food[item].quant2 * (selectedQuantCook === "unit" ? 1 : iQuant) : ""}
                  {food[item].compo3 === itemName ? food[item].quant3 * (selectedQuantCook === "unit" ? 1 : iQuant) : ""}
                  {food[item].compo4 === itemName ? food[item].quant4 * (selectedQuantCook === "unit" ? 1 : iQuant) : ""}
                  {food[item].compo5 === itemName ? food[item].quant5 * (selectedQuantCook === "unit" ? 1 : iQuant) : ""}
                </td>
              ) : null
            )) : null}
            {xListeColCook[11][1] === 1 ? Object.keys(Compo["total"]).map((itemName, itIndex) => (
              <td className="tdcenterbrd" style={{ fontSize: '12px' }} key={itemName}>
                {food[item].compo1 === itemName ? food[item].quant1 * (selectedQuantCook === "unit" ? 1 : iQuant) : ""}
                {food[item].compo2 === itemName ? food[item].quant2 * (selectedQuantCook === "unit" ? 1 : iQuant) : ""}
                {food[item].compo3 === itemName ? food[item].quant3 * (selectedQuantCook === "unit" ? 1 : iQuant) : ""}
                {food[item].compo4 === itemName ? food[item].quant4 * (selectedQuantCook === "unit" ? 1 : iQuant) : ""}
                {food[item].compo5 === itemName ? food[item].quant5 * (selectedQuantCook === "unit" ? 1 : iQuant) : ""}
              </td>
            )) : null}
          </tr>
        );
      });
      var maxTime = 0;
      for (var key in BldTime) {
        if (BldTime.hasOwnProperty(key)) {
          var value = BldTime[key];
          if (typeof value === 'number' && !isNaN(value)) {
            if (value > maxTime) {
              maxTime = value;
            }
          }
        }
      }
      totTime = convTime(maxTime);
      const timeOver = maxTime > 1; //farmTime / 24;
      const xinputKeep = selectedQuantCook !== "unit" && selectedQuantityCook === "farm" ? <input type="text" value={inputKeep} onChange={handleInputKeepChange} style={{ width: '11px' }} maxLength={1} /> : "";
      const xinputKeept = selectedQuantCook !== "unit" && selectedQuantityCook === "farm" ? "Keep " : "";
      const xinputFromLvl = selectedQuantCook !== "unit" && selectedQuantityCook !== "farm" ? <input type="text" value={inputFromLvl} onChange={handleFromLvlChange} style={{ width: '20px', marginLeft: 'auto' }} maxLength={2} /> : "";
      const xinputToLvl = selectedQuantCook !== "unit" && selectedQuantityCook !== "farm" ? <input type="text" value={inputToLvl} onChange={handleToLvlChange} style={{ width: '20px', marginLeft: 'auto' }} maxLength={2} /> : "";
      const xinputFromLvlt = selectedQuantCook !== "unit" && selectedQuantityCook !== "farm" ? "From " : "";
      const xinputToLvlt = selectedQuantCook !== "unit" && selectedQuantityCook !== "farm" ? " to " : "";
      const xLvlconft = selectedQuantCook !== "unit" && selectedQuantityCook !== "farm" ? " days, " + fromtolvlxp + "xp" : "";
      const xspace = selectedQuantCook !== "unit" && selectedQuantityCook !== "farm" ? " " : "";
      xdxp = totXP;
      setdailyxp(xdxp);
      const bfdtolvl = !TryChecked ? bumpkinData[0].foodtolvl : bumpkinData[0].foodtolvltry;
      const bfdpstlvl = !TryChecked ? bumpkinData[0].foodxppastlvl : bumpkinData[0].foodxppastlvltry;
      const bxptonxtlvl = !TryChecked ? bumpkinData[0].xptonextlvl : bumpkinData[0].xptonextlvltry;
      //const icolspan = xListeColCook[0][1] === 1 ? 3 : 2;
      const tableContent = (
        <>
          {selectedQuantityCook !== "farm" ?
            <span>{xinputFromLvlt}{xinputFromLvl}{xinputToLvlt}{xinputToLvl}{xspace}{selectedQuantCook !== "unit" ? parseFloat(fromtolvltime).toFixed(1) : ""}{xLvlconft}</span>
            : null}
          <table className="table">
            <thead>
              <tr>
                {xListeColCook[0][1] === 1 ? <th className="thcenter" >Building</th> : null}
                <th className="th-icon">   </th>
                {xListeColCook[1][1] === 1 ? <th className="thcenter" >Food</th> : null}
                {selectedQuantityCook === "daily" || selectedQuantityCook === "dailymax" ? <th className="thcenter" >Cook</th> : null}
                {xListeColCook[2][1] === 1 ? <th className="thcenter" >
                  <div className="selectquantback" style={{ top: `4px` }}><FormControl variant="standard" id="formselectquant" className="selectquant" size="small">
                    <InputLabel>Quant</InputLabel>
                    <Select value={selectedQuantityCook} onChange={handleChangeQuantityCook}>
                      <MenuItem value="farm">Farm</MenuItem>
                      <MenuItem value="daily">Daily/Farm</MenuItem>
                      <MenuItem value="dailymax">Daily/Time</MenuItem>
                    </Select></FormControl></div></th> : null}
                {xListeColCook[3][1] === 1 ? <th className="thcenter"  >
                  <div className="selectquantback" style={{ top: `4px` }}><FormControl variant="standard" id="formselectquant" className="selectquant" size="small">
                    <InputLabel>XP</InputLabel>
                    <Select value={selectedQuantCook} onChange={handleChangeQuantCook}>
                      <MenuItem value="unit">/ Unit</MenuItem>
                      <MenuItem value="quant">x Quantity</MenuItem>
                    </Select></FormControl></div></th> : null}
                {xListeColCook[4][1] === 1 ? <th className="thcenter" >Time</th> : null}
                {xListeColCook[5][1] === 1 ? <th className="thcenter" >Time comp</th> : null}
                {xListeColCook[6][1] === 1 ? <th className="thcenter" >XP/H</th> : null}
                {xListeColCook[7][1] === 1 ? <th className="thcenter" >XP/H comp</th> : null}
                {xListeColCook[8][1] === 1 ? <th className="thcenter" >XP/SFL</th> : null}
                {xListeColCook[9][1] === 1 ? <th className="thcenter" >Cost</th> : null}
                {xListeColCook[10][1] === 1 ? <th className="thcenter" >
                  <div className="selectquantback" style={{ top: `4px` }}><FormControl variant="standard" id="formselectquant" className="selectquant" size="small">
                    <InputLabel>Cost</InputLabel>
                    <Select value={selectedCostCook} onChange={handleChangeCostCook}>
                      <MenuItem value="shop">Shop</MenuItem>
                      <MenuItem value="trader">Trader</MenuItem>
                      <MenuItem value="nifty">Niftyswap</MenuItem>
                      <MenuItem value="opensea">OpenSea</MenuItem>
                    </Select></FormControl></div></th> : null}
                {xListeColCook[11][1] === 1 ? Object.keys(it).map((itemName, itIndex) => (
                  (it[itemName].cat === "crop" || it[itemName].cat === "animal" || it[itemName].cat === "fruit" || it[itemName].cat === "honey"
                    || it[itemName].cat === "mushroom") ? (
                    <td className="tdcenter" key={itemName}><i><img src={it[itemName].img} alt={itemName} className="itico" /></i></td>
                  ) : null
                )) : null}
                {xListeColCook[11][1] === 1 ? Object.keys(Compo["total"]).map((itemName, itIndex) => (
                  <td className="tdcenter" key={itemName}><i><img src={fish[itemName].img} alt={itemName} className="itico" /></i></td>
                )) : null}
              </tr>
              {(selectedQuantCook !== "unit" || selectedQuantityCook !== "farm") ?
                <tr key="total">
                  {xListeColCook[0][1] === 1 ? <td className="tdcenter">Total</td> : null}
                  {xListeColCook[1][1] === 1 ? <td></td> : null}
                  {selectedQuantityCook !== "farm" ? <td className="tdcenter"></td> : null}
                  {/* {xListeColCook[1][1] === 1 && selectedQuantityCook === "farm" ? <td className="tditem"></td> : null} */}
                  {xListeColCook[2][1] === 1 ? <td></td> : null}
                  {selectedQuantityCook !== "farm" ? <td className="tdcenter"></td> : null}
                  {xListeColCook[2][1] === 1 && selectedQuantityCook === "farm" ?
                    <td className="tdcenter" title="Keep for deliveries">{xinputKeept}{xinputKeep}</td> : null}
                  {xListeColCook[3][1] === 1 ? <td className="tdcenter">{selectedQuantCook !== "unit" ? parseFloat(totXP).toFixed(1) : ""}</td> : null}
                  {xListeColCook[4][1] === 1 ? <td className="tdcenter" style={{ color: timeOver && selectedQuantityCook !== "farm" ? "rgb(255, 0, 0)" : "rgb(255, 255, 255)" }}>
                    {selectedQuantityCook !== "farm" ? totTime :
                      <><span>to lvl{bfdtolvl}</span>
                        <div className="progress-bar" style={{ width: "80px" }}>
                          <div className="progress" style={{ width: `${bfdpstlvl / (bfdpstlvl + bxptonxtlvl) * 100}%` }}>
                            <span className="progress-text">
                              {`${parseFloat(bfdpstlvl).toFixed(0)}`}
                            </span>
                          </div>
                        </div></>}</td> : null}
                  {xListeColCook[5][1] === 1 ? <td className="tdcenter"></td> : null}
                  {xListeColCook[6][1] === 1 ? <td className="tdcenter"></td> : null}
                  {xListeColCook[7][1] === 1 ? <td className="tdcenter"></td> : null}
                  {xListeColCook[8][1] === 1 ? <td className="tdcenter"></td> : null}
                  {xListeColCook[9][1] === 1 ? <td className="tdcenter">{selectedQuantCook !== "unit" ? frmtNb(totCost) : ""}</td> : null}
                  {xListeColCook[10][1] === 1 ? <td className="tdcenter">{selectedQuantCook !== "unit" ? frmtNb(totCostp2p) : ""}</td> : null}
                  {xListeColCook[11][1] === 1 ? Object.keys(it).map((itemName, itIndex) => (
                    (it[itemName].cat === "crop" || it[itemName].cat === "animal" || it[itemName].cat === "fruit" || it[itemName].cat === "honey"
                      || it[itemName].cat === "mushroom") ? (
                      <td className="tdcenterbrd" style={{ fontSize: '12px', color: totComp[itemName] > (!TryChecked ? dProd[itemName] : dProdtry[itemName]) && selectedQuantityCook !== "farm" ? "rgb(255, 0, 0)" : "rgb(255, 255, 255)" }} key={itemName}>
                        {selectedQuantCook !== "unit" && totComp[itemName] > 0 ? totComp[itemName] : ""}
                      </td>
                    ) : null
                  )) : null}
                  {xListeColCook[11][1] === 1 ? Object.keys(Compo["total"]).map((itemName, itIndex) => (
                    <td className="tdcenterbrd" style={{ fontSize: '12px' }} key={itemName}>
                      {selectedQuantCook !== "unit" && Compo["total"][itemName] > 0 ? Compo["total"][itemName] : ""}
                    </td>
                  )) : null}
                </tr> : null}
            </thead>
            <tbody>
              {inventoryItems}
            </tbody>
          </table>
        </>
      );
      setcookData(tableContent);
      setMutants(mutantchickens);
      setsTickets(sTickets);
    }
  }

  function setFish() {
    if (farmData.inventory) {
      var totXPfsh = 0;
      var totCaught = 0;
      var totCost = 0;
      const inventoryEntries = Object.entries(farmData.inventory);
      var pinventoryEntries = "";
      if (farmData.previousInventory) { pinventoryEntries = Object.entries(farmData.previousInventory) }
      const fishNames = Object.keys(fish);
      const sortedInventoryItems = fishNames.map(item => {
        const quantity = inventoryEntries.find(([entryItem]) => entryItem === item)?.[1] || 0;
        return [item, quantity];
      });
      const earthwormbait = <i><img src={fish["Earthworm"].img} alt={''} className="itico" title="Earthworm" /></i>
      const grubbait = <i><img src={fish["Grub"].img} alt={''} className="itico" title="Grub" /></i>
      const redwigglerbait = <i><img src={fish["Red Wiggler"].img} alt={''} className="itico" title="Red Wiggler" /></i>
      const earthwormquant = fish["Earthworm"].quant;
      const grubquant = fish["Grub"].quant;
      const redwigglerquant = fish["Red Wiggler"].quant;
      const inventoryItems = sortedInventoryItems.map(([item, quantity], index) => {
        const cobj = fish[item];
        const ico = cobj ? cobj.img : '';
        const icat = cobj ? cobj.cat : '';
        const ibait = cobj ? cobj.bait : '';
        const ilocat = cobj ? cobj.locations : '';
        const xBaits = ibait.split("/");
        const icaught = cobj ? cobj.caught : '';
        const previousQuantity = parseFloat(pinventoryEntries.find(([pItem]) => pItem === item)?.[1] || 0);
        const pquant = previousQuantity;
        const itemQuantity = quantity;
        const difference = itemQuantity - pquant;
        const absDifference = Math.abs(difference);
        const isNegativeDifference = difference < 0;
        const maxh = 100;
        const hoardPercentage = Math.floor((absDifference / maxh) * 100);
        const ichum = cobj ? cobj.chum : '';
        const ichumimgs = cobj ? cobj.chumimgs : '';
        const xChums = ichum.split("*");
        const xChumsImg = ichumimgs.split("*");
        const iperiod = cobj ? cobj.weather : '';
        var icost = cobj ? !TryChecked ? cobj.cost : cobj.costtry : '';
        const iQuant = quantity;
        const ixp = cobj ? selectedQuantFish === "unit" ? (!TryChecked ? cobj.xp : cobj.xptry) : parseFloat((!TryChecked ? cobj.xp : cobj.xptry) * iQuant).toFixed(1) : 0;
        totXPfsh += isNaN(ixp) ? 0 : Number(ixp);
        totCaught += icaught;
        const iprct = cobj ? parseFloat(cobj.prct).toFixed(1) : '';
        let convPricep = 0;
        if (selectedCurr === "SFL") {
          convPricep = icost;
        }
        if (selectedCurr === "MATIC") {
          convPricep = (icost * priceData[2]) / priceData[1];
        }
        if (selectedCurr === "USDC") {
          convPricep = icost * priceData[2];
        }
        icost = isNaN(convPricep) ? 0 : Number(convPricep);
        totCost += icost * iQuant;
        const xCost = selectedQuantFish === "unit" ? icost : icost * iQuant;
        const ixpsfl = isNaN(ixp / xCost) ? "" : ixp / xCost;
        if (icat !== "Bait") {
          return (
            <tr key={index}>
              {xListeColFish[0][1] === 1 ? <td className="tdcenter">{icat}</td> : null}
              {xListeColFish[1][1] === 1 ? <td className="tdcenter">{ilocat}</td> : null}
              {xListeColFish[2][1] === 1 ? (<td>
                {maxh > 0 && (
                  <div className={`progress-bar ${isNegativeDifference ? 'negative' : ''}`}>
                    <div className="progress" style={{ width: `${hoardPercentage}%` }}>
                      <span className="progress-text">
                        {isNegativeDifference ? `-${parseFloat(absDifference).toFixed(0)}` : `${parseFloat(difference).toFixed(0)}/${parseFloat(maxh).toFixed(0)}`}
                      </span>
                    </div>
                  </div>
                )}
              </td>) : ("")}
              <td id="iccolumn"><i><img src={ico} alt={''} className="itico" /></i></td>
              {xListeColFish[3][1] === 1 ? <td className="tditem">{item}</td> : null}
              {xListeColFish[4][1] === 1 ? <td className="tdcenter">
                {xBaits.map((value, index) => (
                  value !== "" ? (<span key={index}>
                    <i><img src={fish[value].img} alt={''} className="itico" title={value} /></i>
                  </span>) : ("")
                ))}</td> : null}
              {xListeColFish[5][1] === 1 ? <td className="tdcenter">{iQuant}</td> : null}
              {xListeColFish[6][1] === 1 ? <td className="tdcenter">{icaught}</td> : null}
              {xListeColFish[7][1] === 1 ? <td className="tdcenter">
                {xChums.map((value, index) => {
                  if (value !== "") { return (<span key={index}><i><img src={xChumsImg[index]} alt={''} className="itico" title={value} /></i></span>) }
                  return null;
                })}</td> : null}
              {xListeColFish[8][1] === 1 ? <td className="tdcenter">{iperiod}</td> : null}
              {xListeColFish[9][1] === 1 ? <td className="tdcenter">{iprct}</td> : null}
              {xListeColFish[10][1] === 1 ? <td className="tdcenter">{isNaN(ixp) ? "" : ixp}</td> : null}
              {xListeColFish[11][1] === 1 ? <td className="tdcenter">{parseFloat(xCost).toFixed(3)}</td> : null}
              {xListeColFish[12][1] === 1 ? <td className="tdcenter">{isNaN(parseFloat(ixpsfl).toFixed(1)) ? "" : parseFloat(ixpsfl).toFixed(1)}</td> : null}
            </tr>
          );
        }
      });
      const tableContent = (
        <>
          <table className="table">
            <thead>
              <tr>
                {xListeColFish[0][1] === 1 ? <th className="thcenter" >Category</th> : null}
                {xListeColFish[1][1] === 1 ? <th className="thcenter" >Location</th> : null}
                {xListeColFish[2][1] === 1 ? <th className="thcenter" >Hoard</th> : null}
                <th className="th-icon">   </th>
                {xListeColFish[3][1] === 1 ? <th className="thcenter" >Fish</th> : null}
                {xListeColFish[4][1] === 1 ? <th className="thcenter" >Bait</th> : null}
                {xListeColFish[5][1] === 1 ? <th className="thcenter" >Quantity</th> : null}
                {xListeColFish[6][1] === 1 ? <th className="thcenter" >Caught</th> : null}
                {xListeColFish[7][1] === 1 ? <th className="thcenter" >Chum</th> : null}
                {xListeColFish[8][1] === 1 ? <th className="thcenter" >Period</th> : null}
                {xListeColFish[9][1] === 1 ? <th className="thcenter" > % </th> : null}
                {xListeColFish[10][1] === 1 ? <th className="thcenter" >
                  <div className="selectquantback" style={{ top: `4px` }}><FormControl variant="standard" id="formselectquant" className="selectquant" size="small">
                    <InputLabel>XP</InputLabel>
                    <Select value={selectedQuantFish} onChange={handleChangeQuantFish}>
                      <MenuItem value="unit">/ Unit</MenuItem>
                      <MenuItem value="quant">x Quantity</MenuItem>
                    </Select></FormControl></div></th> : null}
                {xListeColFish[11][1] === 1 ? <th className="thcenter" >Cost</th> : null}
                {xListeColFish[12][1] === 1 ? <th className="thcenter" >XP/SFL</th> : null}
              </tr>
              <tr key="total">
                {xListeColFish[0][1] === 1 ? <td className="tdcenter">Total</td> : null}
                {xListeColFish[1][1] === 1 ? <td className="tdcenter"></td> : null}
                {xListeColFish[2][1] === 1 ? <td className="tditem"></td> : null}
                <td></td>
                {xListeColFish[3][1] === 1 ? <td className="tditem"></td> : null}
                {xListeColFish[4][1] === 1 ? <td className="tdcenter">{earthwormquant}{earthwormbait}{grubquant}{grubbait}{redwigglerquant}{redwigglerbait}</td> : null}
                {xListeColFish[5][1] === 1 ? <td className="tdcenter"></td> : null}
                {xListeColFish[6][1] === 1 ? <td className="tdcenter">{totCaught}</td> : null}
                {xListeColFish[7][1] === 1 ? <td className="tdcenter"></td> : null}
                {xListeColFish[8][1] === 1 ? <td className="tdcenter"></td> : null}
                {xListeColFish[9][1] === 1 ? <td className="tdcenter"></td> : null}
                {xListeColFish[10][1] === 1 ? <td className="tdcenter">{(selectedQuantFish !== "unit") ? parseFloat(totXPfsh).toFixed(1) : ""}</td> : null}
                {xListeColFish[11][1] === 1 ? <td className="tdcenter">{(selectedQuantFish !== "unit") ? parseFloat(totCost).toFixed(1) : ""}</td> : null}
                {xListeColFish[12][1] === 1 ? <td className="tdcenter"></td> : null}
              </tr>
            </thead>
            <tbody>
              {inventoryItems}
            </tbody>
          </table>
        </>
      );
      setfishData(tableContent);
      setMutants(mutantchickens);
      setsTickets(sTickets);
    }
  }

  function setExpand() {
    if (farmData.inventory) {
      //const expEntries = Object.entries(expand);
      //const expKeys = Object.keys(expand);
      const expKeys = Object.keys(fromtoexpand.expandData);
      var i = 0;
      const imgcropnode = <img src={imgcrop} alt={''} className="nodico" title="Crop Node" />;
      const imgfruitnode = <img src="./icon/res/apple_tree.png" alt={''} className="nodico" title="Fruit Patch" />;
      const imgwoodnode = <img src={imgwood} alt={''} className="nodico" title="Tree" />;
      const imgstonenode = <img src={imgstone} alt={''} className="nodico" title="Stone Node" />;
      const imgironnode = <img src="./icon/res/iron_small.png" alt={''} className="nodico" title="Iron Node" />;
      const imggoldnode = <img src="./icon/res/gold_small.png" alt={''} className="nodico" title="Gold Node" />;
      const imgcrimstonenode = <img src="./icon/res/crimstone_rock_5.webp" alt={''} className="nodico" title="Crimstone Node" />;
      const imgbeehivenode = <img src="./icon/res/beehive.webp" alt={''} className="itico" title="Beehive" />;
      const imgwoodres = <img src="./icon/res/wood.png" alt={''} className="itico" title="Wood" />;
      const imgstoneres = <img src="./icon/res/stone.png" alt={''} className="itico" title="Stone" />;
      const imgironres = <img src="./icon/res/iron_ore.png" alt={''} className="itico" title="Iron" />;
      const imggoldres = <img src="./icon/res/gold_ore.png" alt={''} className="itico" title="Gold" />;
      const imgcrimstoneres = <img src="./icon/res/crimstone.png" alt={''} className="itico" title="Crimstone" />;
      const imgbbres = <img src="./icon/ui/block_buck.png" alt={''} className="itico" title="Block Buck" />;
      const itotTime = fromtoexpand.expand.totalTime / (60 * 60 * 24);
      const totTime = convTime(itotTime);
      const imglvl = './icon/ui/confirm.png';
      const tableContent = expKeys.map(([element]) => {
        i++;
        //const indx = Number(element);
        //const cobj = expand[i];
        const cobj = fromtoexpand.expandData[i];
        const resBB = cobj.resources ? cobj.resources["Block Buck"] : "";
        const resWood = cobj.resources ? cobj.resources.Wood || "" : "";
        const resStone = cobj.resources ? cobj.resources.Stone || "" : "";
        const resIron = cobj.resources ? cobj.resources.Iron || "" : "";
        const resGold = cobj.resources ? cobj.resources.Gold || "" : "";
        const resCrimstone = cobj.resources ? cobj.resources.Crimstone ? cobj.resources.Crimstone || "" : "" : "";
        const nodCrop = cobj.nodes ? cobj.nodes.Crop || "" : "";
        const nodFruit = cobj.nodes ? cobj.nodes.Fruit || "" : "";
        const nodTree = cobj.nodes ? cobj.nodes.Wood || "" : "";
        const nodStone = cobj.nodes ? cobj.nodes.Stone || "" : "";
        const nodIron = cobj.nodes ? cobj.nodes.Iron || "" : "";
        const nodGold = cobj.nodes ? cobj.nodes.Gold || "" : "";
        const nodCrimstone = cobj.nodes ? cobj.nodes.Crimstone ? cobj.nodes.Crimstone || "" : "" : "";
        const nodBeehive = cobj.nodes ? cobj.nodes.Beehive ? cobj.nodes.Beehive || "" : "" : "";
        const itime = cobj.seconds ? cobj.seconds / (60 * 60 * 24) : 0;
        const time = convTime(itime);
        const level = cobj.bumpkinLevel || "";
        const imglvlfarm = xexpandData.lvlexpand === i ? <img src={imglvl} alt={''} className="itico" title="Your lvl" /> : "";
        const indexrow = i;
        return (
          <tr key={indexrow}>
            {xListeColExpand[0][1] === 1 ? <td className="tdcenter">{i}</td> : null}
            {xListeColExpand[1][1] === 1 ? <td className="tdcenter">{level}</td> : null}
            <td className="tdcenter">{imglvlfarm}</td>
            {xListeColExpand[2][1] === 1 ? <td className="tdcenter">
              <input type="radio" name="fromOption" className="round-checkbox" checked={fromexpand === indexrow} onChange={() => handleFromExpandChange(indexrow)} /></td> : null}
            {xListeColExpand[2][1] === 1 ? <td className="tdcenter">
              <input type="radio" name="toOption" className="round-checkbox" checked={toexpand === indexrow} onChange={() => handleToExpandChange(indexrow)} /></td> : null}
            {xListeColExpand[3][1] === 1 ? <td className="tdcenter">{nodCrop}</td> : null}
            {xListeColExpand[3][1] === 1 ? <td className="tdcenter">{nodFruit}</td> : null}
            {xListeColExpand[3][1] === 1 ? <td className="tdcenter">{nodTree}</td> : null}
            {xListeColExpand[3][1] === 1 ? <td className="tdcenter">{nodStone}</td> : null}
            {xListeColExpand[3][1] === 1 ? <td className="tdcenter">{nodIron}</td> : null}
            {xListeColExpand[3][1] === 1 ? <td className="tdcenter">{nodGold}</td> : null}
            {xListeColExpand[3][1] === 1 ? <td className="tdcenter">{nodCrimstone}</td> : null}
            {xListeColExpand[3][1] === 1 ? <td className="tdcenter">{nodBeehive}</td> : null}
            {xListeColExpand[3][1] === 1 ? <td className="tdcenter">{time}</td> : null}
            {xListeColExpand[4][1] === 1 ? <td className="tdcenter">{resWood}</td> : null}
            {xListeColExpand[4][1] === 1 ? <td className="tdcenter">{resStone}</td> : null}
            {xListeColExpand[4][1] === 1 ? <td className="tdcenter">{resIron}</td> : null}
            {xListeColExpand[4][1] === 1 ? <td className="tdcenter">{resGold}</td> : null}
            {xListeColExpand[4][1] === 1 ? <td className="tdcenter">{resCrimstone}</td> : null}
            {xListeColExpand[4][1] === 1 ? <td className="tdcenter">{resBB}</td> : null}
          </tr>
        );
      });
      const tableHeader = (
        <thead>
          <tr>
            {xListeColExpand[0][1] === 1 ? <th className="th-icon">
              <div className="selectquantback" style={{ top: `4px` }}><FormControl variant="standard" id="formselectquant" className="selectquant" size="small">
                <InputLabel>LVL</InputLabel>
                <Select value={selectedExpandType} onChange={handleChangeExpandType}>
                  <MenuItem value="basic">Basic</MenuItem>
                  <MenuItem value="spring">Spring</MenuItem>
                </Select></FormControl></div></th> : null}
            {xListeColExpand[1][1] === 1 ? <th className="thcenter">Bumpkin</th> : null}
            <th className="tdcenter">Farm</th>
            {xListeColExpand[2][1] === 1 ? <th className="thcenter">From</th> : null}
            {xListeColExpand[2][1] === 1 ? <th className="thcenter">To</th> : null}
            {xListeColExpand[3][1] === 1 ? <th className="thcenter">{imgcropnode}</th> : null}
            {xListeColExpand[3][1] === 1 ? <th className="thcenter">{imgfruitnode}</th> : null}
            {xListeColExpand[3][1] === 1 ? <th className="thcenter">{imgwoodnode}</th> : null}
            {xListeColExpand[3][1] === 1 ? <th className="thcenter">{imgstonenode}</th> : null}
            {xListeColExpand[3][1] === 1 ? <th className="thcenter">{imgironnode}</th> : null}
            {xListeColExpand[3][1] === 1 ? <th className="thcenter">{imggoldnode}</th> : null}
            {xListeColExpand[3][1] === 1 ? <th className="thcenter">{imgcrimstonenode}</th> : null}
            {xListeColExpand[3][1] === 1 ? <th className="thcenter">{imgbeehivenode}</th> : null}
            {xListeColExpand[3][1] === 1 ? <th className="thcenter">Time</th> : null}
            {xListeColExpand[4][1] === 1 ? <th className="thcenter">{imgwoodres}</th> : null}
            {xListeColExpand[4][1] === 1 ? <th className="thcenter">{imgstoneres}</th> : null}
            {xListeColExpand[4][1] === 1 ? <th className="thcenter">{imgironres}</th> : null}
            {xListeColExpand[4][1] === 1 ? <th className="thcenter">{imggoldres}</th> : null}
            {xListeColExpand[4][1] === 1 ? <th className="thcenter">{imgcrimstoneres}</th> : null}
            {xListeColExpand[4][1] === 1 ? <th className="thcenter">{imgbbres}</th> : null}
          </tr>
          <tr>
            {xListeColExpand[0][1] === 1 ? <td className="tdcenter">TOTAL</td> : null}
            {xListeColExpand[1][1] === 1 ? <td className="tdcenter"></td> : null}
            <td className="tdcenter"></td>
            {xListeColExpand[2][1] === 1 ? <td className="tdcenter"></td> : null}
            {xListeColExpand[2][1] === 1 ? <td className="tdcenter"></td> : null}
            {xListeColExpand[3][1] === 1 ? <td className="tdcenter">{fromtoexpand.expand.totalNodes.Crop}</td> : null}
            {xListeColExpand[3][1] === 1 ? <td className="tdcenter">{fromtoexpand.expand.totalNodes.Fruit}</td> : null}
            {xListeColExpand[3][1] === 1 ? <td className="tdcenter">{fromtoexpand.expand.totalNodes.Wood}</td> : null}
            {xListeColExpand[3][1] === 1 ? <td className="tdcenter">{fromtoexpand.expand.totalNodes.Stone}</td> : null}
            {xListeColExpand[3][1] === 1 ? <td className="tdcenter">{fromtoexpand.expand.totalNodes.Iron}</td> : null}
            {xListeColExpand[3][1] === 1 ? <td className="tdcenter">{fromtoexpand.expand.totalNodes.Gold}</td> : null}
            {xListeColExpand[3][1] === 1 ? <td className="tdcenter">{fromtoexpand.expand.totalNodes.Crimstone}</td> : null}
            {xListeColExpand[3][1] === 1 ? <td className="tdcenter">{fromtoexpand.expand.totalNodes.Beehive}</td> : null}
            {xListeColExpand[3][1] === 1 ? <td className="tdcenter">{totTime}</td> : null}
            {xListeColExpand[4][1] === 1 ? <td className="tdcenter">{fromtoexpand.expand.totalResources.Wood}</td> : null}
            {xListeColExpand[4][1] === 1 ? <td className="tdcenter">{fromtoexpand.expand.totalResources.Stone}</td> : null}
            {xListeColExpand[4][1] === 1 ? <td className="tdcenter">{fromtoexpand.expand.totalResources.Iron}</td> : null}
            {xListeColExpand[4][1] === 1 ? <td className="tdcenter">{fromtoexpand.expand.totalResources.Gold}</td> : null}
            {xListeColExpand[4][1] === 1 ? <td className="tdcenter">{fromtoexpand.expand.totalResources.Crimstone}</td> : null}
            {xListeColExpand[4][1] === 1 ? <td className="tdcenter">{fromtoexpand.expand.totalResources["Block Buck"]}</td> : null}
          </tr>
        </thead>
      );

      const table = (
        <>
          <table className="table">
            {tableHeader}
            <tbody>
              {tableContent}
            </tbody>
          </table>
        </>
      );

      setexpandData(table);
      setMutants(mutantchickens);
      setsTickets(sTickets);
    }
  }

  function setFlower() {
    if (farmData.inventory) {
      const flwrKeys = Object.keys(flower);
      const tableContent = flwrKeys.map(element => {
        const cobj = flower[element];
        const flName = element;
        const ico = <img src={cobj.img} alt={''} className="nodico" title={flName} />;
        const seed = cobj.cat && cobj.cat;
        const ibreed = cobj ? cobj.breed : '';
        const ibreedimgs = cobj ? cobj.breedimgs : '';
        const xBreeds = ibreed.split("*");
        const xBreedsImg = ibreedimgs.split("*");
        const iquant = cobj.quant > 0 ? cobj.quant : '';
        const ihrvstd = cobj.harvested > 0 ? cobj.harvested : '';
        return (
          <tr>
            {xListeColFlower[0][1] === 1 ? <td className="tdcenter">{seed}</td> : null}
            <td id="iccolumn">{ico}</td>
            {xListeColFlower[1][1] === 1 ? <td className="tditem">{flName}</td> : null}
            {xListeColFlower[2][1] === 1 ? <td className="tdcenter">
              {xBreeds.map((value, index) => {
                if (value !== "") { return (<span key={index}><i><img src={xBreedsImg[index]} alt={''} className="itico" title={value} /></i></span>) }
                return null;
              })}</td> : null}
            {xListeColFlower[3][1] === 1 ? <td className="tdcenter">{iquant}</td> : null}
            {xListeColFlower[4][1] === 1 ? <td className="tdcenter">{ihrvstd}</td> : null}
          </tr>
        );
      });
      const tableHeader = (
        <thead>
          <tr>
            {xListeColFlower[0][1] === 1 ? <th className="thcenter">Seed</th> : null}
            <th className="th-icon"></th>
            {xListeColFlower[1][1] === 1 ? <th className="thcenter">Name</th> : null}
            {xListeColFlower[2][1] === 1 ? <th className="thcenter">Breed</th> : null}
            {xListeColFlower[3][1] === 1 ? <th className="thcenter">Quant</th> : null}
            {xListeColFlower[4][1] === 1 ? <th className="thcenter">Hrvst</th> : null}
          </tr>
          <tr>
            {xListeColFlower[0][1] === 1 ? <td className="tdcenter">TOTAL</td> : null}
            <td></td>
            {xListeColFlower[1][1] === 1 ? <td className="tdcenter"></td> : null}
            {xListeColFlower[2][1] === 1 ? <td className="tdcenter"></td> : null}
            {xListeColFlower[3][1] === 1 ? <td className="tdcenter"></td> : null}
            {xListeColFlower[4][1] === 1 ? <td className="tdcenter"></td> : null}
          </tr>
        </thead>
      );

      const table = (
        <>
          <table className="table">
            {tableHeader}
            <tbody>
              {tableContent}
            </tbody>
          </table>
        </>
      );

      setflowerData(table);
      setMutants(mutantchickens);
      setsTickets(sTickets);
    }
  }

  function setActivityDay() {
    if (activityData[0]) {
      const actKeys = Object.keys(activityData);
      var totXP = 0;
      var tottktdchest = 0;
      var tottktcrop = 0;
      var tottktbert = 0;
      var tottktwactv = 0;
      var tottkttntcl = 0;
      var totdeliveriestkt = 0;
      var totchorestkt = 0;
      var totmaxtkt = 0;
      var totdeliveriescost = 0;
      var totdeliveriescostp2pt = 0;
      var totdeliveriessfl = 0;
      const ximgxp = <i><img src={imgxp} alt='' className="resico" title="XP" style={{ width: `20px`, height: `20px` }} /></i>;
      const ximgtkt = <i><img src={imgtkt} alt='' className="itico" title="Tickets" /></i>;
      const ximgdchest = <i><img src="./icon/ui/synced.gif" alt='' className="itico" title="Tickets from daily chest" style={{ width: `20px`, height: `20px` }} /></i>;
      const ximgcrop = <i><img src={imgcrop} alt='' className="resico" title="Tickets from crops" style={{ width: `20px`, height: `20px` }} /></i>;
      const ximgbert = <i><img src="./icon/pnj/bert.png" alt='' className="itico" title="Tickets from Bert obsession" /></i>;
      const ximgwactv = <i><img src={it["Flower"].img} alt='' className="itico" title="Tickets from Weekly activity" /></i>;
      //const ximgtntcl = <i><img src="./icon/fish/tentacle.png" alt='' className="itico" title="Tickets from tentacles" /></i>;
      const imgdeliv = <i><img src="./icon/ui/delivery_board.png" alt='' className="resico" title="Deliveries" /></i>;
      const imgchore = <i><img src="./icon/ui/expression_chat.png" alt='' className="resico" title="Chores" style={{ width: `20px`, height: `20px` }} /></i>;
      //const imgmaxtkt = <i><img src={imgtkt} alt='' className="resico" title="Tickets max by day" /></i>;
      let i = 0;
      const sfs = new Date() - dateSeason;
      const dfs = Math.floor(sfs / (1000 * 60 * 60 * 24));
      const tableContent = actKeys.map(([element]) => {
        const idData = i;
        i++;
        const endDate = new Date(activityData[idData].date);
        const isSeasonDay = endDate >= dateSeason;
        const curw = ((endDate.getDate()) / 8);        
        const isweeklyactday = Number.isInteger(curw) || (endDate.getDate() === dateSeason.getDate() && endDate.getMonth() === dateSeason.getMonth());
        const curD = endDate.getDay() === resetDay;
        const wactdone = isweeklyactday && wklactivity[Math.floor(curw) + 1];
        if (isSeasonDay) {
          const ActTot = setActivityTot(activityData[idData], "day");
          //const allSortedItems = ActTot.allSortedItems;
          //const compoHarvested = ActTot.compoHarvested;
          //const compoHarvestn = ActTot.compoHarvestn;
          //const compoBurn = ActTot.compoBurn;
          const tot = ActTot.tot;
          //const cobj = activityData[idData].data;
          const sday = String(endDate.getDate()).padStart(2, '0');
          const smonth = String(endDate.getMonth() + 1).padStart(2, '0');
          const syear = String(endDate.getFullYear()).slice(-2);
          const sxdate = `${smonth}/${sday}/${syear}`;
          const idate = sxdate
          const itotxp = tot.XP;
          const itktdchest = tot.tktchest;
          const itktcrop = tot.tktcrop;
          const itktbert = tot.tktbert;
          const itktwactv = isweeklyactday ? wactdone ? tktWeekly : 0 : 0;
          //const itktbertMax = tot.tktbertMax;
          //const itkttntcl = cobj.tickettentacle ? cobj.tickettentacle : 0; //compoHarvested["Kraken Tenacle"] * 12;
          const itkttntcl = 0; //compoHarvested["Kraken Tentacle"] ? compoHarvested["Kraken Tentacle"] * 12 : 0;
          const ideliveriestkt = tot.deliveriestkt;
          const ichorestkt = tot.chorestkt;
          const itktmax = tot.tktMax;
          const ideliveriescost = tot.deliveriescost;
          const ideliveriescostp2pt = tot.deliveriescostp2pt;
          const itktcost = tot.tktCost;
          const ideliveriessfl = tot.deliveriessfl;
          const ichoresdelivtkt = Number(ideliveriestkt) + Number(ichorestkt) + Number(itktdchest) + Number(itktbert) + Number(itktwactv);
          totXP += itotxp;
          tottktdchest += itktdchest;
          tottktcrop += itktcrop;
          tottktbert += itktbert;
          tottktwactv += itktwactv;
          tottkttntcl += Number(itkttntcl);
          totdeliveriestkt += ideliveriestkt;
          totchorestkt += ichorestkt;
          totdeliveriescost += ideliveriescost;
          totdeliveriescostp2pt += ideliveriescostp2pt;
          totdeliveriessfl += ideliveriessfl;
          totmaxtkt += itktmax;
          return (
            <tr>
              {xListeColActivity[0][1] === 1 ? <td className="tdcenter" id="iccolumn">{idate}</td> : null}
              {xListeColActivity[1][1] === 1 ? <td className="tdcenter">{parseFloat(itotxp).toFixed(1)}</td> : null}
              {xListeColActivity[2][1] === 1 ? <td className="tdcenter">{itktdchest > 0 ? itktdchest : ""}</td> : null}
              {xListeColActivity[3][1] === 1 ? <td className="tdcenter">{itktcrop > 0 ? itktcrop : ""}</td> : null}
              {xListeColActivity[4][1] === 1 ? <td className="tdcenter">{itktbert > 0 ? itktbert : ""}</td> : null}
              {xListeColActivity[5][1] === 1 ? <td className="tdcenter">{itktwactv > 0 ? itktwactv : ""}</td> : null}
              {xListeColActivity[6][1] === 1 ? <td className="tdcenter">{ideliveriestkt > 0 ? ideliveriestkt : ""}</td> : null}
              {xListeColActivity[7][1] === 1 ? <td className="tdcenter">{ichorestkt > 0 ? ichorestkt : ""}</td> : null}
              {xListeColActivity[8][1] === 1 ? <td className="tdcenter">{ichoresdelivtkt}/{itktmax}</td> : null}
              {xListeColActivity[9][1] === 1 ? <td className="tdcenter">{ideliveriescost > 0 ? frmtNb(ideliveriescost) : ""}</td> : null}
              {xListeColActivity[10][1] === 1 ? <td className="tdcenter">{ideliveriescostp2pt > 0 ? frmtNb(ideliveriescostp2pt) : ""}</td> : null}
              {xListeColActivity[11][1] === 1 ? <td className="tdcenter">{itktcost > 0 ? frmtNb(itktcost) : ""}</td> : null}
              {xListeColActivity[12][1] === 1 ? <td className="tdcenter">{ideliveriessfl > 0 ? frmtNb(ideliveriessfl) : ""}</td> : null}
            </tr>
          );
        }

      });
      const tableHeader = (
        <thead>
          <tr>
            {xListeColActivity[0][1] === 1 ? <th className="th-icon">
              <div className="selectquantback" style={{ top: `4px` }}><FormControl variant="standard" id="formselectquant" className="selectquant" size="small">
                <InputLabel>From</InputLabel>
                <Select value={selectedFromActivityDay} onChange={handleChangeFromActivityDay}>
                  <MenuItem value="7">7 days</MenuItem>
                  <MenuItem value="31">1 month</MenuItem>
                  <MenuItem value="season">season</MenuItem>
                </Select></FormControl></div></th> : null}
            {xListeColActivity[1][1] === 1 ? <th className="thcenter">{ximgxp}</th> : null}
            {xListeColActivity[2][1] === 1 ? <th className="thcenter">{ximgdchest}</th> : null}
            {xListeColActivity[3][1] === 1 ? <th className="thcenter">{ximgcrop}</th> : null}
            {xListeColActivity[4][1] === 1 ? <th className="thcenter">{ximgbert}</th> : null}
            {xListeColActivity[5][1] === 1 ? <th className="thcenter">{ximgwactv}</th> : null}
            {xListeColActivity[6][1] === 1 ? <th className="thcenter">{imgdeliv}</th> : null}
            {xListeColActivity[7][1] === 1 ? <th className="thcenter">{imgchore}</th> : null}
            {xListeColActivity[8][1] === 1 ? <th className="thcenter">Max{ximgtkt}</th> : null}
            {xListeColActivity[9][1] === 1 ? <th className="thcenter">Cost{imgdeliv}</th> : null}
            {xListeColActivity[10][1] === 1 ? <th className="thcenter">CostP2P{imgdeliv}</th> : null}
            {xListeColActivity[11][1] === 1 ? <th className="thcenter">Cost{ximgtkt}</th> : null}
            {xListeColActivity[12][1] === 1 ? <th className="thcenter">SFL{imgdeliv}</th> : null}
          </tr>
          <tr>
            {xListeColActivity[0][1] === 1 ? <td className="tdcenter">TOTAL</td> : null}
            {xListeColActivity[1][1] === 1 ? <td className="tdcenter">{parseFloat(totXP).toFixed(1)}</td> : null}
            {xListeColActivity[2][1] === 1 ? <td className="tdcenter">{tottktdchest}</td> : null}
            {xListeColActivity[3][1] === 1 ? <td className="tdcenter">{tottktcrop}</td> : null}
            {xListeColActivity[4][1] === 1 ? <td className="tdcenter">{tottktbert}</td> : null}
            {xListeColActivity[5][1] === 1 ? <td className="tdcenter">{tottktwactv}</td> : null}
            {xListeColActivity[6][1] === 1 ? <td className="tdcenter">{totdeliveriestkt}</td> : null}
            {xListeColActivity[7][1] === 1 ? <td className="tdcenter">{totchorestkt}</td> : null}
            {xListeColActivity[8][1] === 1 ? <td className="tdcenter">{totdeliveriestkt + totchorestkt + tottktdchest + tottktbert + tottktwactv}/{totmaxtkt}</td> : null}
            {xListeColActivity[9][1] === 1 ? <td className="tdcenter">{frmtNb(totdeliveriescost)}</td> : null}
            {xListeColActivity[10][1] === 1 ? <td className="tdcenter">{frmtNb(totdeliveriescostp2pt)}</td> : null}
            {xListeColActivity[11][1] === 1 ? <td className="tdcenter"></td> : null}
            {xListeColActivity[12][1] === 1 ? <td className="tdcenter">{frmtNb(totdeliveriessfl)}</td> : null}
          </tr>
        </thead>
      );
      const table = (
        <>
          <table className="table">
            {tableHeader}
            <tbody>
              {tableContent.reverse()}
            </tbody>
          </table>
        </>
      );
      setActivityTable(table);
      setMutants(mutantchickens);
      setsTickets(sTickets);
    }
  }
  function setActivityItem() {
    if (activityData[0]) {
      const ActTot = setActivityTot(activityData, "items");
      const allSortedItems = ActTot.allSortedItems;
      const compoHarvested = ActTot.compoHarvested;
      const compoHarvestn = ActTot.compoHarvestn;
      const compoBurn = ActTot.compoBurn;
      const foodBuild = ActTot.foodBuild;
      const delivBurn = ActTot.delivBurn;
      const tot = ActTot.tot;
      var totCost = 0;
      var totCostt = 0;
      var totCostn = 0;
      var totCosto = 0;
      const tableContent = allSortedItems.map(([element]) => {
        if (compoHarvested[element] > 0 || compoBurn[element] > 0) {
          const cobj = it[element] || fish[element] || flower[element] || null;
          const ico = cobj ? cobj.img : element === "SFL" ? imgsfl : element === "TKT" ? imgtkt : imgxp;
          const iburn = element === "SFL" ? '' : compoBurn[element] || '';
          const iquant = compoHarvested[element] ? compoHarvested[element] : '';
          const iquantb = iquant - (BurnChecked ? iburn : 0);
          const iharvestn = element === "SFL" ? frmtNb(tot.balSfl) : compoHarvestn[element] || ''; //tot.balSfl
          const titlesfl = element === "SFL" ? "based on farm balance" : "";
          //const icostb = !iquant && cobj ? cobj.cost * iburn || 0 : element === "SFL" && iburn;
          //const icostb = cobj ? cobj.cost * iburn || 0 : element === "SFL" && iburn;
          const icost = cobj ? (cobj.cost * (iquant || iburn)) : (element === "SFL" && (iburn - iquant));
          const icostp2pt = cobj && iquant > 0 ? !isNaN(Number(cobj.costp2pt)) ? Number(cobj.costp2pt) * (iquant || 0) : '' : '';
          const icostp2pn = cobj && iquant > 0 ? !isNaN(Number(cobj.costp2pn)) ? Number(cobj.costp2pn) * (iquant || 0) : '' : '';
          const icostp2po = cobj && iquant > 0 ? !isNaN(Number(cobj.costp2po)) ? Number(cobj.costp2po) * (iquant || 0) : '' : '';
          const icostt = icost;
          //const itoolscraft = cobj.toolscrafted;
          totCost += Number(icostt);
          totCostt += Number(icostp2pt);
          totCostn += Number(icostp2pn);
          totCosto += Number(icostp2po);
          return (
            <tr>
              <td className="tdcenter" id="iccolumn"><i><img src={ico} alt={''} className="itico" title={element} /></i></td>
              {xListeColActivityItem[0][1] === 1 ? <td className="tditem">{element}</td> : null}
              {xListeColActivityItem[1][1] === 1 ? <td className="tdcenter" title={titlesfl}>{iharvestn && iharvestn}</td> : null}
              {xListeColActivityItem[2][1] === 1 ? <td className="tdcenter">{iquantb && parseFloat(iquantb).toFixed(1)}</td> : null}
              {xListeColActivityItem[3][1] === 1 ? <td className="tdcenter">{iburn}</td> : null}
              {xListeColActivityItem[4][1] === 1 ? <td className="tdcenter">{icostt && frmtNb(icostt)}</td> : null}
              {xListeColActivityItem[5][1] === 1 ? <td className="tdcenter">{icostp2pt && frmtNb(icostp2pt)}</td> : null}
              {xListeColActivityItem[6][1] === 1 ? <td className="tdcenter">{icostp2pn && frmtNb(icostp2pn)}</td> : null}
              {xListeColActivityItem[7][1] === 1 ? <td className="tdcenter">{icostp2po && frmtNb(icostp2po)}</td> : null}
              {xListeColActivityItem[8][1] === 1 ? <td className="tdcenterbrd">{delivBurn["total"][element]}</td> : null}
              {xListeColActivityItem[9][1] === 1 ? Object.entries(foodBuild).map(([itemName]) => (
                (food[itemName]) ? (<td className="tdcenterbrd">{!isNaN(foodBuild[itemName][element]) ? parseFloat(foodBuild[itemName][element]).toFixed(0) : ""}</td>) : null)) : null}
            </tr>
          );
        }
      });
      const tableHeader = (
        <thead>
          <tr>
            <th className="th-icon"></th>
            {xListeColActivityItem[0][1] === 1 ? <th className="thcenter">Item</th> : null}
            {xListeColActivityItem[1][1] === 1 ? <th className="thcenter">Hrvst</th> : null}
            {xListeColActivityItem[2][1] === 1 ? <th className="thcenter">
              <div className="selectquantback" style={{ top: `4px` }}><FormControl variant="standard" id="formselectquant" className="selectquant" size="small">
                <InputLabel>Quantity</InputLabel>
                <Select value={selectedFromActivity} onChange={handleChangeFromActivity}>
                  <MenuItem value="today">today</MenuItem>
                  <MenuItem value="1">24h</MenuItem>
                  <MenuItem value="7">7 days</MenuItem>
                  <MenuItem value="31">1 month</MenuItem>
                  <MenuItem value="season">season</MenuItem>
                </Select></FormControl></div></th> : null}
            {xListeColActivityItem[3][1] === 1 ? <th className="thcenter">
              <div className="checktry"><input type="checkbox" id="CostColumnCheckbox" style={{ alignContent: `right` }} checked={BurnChecked} onChange={handleBurnCheckedChange} /></div>
              Burn</th> : null}
            {xListeColActivityItem[4][1] === 1 ? <th className="thcenter">Cost</th> : null}
            {xListeColActivityItem[5][1] === 1 ? <th className="thcenter">Trader</th> : null}
            {xListeColActivityItem[6][1] === 1 ? <th className="thcenter">Niftyswap</th> : null}
            {xListeColActivityItem[7][1] === 1 ? <th className="thcenter">OpenSea</th> : null}
            {xListeColActivityItem[8][1] === 1 ? <th className="tdcenterbrd"><i><img src="./icon/ui/delivery_board.png" title="Deliveries burn" className="itico" /></i></th> : null}
            {xListeColActivityItem[9][1] === 1 ? Object.entries(foodBuild).map(([itemName]) => (
              (food[itemName]) ? (<th className="tdcenterbrd" key={itemName}><i><img src={food[itemName].img} title={itemName} className="itico" /></i></th>) : null)) : null}
          </tr>
          <tr>
            <td className="tdcenter">TOTAL</td>
            {xListeColActivityItem[0][1] === 1 ? <td className="tdcenter"></td> : null}
            {xListeColActivityItem[1][1] === 1 ? <td className="tdcenter"></td> : null}
            {xListeColActivityItem[2][1] === 1 ? <td className="tdcenter"></td> : null}
            {xListeColActivityItem[3][1] === 1 ? <td className="tdcenter"></td> : null}
            {xListeColActivityItem[4][1] === 1 ? <td className="tdcenter">{frmtNb(totCost)}</td> : null}
            {xListeColActivityItem[5][1] === 1 ? <td className="tdcenter">{frmtNb(totCostt)}</td> : null}
            {xListeColActivityItem[6][1] === 1 ? <td className="tdcenter">{frmtNb(totCostn)}</td> : null}
            {xListeColActivityItem[7][1] === 1 ? <td className="tdcenter">{frmtNb(totCosto)}</td> : null}
            {xListeColActivityItem[8][1] === 1 ? <td className="tdcenterbrd"></td> : null}
            {xListeColActivityItem[9][1] === 1 ? Object.entries(foodBuild).map(([itemName]) => (
              (food[itemName]) ? (<td className="tdcenterbrd" key={itemName}>{foodBuild[itemName]["quant"]}</td>) : null)) : null}
          </tr>
        </thead>
      );
      const table = (
        <>
          <table className="table">
            {tableHeader}
            <tbody>
              {tableContent}
            </tbody>
          </table>
        </>
      );
      setActivityTable(table);
      setMutants(mutantchickens);
      setsTickets(sTickets);
    }
  }
  function setActivityQuest() {
    if (activityData[0]) {
      const tot = setActivityTotQuest(activityData);
      const Quest = tot.Quest;
      const questKeys = Object.keys(Quest);
      const dayKeys = Object.keys(activityData);
      const uniqueQuests = new Set();
      const completionsByDate = {};
      const dToday = formatDate(new Date());
      questKeys.forEach((element) => {
        const cobj = Quest[element];
        //const idate = new Date(cobj.date);
        //const qxdate = `${String(idate.getMonth() + 1).padStart(2, '0')}/${String(idate.getDate()).padStart(2, '0')}/${String(idate.getFullYear()).slice(-2)}`;
        const qxdate = formatDate(cobj.date);
        uniqueQuests.add(JSON.stringify({
          from: cobj.from,
          description: cobj.description,
          reward: Number(cobj.reward),
          istkt: cobj.istkt,
        }));
        if (!completionsByDate[qxdate]) {
          completionsByDate[qxdate] = {};
        }
        completionsByDate[qxdate][JSON.stringify({
          from: cobj.from,
          description: cobj.description,
          reward: Number(cobj.reward),
          istkt: cobj.istkt,
        })] = cobj.completed ? "X" : qxdate === dToday ? "." : "-";
      });
      const uniqueQuestsArray = Array.from(uniqueQuests).map(JSON.parse).sort((a, b) => {
        if (a.from === "hank" && b.from !== "hank") {
          return -1;
        } else if (a.from !== "hank" && b.from === "hank") {
          return 1;
        } else {
          return a.from.localeCompare(b.from);
        }
      });
      const tableContent = uniqueQuestsArray.reverse().map((uniqueQuest) => {
        const columns = dayKeys.map((date, index) => {
          //const qxdate = `${String(new Date(date).getMonth() + 1).padStart(2, '0')}/${String(new Date(date).getDate()).padStart(2, '0')}/${String(new Date(date).getFullYear()).slice(-2)}`;
          const qxdate = formatDate(activityData[index].date);
          return completionsByDate[qxdate] ? completionsByDate[qxdate][JSON.stringify(uniqueQuest)] || "" : "";
        });
        var xfrom = "";
        const ofrom = uniqueQuest.from;
        xfrom = "./icon/pnj/" + ofrom + ".png";
        if (ofrom === "pumpkin' pete") { xfrom = "./icon/pnj/pumpkinpete.png" }
        const ximgfrom = <img src={xfrom} alt="" title={ofrom} style={{ width: '20px', height: '20px' }} />;
        //const ximgrew = <img src={imgtkt} alt="" title={ofrom} style={{ width: '25px', height: '25px' }} />;
        return (
          <tr>
            {xListeColActivityQuest[0][1] === 1 ? <td className="tdcenter" id="iccolumn">{ximgfrom}</td> : null}
            {xListeColActivityQuest[1][1] === 1 ? <td className="tdcenter" id="iccolumn" style={{ fontSize: '11px' }} dangerouslySetInnerHTML={{ __html: uniqueQuest.description }}></td> : null}
            {xListeColActivityQuest[2][1] === 1 ? <td className="tdcenter" style={{ fontSize: '11px' }}>{uniqueQuest.reward}</td> : null}
            {xListeColActivityQuest[3][1] === 1 ? columns.map((value, index) => (
              (value === "X") ? (<td className="tdcenterbrd" style={{ backgroundColor: 'rgba(0, 110, 0, 0.39)' }} title='completed'></td>) :
                (value === "-") ? (<td className="tdcenterbrd" style={{ backgroundColor: 'rgba(110, 0, 0, 0.39)' }} title='skipped'></td>) :
                  (value === ".") ? (<td className="tdcenterbrd" style={{ backgroundColor: 'rgba(110, 110, 0, 0.39)' }} title='not done'></td>) :
                    (<td className="tdcenterbrd"></td>))) : null}
          </tr>
        );
      });
      const tableHeader = (
        <thead>
          <tr>
            {xListeColActivityQuest[0][1] === 1 ? <th className="th-icon">From</th> : null}
            {xListeColActivityQuest[1][1] === 1 ? <th className="thcenter" style={{ fontSize: '14px' }}>Description</th> : null}
            {xListeColActivityQuest[2][1] === 1 ? <th className="thcenter" style={{ fontSize: '14px' }}>Reward</th> : null}
            {xListeColActivityQuest[3][1] === 1 ? Object.entries(dayKeys).map((date, index) => (
              (<th className="tdcenterbrd" style={{ fontSize: '8px' }}>{formatDateAndSupYr(activityData[index].date)}</th>))) : null}
          </tr>
        </thead>
      );
      const table = (
        <>
          <table className="table">
            {tableHeader}
            <tbody>
              {tableContent.reverse()}
            </tbody>
          </table>
        </>
      );
      setActivityTable(table);
      setMutants(mutantchickens);
      setsTickets(sTickets);
    }
  }
  function setActivityTot(activityData, xContext) {
    let compoHarvested = [];
    compoHarvested["XP"] = 0;
    compoHarvested["TKT"] = 0;
    compoHarvested["SFL"] = 0;
    let compoHarvestn = [];
    let compoBurn = [];
    compoBurn["SFL"] = 0;
    let foodBuild = [];
    let delivBurn = [];
    delivBurn["total"] = [];
    var tot = {
      XP: 0,
      tktchest: 0,
      tktcrop: 0,
      tktbert: 0,
      tktwact: 0,
      tktbertMax: 0,
      deliveriestkt: 0,
      deliveriessfl: 0,
      deliveriescost: 0,
      deliveriestktcost: 0,
      deliveriescostp2pt: 0,
      deliveriescostp2pn: 0,
      deliveriescostp2po: 0,
      chorestkt: 0,
      tktMax: 0,
      tktCost: 0,
      balSfl: 0
    };
    //const dataEntries = Object.entries(activityData);
    const dataEntries = Object.keys(activityData);
    let i = 0;
    dataEntries.map((value, index) => {
      const DataContext = xContext === "items" ? activityData[index] : activityData;
      const endDate = new Date(DataContext.date);
      const isSeasonDay = endDate >= dateSeason;
      const curw = ((endDate.getDate()) / 8);
      const isweeklyactday = Number.isInteger(curw) || (endDate.getDate() === dateSeason.getDate() && endDate.getMonth() === dateSeason.getMonth());
      const wactdone = isweeklyactday && wklactivity[Math.floor(curw) + 1];
      if (((xContext === "day" && i === 0) || xContext !== "day") && isSeasonDay) {
        const itktdchest = DataContext.data.ticketdailychest;
        const itktcrop = DataContext.data.ticketsoncrop;
        const ibert = DataContext.data.bert && DataContext.data.bert;
        const itktbertMax = ibert ? ibert.reward ? ibert.reward : 0 : 0;
        const ibertcompleted = ibert ? ibert.completed && ibert.completed : false;
        const itktbert = ibertcompleted === true ? itktbertMax : 0;
        const itktwact = isweeklyactday ? wactdone ? tktWeekly : 0 : 0;
        const itktwactMax = isweeklyactday ? tktWeekly : 0;
        //const itkttntcl = DataContext.data.tickettentacle ? DataContext.data.tickettentacle : 0;
        //const itkttntcl = DataContext.data.totfish["Kraken Tentacle"] ? DataContext.data.totfish["Kraken Tentacle"] * 12 : 0;
        //const istoday = selectedFromActivity === "today";
        //compoHarvested["TKT"] += !istoday ? itkttntcl : 0;
        tot.tktchest += itktdchest;
        tot.tktcrop += itktcrop;
        tot.tktbert += itktbert;
        tot.tktwact += itktwact;
        //tot.tktbertMax += itktbertMax;
        tot.tktMax += 1 + itktbertMax + itktwactMax;
        compoHarvested["TKT"] += itktcrop + itktdchest + itktbert + itktwact; // + itkttntcl;
        const prevBalance = activityData[i + 1] ? activityData[i + 1].data.balance : 0;
        const dayBalance = DataContext.data.balance;
        const dayGain = activityData[i + 1] ? prevBalance - dayBalance : 0;
        tot.balSfl += activityData.length > 1 ? dayGain : 0;
        const totHarvestEntries = Object.entries(DataContext.data.totharvest);
        totHarvestEntries.map(([item]) => {
          compoHarvested[item] = compoHarvested[item] || 0;
          compoHarvested[item] += DataContext.data.totharvest[item];
          compoHarvestn[item] = compoHarvestn[item] || 0;
          compoHarvestn[item] += DataContext.data.totharvestn[item];
        });
        if (DataContext.data.totfish) {
          const totFishEntries = Object.entries(DataContext.data.totfish);
          totFishEntries.map(([item]) => {
            compoHarvested[item] = compoHarvested[item] || 0;
            compoHarvested[item] += DataContext.data.totfish[item];
            //compoHarvested["TKT"] += item === "Kraken Tentacle" ? DataContext.data.totfish[item] : 0;
          });
        }
        if (DataContext.data.totflower) {
          const totFlowerEntries = Object.entries(DataContext.data.totflower);
          totFlowerEntries.map(([item]) => {
            compoHarvested[item] = compoHarvested[item] || 0;
            compoHarvested[item] += DataContext.data.totflower[item];
            //compoHarvested["TKT"] += item === "Kraken Tentacle" ? DataContext.data.totfish[item] : 0;
          });
        }
        const totBuildEntries = Object.entries(DataContext.data.totbuild);
        totBuildEntries.map(([item, quantity]) => {
          const buildQuant = DataContext.data.totbuild[item];
          if (food[item]) {
            foodBuild[item] = foodBuild[item] || [];
            foodBuild[item]["quant"] = foodBuild[item]["quant"] || 0;
            foodBuild[item]["quant"] += buildQuant;
            for (let i = 1; i < 5; i++) {
              const compo = food[item][`compo${i}`];
              const quant = food[item][`quant${i}`];
              if (it[compo] || fish[compo]) {
                compoBurn[compo] = compoBurn[compo] || 0;
                compoBurn[compo] += quant * buildQuant;
                foodBuild[item][compo] = foodBuild[item][compo] || 0;
                foodBuild[item][compo] += quant * buildQuant;
                //console.log(item + ":" + compoValues[compo]);
              }
            }
            tot.XP += Number(food[item].xp) * buildQuant;
            compoHarvested["XP"] += Number(food[item].xp) * buildQuant;
            foodBuild[item]["XP"] = foodBuild[item]["XP"] || 0;
            foodBuild[item]["XP"] += Number(food[item].xp) * buildQuant;
          }
          if (fish[item]) {
            compoHarvested[item] = compoHarvested[item] || 0;
            compoHarvested[item] += buildQuant;
            tot.XP += !isNaN(Number(fish[item].xp)) ? Number(fish[item].xp) * buildQuant : 0;
          }
        });
        const totToolEntries = Object.entries(DataContext.data.toolscrafted);
        totToolEntries.map(([item], quantity) => {
          const iquant = DataContext.data.toolscrafted[item];
          if (item === "Axe") {
            compoBurn["SFL"] += 0.065 * iquant;
          }
          if (item === "Pickaxe") {
            compoBurn["SFL"] += 0.065 * iquant;
            compoBurn["Wood"] = compoBurn["Wood"] || 0;
            compoBurn["Wood"] += 3 * iquant;
          }
          if (item === "Stone Pickaxe") {
            compoBurn["SFL"] += 0.065 * iquant;
            compoBurn["Wood"] = compoBurn["Wood"] || 0;
            compoBurn["Wood"] += 3 * iquant;
            compoBurn["Stone"] = compoBurn["Stone"] || 0;
            compoBurn["Stone"] += 5 * iquant;
          }
          if (item === "Iron Pickaxe") {
            compoBurn["SFL"] += 0.25 * iquant;
            compoBurn["Wood"] = compoBurn["Wood"] || 0;
            compoBurn["Wood"] += 3 * iquant;
            compoBurn["Iron"] = compoBurn["Iron"] || 0;
            compoBurn["Iron"] += 5 * iquant;
          }
          if (item === "Gold Pickaxe") {
            compoBurn["SFL"] += 0.3125 * iquant;
            compoBurn["Wood"] = compoBurn["Wood"] || 0;
            compoBurn["Wood"] += 3 * iquant;
            compoBurn["Gold"] = compoBurn["Gold"] || 0;
            compoBurn["Gold"] += 3 * iquant;
          }
          if (item === "Rod") {
            compoBurn["SFL"] += 0.065 * iquant;
            compoBurn["Wood"] = compoBurn["Wood"] || 0;
            compoBurn["Wood"] += 3 * iquant;
            compoBurn["Stone"] = compoBurn["Stone"] || 0;
            compoBurn["Stone"] += 1 * iquant;
          }
        });
        const totDelivEntries = Object.entries(DataContext.data.deliveries);
        totDelivEntries.map(([item]) => {
          const OrderItem = DataContext.data.deliveries[item];
          /* const createdDate = new Date(OrderItem.createdAt);
          const offsetInMinutes = createdDate.getTimezoneOffset();
          const createdDateUTC = new Date(createdDate.getTime() + offsetInMinutes * 60 * 1000);
          const dNow = new Date();
          const isToday = createdDateUTC.getDay() === dNow.getDay() && createdDateUTC.getMonth() === dNow.getMonth(); */
          //const Shelly = ["shelly"];
          //const isShelly = Shelly.some(valeur => new RegExp(valeur).test(totDelivEntries[item][1].from));
          //if (isToday) {
          const isShelly = item === "shelly";
          let patterntkn = /res\/(.*?)\ alt=/g;
          let correspondancetkn = patterntkn.exec(OrderItem.reward);
          let pattern = /(.*?)<img/g;
          let correspondance = pattern.exec(OrderItem.reward);
          const istkt = correspondancetkn && correspondancetkn[1] !== "sfltoken.png" && correspondancetkn[1] !== "coins.png";
          const isPreSeason = OrderItem.preSeason && OrderItem.preSeason;
          if (OrderItem.completed) {
            delivBurn[item] = [];
            //"items": "1<img src=./icon/food/fermented_carrots.png alt=\"\" title=\"Fermented Carrots\" style=\"width: 17px; height: 17px\"/>",
            var regex = /(\d+)<img[^>]+title="([^"]+)"[^>]*\/>/g;
            var match;
            while ((match = regex.exec(OrderItem.items)) !== null) {
              var value = match[1];
              const ivalue = Number(value);
              var title = match[2];
              if (food[title]) {
                for (let i = 1; i < 5; i++) {
                  const compo = food[title][`compo${i}`];
                  const quant = food[title][`quant${i}`];
                  if (it[compo] || fish[compo]) {
                    compoBurn[compo] = compoBurn[compo] || 0;
                    compoBurn[compo] += quant * ivalue;
                    delivBurn[item][compo] = delivBurn[item][compo] || 0;
                    delivBurn[item][compo] += quant * ivalue;
                    delivBurn["total"][compo] = delivBurn["total"][compo] || 0;
                    delivBurn["total"][compo] += quant * ivalue;
                    const xcompo = it[compo] ? it[compo] : fish[compo] ? fish[compo] : null;
                    const icost = xcompo.cost * (quant * ivalue);
                    const icostt = xcompo.costp2pt ? xcompo.costp2pt : 0 * (quant * ivalue);
                    const icostn = xcompo.costp2pn ? xcompo.costp2pn : 0 * (quant * ivalue);
                    const icosto = xcompo.costp2po ? xcompo.costp2po : 0 * (quant * ivalue);
                    tot.deliveriescost += icost;
                    tot.deliveriescostp2pt += Number(icostt);
                    tot.deliveriescostp2pn += Number(icostn);
                    tot.deliveriescostp2po += Number(icosto);
                    tot.deliveriestktcost += istkt ? icost : 0
                    //console.log(item + ":" + compoValues[compo]);
                  }
                }
                //tot.XP += Number(food[title].xp) * ivalue;
              }
              if (it[title] || fish[title]) {
                compoBurn[title] = compoBurn[title] || 0;
                compoBurn[title] += ivalue;
                delivBurn[item][title] = delivBurn[item][title] || 0;
                delivBurn[item][title] += ivalue;
                delivBurn["total"][title] = delivBurn["total"][title] || 0;
                delivBurn["total"][title] += ivalue;
                const xcompo = it[title] ? it[title] : fish[title] ? fish[title] : null;
                const icost = xcompo.cost * ivalue;
                const icostt = xcompo.costp2pt ? xcompo.costp2pt : 0 * ivalue;
                const icostn = xcompo.costp2pn ? xcompo.costp2pn : 0 * ivalue;
                const icosto = xcompo.costp2po ? xcompo.costp2po : 0 * ivalue;
                tot.deliveriescost += icost;
                tot.deliveriescostp2pt += Number(icostt);
                tot.deliveriescostp2pn += Number(icostn);
                tot.deliveriescostp2po += Number(icosto);
                tot.deliveriestktcost += istkt ? icost : 0
                //console.log(item + ":" + compoValues[compo]);
              }
            }
            //"reward": "1.42<img src=./icon/res/sfltoken.png alt=\"\" style=\"width: 20px; height: 20px\"/>",
            //"reward": "4<img src=./icon/res/mermaid_scale.webp alt=\"\" style=\"width: 20px; height: 20px\"/>",
            const itm = istkt ? "TKT" : "SFL";
            if (correspondance) {
              compoHarvested[itm] += Number(correspondance[1]) || 0;
              tot.deliveriestkt += !isPreSeason && istkt && (Number(correspondance[1]) || 0);
              tot.deliveriessfl += !istkt && (Number(correspondance[1]) || 0);
            }
          }
          tot.tktMax += !isShelly && istkt && (correspondance && (Number(correspondance[1]) || 0));
          //}
        });
        const totChoreEntries = Object.entries(DataContext.data.chores);
        totChoreEntries.map(([item]) => {
          const choreItem = DataContext.data.chores[item];
          /* const createdDate = new Date(totChoreEntries[item][1].createdAt);
          const offsetInMinutes = createdDate.getTimezoneOffset();
          const createdDateUTC = new Date(createdDate.getTime() + offsetInMinutes * 60 * 1000);
          const dNow = new Date();
          const isToday = createdDateUTC.getDay() === dNow.getDay() && createdDateUTC.getMonth() === dNow.getMonth(); */
          //if (isToday) {
          //const choreItem = totChoreEntries[item][1] && totChoreEntries[item][1];
          if (choreItem && choreItem.completed) {
            compoHarvested["TKT"] = compoHarvested["TKT"] || 0;
            compoHarvested["TKT"] += choreItem.tickets;
            tot.chorestkt += choreItem.tickets;
          }
          tot.tktMax += choreItem && Number(choreItem.tickets);
          //}
        });
      }
      i++;
    });
    //console.log(compoBurn);
    tot.tktCost = tot.deliveriestktcost / (tot.deliveriestkt);
    let compoTotal = [];
    compoTotal = Object.assign({}, compoHarvested, compoBurn);
    const itemOrder = Object.keys(it);
    const fishOrder = Object.keys(fish);
    const flowerOrder = Object.keys(flower);
    const compoEntries = Object.entries(compoTotal);
    const sortedInventoryItems = itemOrder.map((item) => {
      const entry = compoEntries.find(([entryItem]) => entryItem === item);
      const quantity = entry ? entry[1] : 0;
      return [item, quantity];
    });
    const sortedFishItems = fishOrder.map((item) => {
      const entry = compoEntries.find(([entryItem]) => entryItem === item);
      const quantity = entry ? entry[1] : 0;
      return [item, quantity];
    });
    const sortedFlowerItems = flowerOrder.map((item) => {
      const entry = compoEntries.find(([entryItem]) => entryItem === item);
      const quantity = entry ? entry[1] : 0;
      return [item, quantity];
    });
    sortedInventoryItems.unshift(["TKT", compoHarvested["TKT"]]);
    sortedInventoryItems.unshift(["SFL", compoBurn["SFL"]]);
    sortedInventoryItems.unshift(["XP", compoHarvested["XP"]]);
    const allSortedItems1 = sortedInventoryItems.concat(sortedFlowerItems);
    const allSortedItems = allSortedItems1.concat(sortedFishItems);
    const result = {
      allSortedItems: allSortedItems,
      compoHarvested: compoHarvested,
      compoHarvestn: compoHarvestn,
      compoBurn: compoBurn,
      foodBuild: foodBuild,
      delivBurn: delivBurn,
      tot: tot
    }
    return result;
  }
  function setActivityTotQuest(activityData) {
    let Quest = [];
    let i = 0;
    //const dataEntries = Object.entries(activityData);
    const dataEntries = Object.keys(activityData);
    dataEntries.map((value, index) => {
      const totChoreEntries = Object.entries(activityData[index].data.chores);
      totChoreEntries.map(([item]) => {
        const choreItem = activityData[index].data.chores[item];
        const choreFrom = "hank";
        const choreDesc = choreItem.description;
        const choreDate = formatDate(activityData[index].date);
        const choreCompleted = choreItem.completed;
        const choreTkt = choreItem.tickets;
        Quest[i] = {
          from: choreFrom,
          description: choreDesc,
          date: choreDate,
          completed: choreCompleted,
          istkt: true,
          reward: choreTkt
        }
        i++;
        //if (!Dates.includes(choreDate)) { Dates.push(choreDate) }
        //console.log(Quest[i - 1]);
      });
      const totDelivEntries = Object.entries(activityData[index].data.deliveries);
      totDelivEntries.map(([item]) => {
        const isShelly = item === "shelly";
        if (!isShelly) {
          const delivItem = activityData[index].data.deliveries[item];
          const delivFrom = item;
          const delivDesc = delivItem.items;
          const delivDate = formatDate(activityData[index].date);
          const delivCompleted = delivItem.completed;
          let patterntkn = /res\/(.*?)\ alt=/g;
          let correspondancetkn = patterntkn.exec(delivItem.reward);
          let pattern = /(.*?)<img/g;
          let correspondance = pattern.exec(delivItem.reward);
          const istkt = correspondancetkn && correspondancetkn[1] !== "sfltoken.png";
          const delivRew = correspondance && correspondance[1];
          Quest[i] = {
            from: delivFrom,
            description: delivDesc,
            date: delivDate,
            completed: delivCompleted,
            istkt: istkt,
            reward: delivRew
          }
          i++;
          //if (!Dates.includes(delivDate)) { Dates.push(delivDate) }
          //console.log(Quest[i - 1]);
        }
      });
    });
    const result = {
      Quest: Quest,
      //Dates: Dates,
    }
    return result;
  }
  function PBarSFL() {
    const maxh = 255;
    if (farmData.balance) {
      const previousQuantity = farmData.previousBalance;
      const Quantity = farmData.balance;
      const difference = Quantity - previousQuantity;
      const absDifference = Math.abs(difference);
      const isNegativeDifference = difference < 0;
      const hoardPercentage = Math.floor((absDifference / maxh) * 100);
      return (
        hoardPercentage > 0 && (
          <div className={`progress-barb ${isNegativeDifference ? 'negative' : ''}`}>
            <div className="progress" style={{ width: `${hoardPercentage}%` }}>
              <span className="progress-text">
                {isNegativeDifference ? frmtNb(absDifference) : `${frmtNb(difference)}/${frmtNb(maxh)}`}
              </span>
            </div>
          </div>
        )
      );
    }
  }
  async function getPrices() {
    //if (testb === false) {
    var bTrynftArray = [];
    var bTrynftwArray = [];
    var bTrybuildArray = [];
    var bTryskillArray = [];
    var bTrybudArray = [];
    if (localStorage.getItem("SFLManData") !== null) {
      const cookieValue = localStorage.getItem("SFLManData");
      var loadedData = JSON.parse(cookieValue);
      bTrynftArray = loadedData.bTrynft.length > 0 ? loadedData.bTrynft.reduce((acc, item) => { acc[item.name] = item.value; return acc; }, {}) : "";
      bTrynftwArray = loadedData.bTrynftw.length > 0 ? loadedData.bTrynftw.reduce((acc, item) => { acc[item.name] = item.value; return acc; }, {}) : "";
      bTrybuildArray = loadedData.bTrybuild.length > 0 ? loadedData.bTrybuild.reduce((acc, item) => { acc[item.name] = item.value; return acc; }, {}) : "";
      bTryskillArray = loadedData.bTryskill.length > 0 ? loadedData.bTryskill.reduce((acc, item) => { acc[item.name] = item.value; return acc; }, {}) : "";
      bTrybudArray = loadedData.bTrybud.length > 0 ? loadedData.bTrybud.reduce((acc, item) => { acc[item.name] = item.value; return acc; }, {}) : "";
    }
    const response = await fetch("/getdatacrypto", {
      method: 'GET',
      headers: {
        frmid: lastClickedInputValue.current,
        xinitprc: xinitprc,
        inputkeep: lastClickedInputKeep.current,
        xtrynft: JSON.stringify(bTrynftArray),
        xtrynftw: JSON.stringify(bTrynftwArray),
        xtrybuild: JSON.stringify(bTrybuildArray),
        xtryskill: JSON.stringify(bTryskillArray),
        xtrybud: JSON.stringify(bTrybudArray),
      }
    });
    if (response.ok) {
      const responseData = await response.json();
      //console.log(responseData);
      setpriceData(JSON.parse(JSON.stringify(responseData.priceData)));
      setpriceDataT(responseData.priceDataT);
      setpriceDataN(responseData.priceDataN);
      setpriceDataO(responseData.priceDataO);
      setpriceDataOW(responseData.priceDataOW);
      supplyOS = responseData.supplyOS;
      supplyOSW = responseData.supplyOSW;
      if (responseData.allData !== "" && responseData.allData !== undefined) {
        setFarmData(responseData.allData.frmData);
        setBumpkinData(responseData.allData.Bumpkin);
        MergeIt(responseData.allData.it, it);
        setPlanted(responseData.allData.it);
        it = responseData.allData.it;
        MergeFood(responseData.allData.food, food);
        food = responseData.allData.food;
        MergeFish(responseData.allData.fish, fish);
        fish = responseData.allData.fish;
        flower = responseData.allData.flower;
        //food = responseData.allData.food;
        //it = responseData.allData.it;
        nft = responseData.allData.nft;
        nftw = responseData.allData.nftw;
        skill = responseData.allData.skill;
        buildng = responseData.allData.buildng;
        bud = responseData.allData.bud;
        getFarmit(it);
        getCookit(food);
        getTryit(nft, nftw, skill, buildng, bud);
        spot = responseData.allData.spot;
        //getFarmit(it);
        //getCookit(food);
        //getTryit(nft, nftw, skill, buildng, bud);
        //getActive(nft, nftw, skill, buildng, bud);
        mutantchickens = responseData.allData.mutantchickens;
        sTickets = responseData.allData.sTickets;
        setdeliveriesData(responseData.allData.orderstable);
        buildngf = responseData.allData.buildngf;
        //fishing = responseData.allData.fishing;
        //Fishing = responseData.allData.FishingCasts;
        fishingDetails = responseData.allData.fishingDetails;
        wklactivity = responseData.allData.wklactivity;
        //expand = responseData.allData.expand;
        //xexpandData = responseData.allData.expandData;
        //frmOwner = responseData.allData.frmOwner;
      }
      //NFTPrice();
      xinitprc = true;
      setReqState('');
    } else {
      console.log(`Error : ${response.status}`);
      setReqState('Error refreshing prices');
      //localStorage.clear();
      //console.log("Cleared local data");
    }
    /*} else {
      const responseData = xrespPrice;
      setpriceData(JSON.parse(JSON.stringify(responseData.priceData)));
      setpriceDataT(responseData.priceDataT);
      setpriceDataN(responseData.priceDataN);
      setpriceDataO(responseData.priceDataO);
      setpriceDataOW(responseData.priceDataOW);
      NFTPrice();
    } */
  }
  function setMutants(table) {
    const MutItems = table.map(([item, amount], index) => {
      const cobj = nft[table[index][0].name];
      const ico = cobj ? cobj.img : '';
      return (
        <img src={ico} alt={''} className="nftico" />
      )
    });
    if (table.length > 0) {
      var poulette = <img src="./icon/nft/poulte.png" alt={''} className="poulteico" />;
      MutItems.unshift(poulette);
    }
    setmutData(MutItems);
  }
  function setsTickets(tabletk) {
    if (tabletk.length > 1) {
      setticketsData(<div><img src={imgtkt} alt={''} className="itico" />{tabletk[1].amount}</div>);
    } else {
      setticketsData("");
    }
  }
  function NFTPrice() {
    const priceOEntries = Object.entries(priceDataO);
    const priceOWEntries = Object.entries(priceDataOW);
    const nftEntries = Object.entries(nft);
    const nftwEntries = Object.entries(nftw);
    const BoostEntries = Object.entries(BoostNFT);
    for (var inft = 0; inft < nftEntries.length; inft++) {
      for (var j = 0; j < priceOEntries.length; j++) {
        if (nftEntries[inft][1].id === priceOEntries[j][1].id) {
          nft[nftEntries[inft][0]].price = parseFloat(priceOEntries[j][1].unit).toFixed(2);
          try { nft[nftEntries[inft][0]].supply = supplyOS[nftEntries[inft][1].id].supply }
          catch { nft[nftEntries[inft][0]].supply = 0 }
          break;
        }
      }
      for (var i = 0; i < BoostEntries.length; i++) {
        if (nftEntries[inft][0] === BoostEntries[i][1].item) {
          BoostNFT[i].price = parseFloat(nftEntries[inft][1].price).toFixed(2);
          break;
        }
      }
    }
    for (var inftw = 0; inftw < nftwEntries.length; inftw++) {
      for (var jw = 0; jw < priceOWEntries.length; jw++) {
        if (nftwEntries[inftw][1].id === priceOWEntries[jw][1].id) {
          nftw[nftwEntries[inftw][0]].price = parseFloat(priceOWEntries[jw][1].unit).toFixed(2);
          try { nftw[nftwEntries[inftw][0]].supply = supplyOSW[nftwEntries[inftw][1].id].supply }
          catch { nftw[nftwEntries[inftw][0]].supply = 0 }
          break;
        }
      }
      for (var iw = 0; iw < BoostEntries.length; iw++) {
        if (nftwEntries[inftw][0] === BoostEntries[iw][1].item) {
          BoostNFT[iw].price = parseFloat(nftwEntries[inftw][1].price).toFixed(2);
          break;
        }
      }
    }
  }

  useEffect(() => {
    loadCookie();
    const fetchData = async () => {
      try {
        await getPrices();
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    };
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    if (selectedInv === "inv") {
      try {
        setInv();
        if (farmData.balance) { setCookie() }
      } catch (error) {
        //localStorage.clear();
        console.log(error);
      }
    }
    if (selectedInv === "cook") {
      try {
        setCook();
        if (farmData.balance) { setCookie() }
      } catch (error) {
        //localStorage.clear();
        //console.log("Error, cleared local data");
        console.log(error);
      }
    }
    if (selectedInv === "fish") {
      try {
        setFish();
        if (farmData.balance) { setCookie() }
      } catch (error) {
        //localStorage.clear();
        //console.log("Error, cleared local data");
        console.log(error);
      }
    }
    if (selectedInv === "flower") {
      try {
        setFlower();
        if (farmData.balance) { setCookie() }
      } catch (error) {
        //localStorage.clear();
        //console.log("Error, cleared local data");
        console.log(error);
      }
    }
    if (selectedInv === "expand") {
      try {
        setExpand();
        if (farmData.balance) { setCookie() }
      } catch (error) {
        //localStorage.clear();
        //console.log("Error, cleared local data");
        console.log(error);
      }
    }
    if (selectedInv === "activity") {
      //try {
      if (activityDisplay === "day") {
        setActivityDay();
      }
      if (activityDisplay === "item") {
        setActivityItem();
      }
      if (activityDisplay === "quest") {
        setActivityQuest();
      }
      if (farmData.balance) { setCookie() }
      /* } catch {
        //localStorage.clear();
        //console.log("Error, cleared local data");
      } */
    }
  }, [farmData, itData, selectedCurr, selectedQuant, selectedQuantCook, selectedQuantFish, selectedQuantity, selectedQuantityCook, selectedCostCook,
    selectedReady, selectedDsfl, selectedInv, inputMaxBB, inputKeep, inputFarmTime, deliveriesData, HarvestD,
    priceDataT, priceDataN, priceDataO, xListeCol, xListeColCook, xListeColFish, xListeColFlower, xListeColExpand, xListeColActivity, xListeColActivityItem, CostChecked, TryChecked, BurnChecked, cstPrices,
    fromtolvltime, inputFromLvl, inputToLvl, fromtoexpand, activityData, activityDisplay]);
  useEffect(() => {
    NFTPrice();
  }, [priceDataO]);
  useEffect(() => {
    if (inputFromLvl > 0 && inputToLvl < 100) { getxpFromToLvl(inputFromLvl, inputToLvl, xdxp) }
  }, [dailyxp]);
  useEffect(() => {
    getFromToExpand(fromexpand + 1, toexpand, selectedExpandType);
  }, [fromexpand, toexpand, selectedExpandType]);
  useEffect(() => {
    if (selectedInv === "activity") {
      getActivity();
    }
  }, [selectedFromActivity, selectedFromActivityDay, selectedInv, activityDisplay]);

  const xfishcastmax = !TryChecked ? fishingDetails.CastMax : fishingDetails.CastMaxtry;
  const xfishcost = !TryChecked ? fishingDetails.CastCost : fishingDetails.CastCosttry;
  const fishcasts = fishingDetails.casts + "/" + xfishcastmax;
  const fishcosts = parseFloat(fishingDetails.casts * xfishcost).toFixed(3) + "/" + parseFloat(xfishcastmax * xfishcost).toFixed(3);
  return (
    <>
      <div className="App">
        <div className="top-frame">
          <h1 className="App-h1">
            <img src={logo} alt="" className="App-logo" />Sunflower Manager
            <div className="don">if you would like to give MATIC to help keep server running : <a id="copy-link" href="#" onClick={(event) => handleDonClick("0xAc3c7f9f1f8492Cc10A4fdb8C738DD82013d61dA", event.target)}>0xAc3c7f9f1f8492Cc10A4fdb8C738DD82013d61dA</a></div>
            <div className="currencies">
              <div className="currency-controls">
                {selectedInv === "inv" ? <div className="selectcol" size="small"><DropdownCheckbox options={xListeCol} onChange={handleDropdownChange} /></div> : ""}
                {selectedInv === "cook" ? <div className="selectcol" size="small"><DropdownCheckbox options={xListeColCook} onChange={handleDropdownCookChange} /></div> : ""}
                {selectedInv === "fish" ? <div className="selectcol" size="small"><DropdownCheckbox options={xListeColFish} onChange={handleDropdownFishChange} /></div> : ""}
                {selectedInv === "flower" ? <div className="selectcol" size="small"><DropdownCheckbox options={xListeColFlower} onChange={handleDropdownFlowerChange} /></div> : ""}
                {selectedInv === "expand" ? <div className="selectcol" size="small"><DropdownCheckbox options={xListeColExpand} onChange={handleDropdownExpandChange} /></div> : ""}
                {selectedInv === "activity" && activityDisplay === "day" ? <div className="selectcol" size="small"><DropdownCheckbox options={xListeColActivity} onChange={handleDropdownActivityChange} /></div> : ""}
                {selectedInv === "activity" && activityDisplay === "item" ? <div className="selectcol" size="small"><DropdownCheckbox options={xListeColActivityItem} onChange={handleDropdownActivityItemChange} /></div> : ""}
                {selectedInv === "activity" && activityDisplay === "quest" ? <div className="selectcol" size="small"><DropdownCheckbox options={xListeColActivityQuest} onChange={handleDropdownActivityQuestChange} /></div> : ""}
                {farmData.balance ? (
                  <div className="selectcurrback">
                    <FormControl id="formselectcurr" className="selectcurr" size="small">
                      <InputLabel>Currency</InputLabel>
                      <Select value={selectedCurr} onChange={handleChangeCurr}>
                        <MenuItem value="SFL">
                          <img src="./sfl.png" alt="SFL" className="curr-icon" />
                        </MenuItem>
                        <MenuItem value="MATIC">
                          <img src="./matic.png" alt="MATIC" className="curr-icon" />
                        </MenuItem>
                        <MenuItem value="USDC">
                          <img src="./usdc.png" alt="USDC" className="curr-icon" />
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                ) : ("")}
              </div>
              <div className="currency-pair">
                <div className="currency"><img src="./sfl.png" alt="" className="curr-icon" />{parseFloat(priceData[2]).toFixed(3)}</div>
                <div className="currency"><img src="./matic.png" alt="" className="curr-icon" />{parseFloat(priceData[1]).toFixed(3)}</div>
              </div>
            </div>
          </h1>
          <div>
            <div className="buttons-container">
              <div>{username !== "" ? username : <span>Farm ID</span>}</div>
              <input type="text" value={inputValue} onChange={handleInputChange} style={{ width: '50px' }} maxLength={6} />
              <button onClick={handleButtonClick}>GO</button>
              <div>
                {farmData.balance ? (<>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {bumpkinData[0] ? (<button onClick={handleButtonfBumpkinClick}>lvl{bumpkinData[0].lvl}</button>) : (<span className="reqstat">NO BUMPKIN</span>)}
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '-18px' }}>
                      <input type="checkbox" title="Toggle Active/Try set" id="CostColumnCheckbox" checked={TryChecked} onChange={handleTryCheckedChange} />
                      <button onClick={handleButtonfTNFTClick} title="NFT"><img src="./icon/nft/nancy.png" alt="" className="itico" /></button>
                    </div>
                    <button onClick={handleButtonfBoostClick} title="Boost"><img src="./icon/ui/lightning.png" alt="" className="itico" /></button>
                    <button onClick={handleButtonfSkillClick} title="Skill"><img src="./icon/skill/greenthumb.png" alt="" className="itico" /></button>
                    <button onClick={handleButtonfDlvrClick} title="Deliveries"><img src="./icon/ui/delivery_board.png" alt="" className="itico" /></button>
                  </div>
                  <div className="balance-container">
                    <img src="./icon/res/sfltoken.png" alt="" />
                    {frmtNb(farmData.balance)}
                    {PBarSFL()}
                    {ticketsData ? ticketsData : ""}
                    {mutData ? mutData : ""}
                  </div>
                </>) : (<><p>Enter your farm ID and clic GO</p></>)}
              </div>
              <p className="reqstat">{reqState}</p>
            </div>
            <div className="maxbb">
              {((selectedInv === "inv" && selectedQuantity === "daily") || (selectedInv === "cook" && (selectedQuantityCook === "daily" || selectedQuantityCook === "dailymax"))) && (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'left' }}>
                    <span>Farming hours</span>
                    <input type="text" value={inputFarmTime} onChange={handleFarmTimeChange} style={{ width: '20px', marginLeft: 'auto' }} maxLength={2} />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'left' }}>
                    <span>Daily max BB</span>
                    <input type="text" value={inputMaxBB} onChange={handleMaxBBChange} style={{ width: '20px', marginLeft: 'auto' }} maxLength={2} />
                  </div>
                </div>
              )}
            </div>
            {farmData.balance ? (
              <div style={{ position: 'relative', display: 'flex', alignItems: 'left', height: '20px', width: '180px', top: '-5px' }}>
                <div className="selectinvback" style={{ display: 'flex', alignItems: 'left', height: '20px', width: '110px' }}>
                  <FormControl variant="standard" id="formselectinv" className="selectinv" size="small" style={{ width: '100px' }}>
                    <InputLabel></InputLabel>
                    <Select value={selectedInv} onChange={handleChangeInv}>
                      <MenuItem value="inv"><img src="./icon/tools/shovel.png" alt="" className="itico" />Farm</MenuItem>
                      <MenuItem value="cook"><img src="./icon/food/chef_hat.png" alt="" className="itico" />Cook</MenuItem>
                      <MenuItem value="fish"><img src="./icon/fish/anchovy.png" alt="" className="itico" />Fish</MenuItem>
                      <MenuItem value="flower"><img src="./icon/flower/red_pansy.webp" alt="" className="itico" />Flower</MenuItem>
                      <MenuItem value="expand"><img src="./icon/tools/hammer.png" alt="" className="itico" />Expand</MenuItem>
                      {isAbo ? <MenuItem value="activity"><img src="./icon/ui/stopwatch.png" alt="" className="itico" />Activity</MenuItem> : null}
                    </Select>
                  </FormControl></div>
                {selectedInv === "activity" ? (<div className="selectinvback" style={{ display: 'flex', alignItems: 'left', height: '20px', width: '75px' }}>
                  <FormControl variant="standard" id="formselectinv" className="selectinv" size="small">
                    <InputLabel></InputLabel>
                    <Select value={activityDisplay} onChange={handleChangeActivityDisplay}>
                      <MenuItem value="day">/Day</MenuItem>
                      <MenuItem value="item">/Item</MenuItem>
                      <MenuItem value="quest">/Quest</MenuItem>
                    </Select>
                  </FormControl></div>) : null}
              </div>) : null}
          </div>
        </div>
        <div className="table-container">
          {farmData.balance ? (
            <>
              {invData ? selectedInv === "inv" ? invData : "" : ""}
              {cookData ? selectedInv === "cook" ? cookData : "" : ""}
              {selectedInv === "fish" ? <span>Kraken hunger: <img src={fishingDetails ? fishingDetails.img : imgna} alt="" className="itico" title={fishingDetails.name} />
                -    Weather: {fishingDetails.weatherimg !== "" ? <img src={fishingDetails.weatherimg} alt="" className="itico" title={fishingDetails.weather} /> : ""}
                <img src={fishingDetails.periodimg} alt="" className="itico" title={fishingDetails.period} />
                -    <img src={imgrod} alt="" className="itico" title="Daily casts" />{fishcasts}  -  Cost: {fishcosts}</span> : null}
              {fishData ? selectedInv === "fish" ? fishData : "" : ""}
              {flowerData ? selectedInv === "flower" ? flowerData : "" : ""}
              {expandData ? selectedInv === "expand" ? expandData : "" : ""}
              {activityTable ? selectedInv === "activity" ? activityTable : "" : ""}
            </>
          ) : ("")}
        </div>
        {showfBoost && (
          <ModalBoost onClose={handleClosefBoost} tableData={BoostNFT} />
        )}
        {showfSkill && (
          <ModalBoost onClose={handleClosefSkill} tableData={BoostSkill} />
        )}
        {showfBumpkin && (
          <ModalBumpkin onClose={handleClosefBumpkin} tableData={bkn} wardrobe={bumpkinData[0].wardrobe} bumpkinOnC={bumpkinData[0]} bumpkinOffC={bumpkinDataOC} img={imgbkn} nftw={nftw} frmid={lastClickedInputValue.current} />
        )}
        {showfGraph && (
          <ModalGraph onClose={handleClosefGraph} graphtype={GraphType} frmid={lastClickedInputValue.current} />
        )}
        {showfTNFT && (
          <ModalTNFT onClose={(ittry, foodtry, fishtry, nfttry, nftwtry, buildtry, skilltry, budtry, Fishingtry, bTrynft, bTrynftw, bTrybuild, bTryskill, bTrybud) => { handleClosefTNFT(ittry, foodtry, fishtry, nfttry, nftwtry, buildtry, skilltry, budtry, Fishingtry, bTrynft, bTrynftw, bTrybuild, bTryskill, bTrybud) }}
            it={it} food={food} fish={fish} bTrynft={bTrynft} bnft={bnft} bTrynftw={bTrynftw} bnftw={bnftw} bTrybuild={bTrybuild} bbuild={bbuild}
            bTryskill={bTryskill} bskill={bskill} bTrybud={bTrybud} bbud={bbud} spot={spot} nft={nft} nftw={nftw} fruitPlanted={fruitPlanted} frmid={lastClickedInputValue.current}
            fishingDetails={fishingDetails} />
        )}
        {showfDlvr && (
          <ModalDlvr onClose={() => { handleClosefDlvr() }} tableData={deliveriesData} />
        )}
        {showCadre && (
          <Cadre onClose={handleCloseCadre} tableData={listingsData} Platform={platformListings} frmid={lastClickedInputValue.current} />
        )}
      </div >
    </>
  );
  function setCookie() {
    try {
      const bvversion = vversion;
      const bFarmitArray = Object.entries(bFarmit).map(([key, value]) => ({ name: key, value }));
      const bCookitArray = Object.entries(bCookit).map(([key, value]) => ({ name: key, value }));
      const bTrynftArray = Object.entries(bTrynft).map(([key, value]) => ({ name: key, value }));
      const bTrynftwArray = Object.entries(bTrynftw).map(([key, value]) => ({ name: key, value }));
      const bTrybuildArray = Object.entries(bTrybuild).map(([key, value]) => ({ name: key, value }));
      const bTryskillArray = Object.entries(bTryskill).map(([key, value]) => ({ name: key, value }));
      const bTrybudArray = Object.entries(bTrybud).map(([key, value]) => ({ name: key, value }));
      const xHrvstArray = Object.entries(xHrvst).map(([key, value]) => ({ name: key, value }));
      const xHrvsttryArray = Object.entries(xHrvsttry).map(([key, value]) => ({ name: key, value }));
      const xBurningArray = Object.entries(xBurning).map(([key, value]) => ({ name: key, value }));
      const fruitPlantedArray = Object.entries(fruitPlanted).map(([key, value]) => ({ name: key, value }));
      const dProdArray = Object.entries(dProd).map(([key, value]) => ({ name: key, value }));
      const dProdtryArray = Object.entries(dProdtry).map(([key, value]) => ({ name: key, value }));
      var dataToStore = {
        vversion: bvversion,
        inputValue: inputValue,
        inputKeep: inputKeep,
        inputMaxBB: inputMaxBB,
        inputFarmTime: inputFarmTime,
        inputFromLvl: inputFromLvl,
        inputToLvl: inputToLvl,
        selectedInv: selectedInv,
        selectedCurr: selectedCurr,
        selectedDsfl: selectedDsfl,
        selectedQuantity: selectedQuantity,
        selectedQuant: selectedQuant,
        selectedQuantityCook: selectedQuantityCook,
        selectedQuantCook: selectedQuantCook,
        selectedQuantFish: selectedQuantFish,
        xListeCol: xListeCol,
        xListeColCook: xListeColCook,
        xListeColFish: xListeColFish,
        xListeColExpand: xListeColExpand,
        xListeColActivity: xListeColActivity,
        xListeColActivityItem: xListeColActivityItem,
        TryChecked: TryChecked,
        CostChecked: CostChecked,
        xHrvst: xHrvstArray,
        xHrvsttry: xHrvsttryArray,
        xBurning: xBurningArray,
        cstPrices: cstPrices,
        bFarmit: bFarmitArray,
        bCookit: bCookitArray,
        bTrynft: bTrynftArray,
        bTrynftw: bTrynftwArray,
        bTrybuild: bTrybuildArray,
        bTryskill: bTryskillArray,
        bTrybud: bTrybudArray,
        fruitPlanted: fruitPlantedArray,
        dProd: dProdArray,
        dProdtry: dProdtryArray,
      };
      var dataToStoreString = JSON.stringify(dataToStore);
      //document.cookie = "sflman=" + dataToStoreString + ";expires=31 Dec 2024 23:59:59 UTC;";
      localStorage.setItem("SFLManData", dataToStoreString);
      //console.log("setC: " + dataToStoreString);
    }
    catch {
      localStorage.removeItem("SFLManData");
      //localStorage.clear();
      console.log("Error, cleared local data");
    }
  }
  function loadCookie() {
    try {
      //var cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)sflman\s*=\s*([^;]*).*$)|^.*$/, "$1");
      const cookieValue = localStorage.getItem("SFLManData");
      if (cookieValue) {
        var loadedData = JSON.parse(cookieValue);
        //console.log("loadC: " + loadedData);
        if (loadedData.vversion !== vversion) {
          localStorage.removeItem("SFLManData");
          console.log("Cleared local data to fit newer version");
          return;
        }
        vversion = loadedData.vversion;
        setInputValue(loadedData.inputValue);
        setInputKeep(loadedData.inputKeep);
        lastClickedInputKeep.current = loadedData.inputKeep;
        setInputMaxBB(loadedData.inputMaxBB);
        setInputFarmTime(loadedData.inputFarmTime);
        setInputFromLvl(loadedData.inputFromLvl);
        setInputToLvl(loadedData.inputToLvl);
        setCstPrices(loadedData.cstPrices);
        //setSelectedInv(loadedData.selectedInv);
        setSelectedInv("inv");
        setSelectedCurr(loadedData.selectedCurr);
        setSelectedDsfl(loadedData.selectedDsfl);
        setSelectedQuantity(loadedData.selectedQuantity);
        setSelectedQuant(loadedData.selectedQuant);
        setSelectedQuantityCook(loadedData.selectedQuantityCook);
        setSelectedQuantCook(loadedData.selectedQuantCook);
        setSelectedQuantFish(loadedData.selectedQuantFish);
        setXListeCol(loadedData.xListeCol);
        setXListeColCook(loadedData.xListeColCook && loadedData.xListeColCook);
        setXListeColFish(loadedData.xListeColFish && loadedData.xListeColFish);
        setXListeColExpand(loadedData.xListeColExpand && loadedData.xListeColExpand);
        setXListeColActivity(loadedData.xListeColActivity && loadedData.xListeColActivity);
        setXListeColActivityItem(loadedData.xListeColActivityItem && loadedData.xListeColActivityItem);
        setTryChecked(loadedData.TryChecked);
        setCostChecked(loadedData.CostChecked);
        xHrvst = loadedData.xHrvst.reduce((acc, item) => { acc[item.name] = item.value; return acc; }, {});
        xHrvsttry = loadedData.xHrvsttry.reduce((acc, item) => { acc[item.name] = item.value; return acc; }, {});
        xBurning = loadedData.xBurning.reduce((acc, item) => { acc[item.name] = item.value; return acc; }, {});
        bFarmit = loadedData.bFarmit.reduce((acc, item) => { acc[item.name] = item.value; return acc; }, {});
        bCookit = loadedData.bCookit.reduce((acc, item) => { acc[item.name] = item.value; return acc; }, {});
        bTrynft = loadedData.bTrynft.reduce((acc, item) => { acc[item.name] = item.value; return acc; }, {});
        bTrynftw = loadedData.bTrynftw.reduce((acc, item) => { acc[item.name] = item.value; return acc; }, {});
        bTrybuild = loadedData.bTrybuild.reduce((acc, item) => { acc[item.name] = item.value; return acc; }, {});
        bTryskill = loadedData.bTryskill.reduce((acc, item) => { acc[item.name] = item.value; return acc; }, {});
        bTrybud = loadedData.bTrybud.reduce((acc, item) => { acc[item.name] = item.value; return acc; }, {});
        fruitPlanted = loadedData.fruitPlanted.reduce((acc, item) => { acc[item.name] = item.value; return acc; }, {});
        dProd = loadedData.dProd.reduce((acc, item) => { acc[item.name] = item.value; return acc; }, {});
        dProdtry = loadedData.dProdtry.reduce((acc, item) => { acc[item.name] = item.value; return acc; }, {});
      }
    }
    catch {
      localStorage.removeItem("SFLManData");
      //localStorage.clear();
      console.log("Error, cleared local data");
    }
  }
  async function getxpFromToLvl(xfrom, xto, xdxp) {
    const responseLVL = await fetch("/getfromtolvl", {
      method: 'GET',
      headers: {
        frmid: lastClickedInputValue.current,
        from: xfrom,
        to: xto,
        xdxp: xdxp,
      }
    });
    if (responseLVL.ok) {
      const responseDataLVL = await responseLVL.json();
      //console.log(responseData);
      //setpriceData(JSON.parse(JSON.stringify(responseData.priceData)));
      setfromtolvltime(responseDataLVL.time);
      setfromtolvlxp(responseDataLVL.xp);
    } else {
      console.log(`Error : ${responseLVL.status}`);
    }
  }
  async function getFromToExpand(xfrom, xto, xtype) {
    const responseExpand = await fetch("/getfromtoexpand", {
      method: 'GET',
      headers: {
        frmid: lastClickedInputValue.current,
        from: xfrom,
        to: xto,
        type: xtype,
        spot: spot
      }
    });
    if (responseExpand.ok) {
      const responseDataExp = await responseExpand.json();
      //console.log(responseData);
      //setpriceData(JSON.parse(JSON.stringify(responseData.priceData)));
      setfromtoexpand(responseDataExp);
    } else {
      console.log(`Error : ${responseExpand.status}`);
    }
  }
  async function getActivity() {
    if (lastClickedInputValue.current !== '') {
      const xContextTime = activityDisplay === "day" ? selectedFromActivityDay : activityDisplay === "item" ? selectedFromActivity : activityDisplay === "quest" ? "season" : "today";
      const responseActivity = await fetch("/getactivity", {
        method: 'GET',
        headers: {
          frmid: lastClickedInputValue.current,
          time: xContextTime,
        }
      });
      if (responseActivity.ok) {
        const responseDataActivity = await responseActivity.json();
        //console.log(responseData);
        //setpriceData(JSON.parse(JSON.stringify(responseData.priceData)));
        setActivityData(responseDataActivity);
      } else {
        console.log(`Error : ${responseActivity.status}`);
      }
    }
  }
}

function convTime(nombre) {
  if (nombre > 0 && nombre !== Infinity) {
    //if (nombre === "-Infinity" || nombre === "Infinity" || nombre === 0 || nombre === NaN) { return "00:00:00" }
    const heures = Math.floor(nombre * 24);
    const minutes = Math.floor(nombre * 24 * 60) % 60;
    const secondes = Math.floor(nombre * 24 * 60 * 60) % 60;
    const jours = Math.floor(heures / 24);
    const heuresFormat = heures % 24;
    const jourStr = jours > 0 ? jours.toString().padStart(2, '0') + ':' : '';
    const heureStr = heuresFormat.toString().padStart(2, '0');
    const minuteStr = minutes.toString().padStart(2, '0');
    const secondeStr = secondes.toString().padStart(2, '0');
    return jourStr + heureStr + ':' + minuteStr + ':' + secondeStr;
  } else {
    return '00:00:00';
  }
}
function convtimenbr(tempsFormat) {
  if (tempsFormat !== "" && tempsFormat !== 0) {
    const tempsArray = tempsFormat.split(':');
    let jours, heures, minutes, secondes;
    if (tempsArray.length === 3) {
      jours = 0;
      heures = parseInt(tempsArray[0], 10);
      minutes = parseInt(tempsArray[1], 10);
      secondes = parseInt(tempsArray[2], 10);
    } else if (tempsArray.length === 4) {
      jours = parseInt(tempsArray[0], 10);
      heures = parseInt(tempsArray[1], 10);
      minutes = parseInt(tempsArray[2], 10);
      secondes = parseInt(tempsArray[3], 10);
    } else {
      return 0;
    }
    var totalSecondes = (jours * 86400 + heures * 3600 + minutes * 60 + secondes);
    const nombre = totalSecondes / (24 * 60 * 60);
    return nombre;
  } else {
    return 0;
  }
}
function formatdate(timestamp) {
  if (timestamp < 3600 * 1000 * 24) { timestamp -= 3600 * 1000 }
  if (timestamp <= 0) { return 0 }
  var dateActuelle = new Date(timestamp);
  //var jours = dateActuelle.getDate();
  var heures = dateActuelle.getHours();
  var minutes = dateActuelle.getMinutes();
  //var secondes = dateActuelle.getSeconds();
  var dateFormatee = (
    //(jours < 10 ? "0" : "") + jours + ":" +
    (heures < 10 ? "0" : "") + heures + ":" +
    (minutes < 10 ? "0" : "") + minutes //+ ":" +
    //(secondes < 10 ? "0" : "") + secondes
  );
  return dateFormatee;
}
function formatDate(xDate, setUTC) {
  const currentDate = (xDate instanceof Date) ? xDate : new Date(xDate);
  var day = "";
  var month = "";
  var year = "";
  if (setUTC) {
    day = String(currentDate.getUTCDate()).padStart(2, '0');
    month = String(currentDate.getUTCMonth() + 1).padStart(2, '0');
    year = String(currentDate.getUTCFullYear()).slice(-2);
  } else {
    day = String(currentDate.getDate()).padStart(2, '0');
    month = String(currentDate.getMonth() + 1).padStart(2, '0');
    year = String(currentDate.getFullYear()).slice(-2);
  }
  const dateNow = `${month}/${day}/${year}`;
  return dateNow;
}
function formatDateAndSupYr(xDate, setUTC) {
  const currentDate = (xDate instanceof Date) ? xDate : new Date(xDate);
  var day = "";
  var month = "";
  if (setUTC) {
    day = String(currentDate.getUTCDate()).padStart(2, '0');
    month = String(currentDate.getUTCMonth() + 1).padStart(2, '0');
  } else {
    day = String(currentDate.getDate()).padStart(2, '0');
    month = String(currentDate.getMonth() + 1).padStart(2, '0');
  }
  const dateNow = `${month}/${day}`;
  return dateNow;
}
const getMaxValue = (value1, value2, value3) => {
  const positiveValues = [parseFloat(value1).toFixed(20), parseFloat(value2).toFixed(20), parseFloat(value3).toFixed(20)].filter(value => value > 0);
  return positiveValues.length > 0 ? parseFloat(Math.max(...positiveValues)).toFixed(20).toString() : null;
};
function frmtNb(nombre) {
  const nombreNumerique = parseFloat(nombre);
  var nombreStr = nombreNumerique.toString();
  const positionE = nombreStr.indexOf("e");
  if (positionE !== -1) {
    const nombreNumeriqueCorr = Number(nombreStr).toFixed(20);
    nombreStr = nombreNumeriqueCorr.toString();
  }
  if (isNaN(nombreNumerique)) {
    return "0";
  }
  const positionVirgule = nombreStr.indexOf(".");
  if (positionVirgule !== -1) {
    let chiffreSupZero = null;
    for (let i = positionVirgule + 1; i < nombreStr.length; i++) {
      if (nombreStr[i] !== '0') {
        chiffreSupZero = i;
        break;
      }
    }
    if (chiffreSupZero === null) { return nombreNumerique.toFixed(2) }
    if (Math.abs(Math.floor(nombre)) > 0) {
      if (Math.abs(Math.floor(nombre)) < 5) {
        return nombreNumerique.toFixed(3);
      } else {
        return nombreNumerique.toFixed(2);
      }
    } else {
      return nombreStr.slice(0, chiffreSupZero + 3);
    }
  } else {
    return nombreStr;
  }
}
function ColorValue(value) {
  if (value < 1) {
    return "red";
  } else {
    const normalizedValue = Math.min((value - 1) / (20 - 1), 1);
    //console.log(normalizedValue);
    const red = Math.round((1 - normalizedValue) * 255);
    const green = Math.round(255);
    const blue = 0;
    return `rgb(${red}, ${green}, ${blue})`;
  }
}
function Timer({ timestamp, index, onTimerFinish }) {
  const [timeLeft, setTimeLeft] = useState(timestamp - Date.now());
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prevTimeLeft => prevTimeLeft - 1000);
      if (timeLeft <= 0) {
        clearInterval(timer);
        onTimerFinish(index);
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [timeLeft, index, onTimerFinish]);
  const formatTime = (time) => {
    const hours = Math.floor(time / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);
    return `${hours}:${minutes}:${seconds}`;
  };
  return (
    <div>
      {timeLeft > 0 ? (
        <div>
          <span>{formatTime(timeLeft)}</span>
        </div>
      ) : (
        <div>
          <img src={imgrdy} alt="" />
        </div>
      )}
    </div>
  );
}
function setTryit(xnft, xnftw, xskill, xbuildng, xbud) {
  const nftEntries = Object.entries(xnft);
  const nftwEntries = Object.entries(xnftw);
  const sklEntries = Object.entries(xskill);
  const bldEntries = Object.entries(xbuildng);
  const budEntries = Object.entries(xbud);
  nftEntries.forEach(([item], index) => { bTrynft[item] = xnft[item].tryit });
  nftwEntries.forEach(([item], index) => { bTrynftw[item] = xnftw[item].tryit });
  sklEntries.forEach(([item], index) => { bTryskill[item] = xskill[item].tryit });
  bldEntries.forEach(([item], index) => { bTrybuild[item] = xbuildng[item].tryit });
  budEntries.forEach(([item], index) => { bTrybud[item] = xbud[item].tryit });
}
function setActive(xnft, xnftw, xskill, xbuildng, xbud) {
  const nftEntries = Object.entries(xnft);
  const nftwEntries = Object.entries(xnftw);
  const sklEntries = Object.entries(xskill);
  const bldEntries = Object.entries(xbuildng);
  const budEntries = Object.entries(xbud);
  nftEntries.forEach(([item], index) => { bnft[item] = xnft[item].isactive });
  nftwEntries.forEach(([item], index) => { bnftw[item] = xnftw[item].isactive });
  sklEntries.forEach(([item], index) => { bskill[item] = xskill[item].isactive });
  bldEntries.forEach(([item], index) => { bbuild[item] = xbuildng[item].isactive });
  budEntries.forEach(([item], index) => { bbud[item] = xbud[item].isactive });
}
function getTryit(xnft, xnftw, xskill, xbuildng, xbud) {
  const nftEntries = Object.entries(xnft);
  const nftwEntries = Object.entries(xnftw);
  const sklEntries = Object.entries(xskill);
  const bldEntries = Object.entries(xbuildng);
  const budEntries = Object.entries(xbud);
  nftEntries.forEach(([item], index) => { xnft[item].tryit = bTrynft[item] });
  nftwEntries.forEach(([item], index) => { xnftw[item].tryit = bTrynftw[item] });
  sklEntries.forEach(([item], index) => { xskill[item].tryit = bTryskill[item] });
  bldEntries.forEach(([item], index) => { xbuildng[item].tryit = bTrybuild[item] });
  budEntries.forEach(([item], index) => { xbud[item].tryit = bTrybud[item] });
}
function setFarmit(xit) {
  const itEntries = Object.entries(xit);
  itEntries.forEach(([item], index) => { bFarmit[item] = xit[item].farmit });
  //console.log(food);
}
function getFarmit(xit) {
  const itEntries = Object.entries(xit);
  itEntries.forEach(([item], index) => { xit[item].farmit = bFarmit[item] ? bFarmit[item] : 0 });
  //console.log(food);
}
function setCookit(xfood) {
  const itEntries = Object.entries(xfood);
  itEntries.forEach(([item], index) => { bCookit[item] = xfood[item].cookit });
  //console.log(food);
}
function getCookit(xfood) {
  const itEntries = Object.entries(xfood);
  itEntries.forEach(([item], index) => { xfood[item].cookit = bCookit[item] ? bCookit[item] : 0 });
  //console.log(food);
}
function MergeIt(xit, xittry) {
  const ittryEntries = Object.entries(xittry);
  ittryEntries.forEach(([item], index) => {
    xit[item].costtry = xittry[item].costtry;
    xit[item].timetry = xittry[item].timetry;
    xit[item].harvesttry = xittry[item].harvesttry;
    xit[item].harvestdmaxtry = xittry[item].harvestdmaxtry;
    xit[item].stocktry = xittry[item].stocktry;
    xit[item].myieldtry = xittry[item].myieldtry;
    xit[item].mtimetry = xittry[item].mtimetry;
  });
}
function MergeFood(xfood, xfoodtry) {
  const ittryEntries = Object.entries(xfoodtry);
  ittryEntries.forEach(([item], index) => {
    xfood[item].costtry = xfoodtry[item].costtry;
    xfood[item].timetry = xfoodtry[item].timetry;
    xfood[item].timecrptry = xfoodtry[item].timecrptry;
    xfood[item].xptry = xfoodtry[item].xptry;
    xfood[item].xphtry = xfoodtry[item].xphtry;
  });
}
function MergeFish(xfish, xfishtry) {
  const ittryEntries = Object.entries(xfishtry);
  ittryEntries.forEach(([item], index) => {
    xfish[item].xptry = xfishtry[item].xptry;
    xfish[item].costtry = xfishtry[item].costtry;
  });
}
function MergeFishing(xFishing, xFishingtry) {
  xFishing["CastCosttry"] = xFishingtry["CastCosttry"];
  xFishing["CastMaxtry"] = xFishingtry["CastMaxtry"];
}
function getPlanted(xit) {
  const itEntries = Object.entries(xit);
  itEntries.forEach(([item], index) => {
    if (xit[item].cat === "fruit") { fruitPlanted[item] = xit[item].planted }
  });
}
function setPlanted(xit) {
  const itEntries = Object.entries(xit);
  itEntries.forEach(([item], index) => {
    if (xit[item].cat === "fruit") { xit[item].planted = fruitPlanted[item] }
  });
}

export default App;